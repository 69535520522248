import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid, TextField, Typography } from "@material-ui/core";
import { Props as BaseProps } from "./page";
import { Props as ResourceProps } from "../resources/svg/svg";
import { Heart } from "../resources";

interface Props extends BaseProps {
  expectedValue: string;
  textInvalid: string;
  question: string;
  Resource: React.FC<ResourceProps>;
}

export const Question: React.FC<Props> = ({
  expectedValue,
  textInvalid,
  question,
  Resource,
  goTo,
}) => {
  const history = useHistory();
  const [value, setValue] = useState("");
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      style={{ height: "100vh" }}
    >
      <Grid container direction="column" alignItems="center" wrap="nowrap">
        <Resource style={{ width: "80%", height: "50%", margin: "2rem" }} />
        <TextField
          color="secondary"
          placeholder="Hmmm..."
          inputProps={{ style: { textAlign: "center", fontSize: "2rem" } }}
          value={value}
          onChange={(v) => setValue(v.target.value)}
        />
        <Typography
          variant="h5"
          color="secondary"
          style={{ marginTop: "1rem" }}
        >
          {isEqual(value, expectedValue) ? (
            <span onClick={() => history.push(goTo)}>
              <Heart />
            </span>
          ) : normalize(value).length > 0 ? (
            textInvalid
          ) : (
            question
          )}
        </Typography>
      </Grid>
    </Grid>
  );
};

function isEqual(a: string, b: string): boolean {
  return normalize(a) === normalize(b);
}

function normalize(v: string): string {
  return v.toLowerCase().trim();
}

import React from "react";
import { Route, Switch, HashRouter as Router } from "react-router-dom";
import axios from "axios";

import { Main, Info, Question, TravelList, Code } from "./pages";
import { Travel, Cinema, Phone, Treasure } from "./resources";

const prefix = "/druga-rocznica";
const functionsURL =
  "https://us-central1-second-anniversary.cloudfunctions.net";
const Reciepient = "Kasia";

const showErr = (err: Error) => {
  alert(`Coś poszło nie tak :( Skontaktuj się ze mną. Error: ${err}`);
};

const pages: {
  id?: string;
  render: (nextPage: string, index: number) => JSX.Element;
}[] = [
  {
    render: (nextPage) => {
      return (
        <Question
          question="Dzień dobry!"
          expectedValue="Jesteś moim skarbem"
          textInvalid="No niestety...próbuj dalej"
          Resource={Phone}
          goTo={nextPage}
        />
      );
    },
  },
  {
    id: "766fdd78",
    render: (nextPage) => {
      return (
        <Info
          goTo={nextPage}
          text="Brawo kochanie! Należy się nagroda! Zapamiętaj swój magiczny kodzik: 35124. Strzeż go pilnie! Nie pokazuj nikomu! Pod żadnym pozorem!"
        />
      );
    },
  },
  {
    id: "52a77ea3",
    render: (nextPage) => {
      return <Main goTo={nextPage} />;
    },
  },
  {
    id: "a67d4406",
    render: (nextPage) => {
      return (
        <Info
          goTo={nextPage}
          text="Wypowiedz magiczne zaklęcie, a kuferek odkryje swe wnętrze..."
          Resource={Treasure}
        />
      );
    },
  },
  {
    id: "ab521db6",
    render: (nextPage) => {
      return (
        <Question
          question="Jak to leciało?"
          expectedValue="Bohemian Rhapsody"
          textInvalid="Nie...to nie tak"
          Resource={Cinema}
          goTo={nextPage}
        />
      );
    },
  },
  {
    id: "0ef02ffa",
    render: (nextPage) => {
      return (
        <Info
          goTo={nextPage}
          text={`"Ha ha ha, myślałaś, że tak łatwo się przed Tobą otworzę?" ~Kuferek`}
          Resource={Treasure}
        />
      );
    },
  },
  {
    id: "7474059f",
    render: (nextPage) => {
      return (
        <Info
          goTo={nextPage}
          text="Mówią, że czas to pojęcie względne...i chyba mają rację. Jesteś obok - biegnie jak oszalały. Nie ma Cię - zwalnia, sekunda jak minuta, minuta niczym godzina. A to tylko jeden wymiar, dodajmy przestrzeń, a sprawy jeszcze bardziej się skomplikują..."
        />
      );
    },
  },
  {
    id: "a44ec543",
    render: (nextPage) => {
      return (
        <TravelList
          goTo={nextPage}
          onEnter={async () => {
            try {
              await axios.post(`${functionsURL}/onStartConversation`, {
                Reciepient,
              });
            } catch (err) {
              showErr(err);
            }
          }}
        />
      );
    },
  },
  {
    id: "3bcc5b25",
    render: (nextPage) => {
      return (
        <Info
          goTo={nextPage}
          text="Cała ta czasoprzestrzeń, tak zawiła, a bez znaczenia. To Ty, ukochana, nadajesz sens, wszystkiemu!"
          Resource={Travel}
        />
      );
    },
  },
  {
    id: "bdea7145",
    render: (nextPage) => {
      return (
        <Code
          goTo={nextPage}
          onEnter={async () => {
            try {
              await axios.post(`${functionsURL}/onEndGame`, {
                Reciepient,
              });
            } catch (err) {
              showErr(err);
            }
          }}
        />
      );
    },
  },
];

function App() {
  return (
    <Router>
      <Switch>
        {pages.map(({ id = "", render }, i) => {
          const nextPage = pages[i + 1];
          const nextPath = nextPage ? nextPage.id : "";
          return (
            <Route
              key={id}
              path={`${prefix}/${id}`}
              exact
              render={() => render(`${prefix}/${nextPath}`, i)}
            />
          );
        })}
      </Switch>
    </Router>
  );
}

export default App;

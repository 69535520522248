import React from "react";
import { useHistory } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import { Props } from "./page";
import { Heart } from "../resources";
import { Props as ResourceProps } from "../resources/svg/svg";

export const Info: React.FC<
  Props & { text: string; Resource?: React.FC<ResourceProps> }
> = ({ goTo, text, Resource }) => {
  const history = useHistory();
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      style={{ height: "100vh" }}
    >
      <Grid container direction="column" alignItems="center" wrap="nowrap">
        {Resource && (
          <Resource style={{ width: "50%", height: "50%", margin: "0.5rem" }} />
        )}
        <Grid item style={{ padding: "2rem" }}>
          <Typography
            variant="h5"
            color="secondary"
            style={{
              marginTop: "1rem",
              textAlign: "justify",
              letterSpacing: 5,
            }}
          >
            {text}
          </Typography>
        </Grid>
        <span onClick={() => history.push(goTo)}>
          <Heart />
        </span>
      </Grid>
    </Grid>
  );
};

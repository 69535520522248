import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import { Props } from "./page";
import { Heart, Together } from "../resources";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";

const grid = 8;

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  letterSpacing: 3,

  // change background colour if dragging
  background: isDragging ? "#f50057" : "none",
  color: isDragging ? "#fff" : "inherit",

  // styles we need to apply on draggables
  ...draggableStyle,
});

interface Item {
  id: string;
  content: string;
}

const given = [
  {
    id: "Mdina",
    content: "35.884, 14.403",
  },
  {
    id: "Ortyga",
    content: "37.064, 15.293",
  },
  {
    id: "Szczeliniec",
    content: "50.484, 16.343",
  },
  {
    id: "Gozo",
    content: "36.044, 14.239",
  },
  {
    id: "Etna",
    content: "37.751, 14.984",
  },
];

const expected = ["Etna", "Ortyga", "Szczeliniec", "Mdina", "Gozo"];

const isSorted = (items: Item[]) => {
  for (let i = 0; i < items.length; i++) {
    if (items[i].id !== expected[i]) {
      return false;
    }
  }
  return true;
};

export const TravelList: React.FC<Props & { onEnter?: () => {} }> = ({
  goTo,
  onEnter,
}) => {
  const history = useHistory();
  const [items, setItems] = useState(given);
  useEffect(() => {
    onEnter && onEnter();
  }, []);
  const onDragEnd = (result: DropResult): void => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    setItems(newItems);
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      style={{ height: "100vh" }}
    >
      <Grid container direction="column" alignItems="center" wrap="nowrap">
        <Together style={{ width: "80%", height: "50%", margin: "2rem" }} />
        <Grid item>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {items.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <Typography
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          {item.content}
                        </Typography>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Grid>
        {isSorted(items) && (
          <span onClick={() => history.push(goTo)}>
            <Heart />
          </span>
        )}
      </Grid>
    </Grid>
  );
};

function reorder(list: Item[], startIndex: number, endIndex: number) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

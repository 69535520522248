import React from "react";
import { Props } from "./svg";

export const Travel: React.FC<Props> = ({ style }) => {
  return (
    <svg
      id="ee521cf8-276d-4d1e-900d-5b4e704817f9"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="743.31832"
      height="819.52927"
      viewBox="0 0 743.31832 819.52927"
      style={style}
    >
      <path
        d="M770.85285,855.42322c-6.43422-5.43687-10.34247-14.02552-9.31754-22.38664s7.37308-16.04756,15.60755-17.82308,17.62118,3.13941,20.12226,11.18325c1.37646-15.50821,2.96258-31.6662,11.20774-44.87289,7.46579-11.95834,20.39667-20.515,34.41082-22.04577s28.76926,4.2508,37.50938,15.312,10.8801,27.14188,4.75875,39.841c-4.50939,9.355-12.81726,16.31717-21.52184,21.98123a138.90067,138.90067,0,0,1-94.99923,20.78271"
        transform="translate(-228.34084 -40.23537)"
        fill="#f2f2f2"
      />
      <path
        d="M866.5347,764.04938a227.26176,227.26176,0,0,0-31.6712,31.44942,227.92451,227.92451,0,0,0-35.672,59.61158c-.64234,1.57405,1.91364,2.25922,2.54883.70268a226.64209,226.64209,0,0,1,66.66345-89.89462c1.3196-1.07454-.56035-2.93475-1.86907-1.86906Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#fff"
      />
      <path
        d="M402.90576,853.78551c9.5355-8.05743,15.32752-20.78579,13.80857-33.177s-10.92689-23.78243-23.13034-26.41375-26.11455,4.65259-29.82113,16.57355c-2.03992-22.98313-4.39055-46.92923-16.60985-66.50151-11.06427-17.72222-30.22781-30.40317-50.99675-32.67177s-42.636,6.29968-55.58879,22.69236-16.12428,40.22419-7.05245,59.04433c6.68289,13.86414,18.99514,24.182,31.89531,32.57612a205.85042,205.85042,0,0,0,140.78859,30.79993"
        transform="translate(-228.34084 -40.23537)"
        fill="#f2f2f2"
      />
      <path
        d="M261.10552,718.36972a336.80174,336.80174,0,0,1,46.93663,46.60795,341.61456,341.61456,0,0,1,37.01514,54.782A340.21365,340.21365,0,0,1,360.908,853.32188c.95194,2.33273-2.836,3.34816-3.77737,1.04137a334.31111,334.31111,0,0,0-30.54713-57.79643,336.41428,336.41428,0,0,0-40.96855-50.61069,335.6104,335.6104,0,0,0-27.27936-24.81647c-1.95565-1.59247.83042-4.34928,2.76995-2.76994Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#fff"
      />
      <path
        d="M401.57048,537.33449a.99651.99651,0,0,1-.701-.28662,290.16782,290.16782,0,1,1,428.692-24.3252,1.00013,1.00013,0,0,1-1.55371-1.25976A288.6388,288.6388,0,0,0,892.164,330.23537c0-158.80371-129.19629-288-288-288s-288,129.19629-288,288A285.89387,285.89387,0,0,0,402.27153,535.6216a.99982.99982,0,0,1-.70105,1.71289Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M604.164,620.23537a292.9852,292.9852,0,0,1-48.33936-4.01075,1.00012,1.00012,0,1,1,.33105-1.97265,290.95451,290.95451,0,0,0,48.00831,3.9834,288.01307,288.01307,0,0,0,86.20239-13.12207.99984.99984,0,1,1,.5979,1.9082A290.00124,290.00124,0,0,1,604.164,620.23537Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M456.04,111.533a2.15763,2.15763,0,1,0-2.15771-2.1576A2.15742,2.15742,0,0,0,456.04,111.533Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M507.68693,111.533a2.15763,2.15763,0,1,0-2.15771-2.1576A2.15784,2.15784,0,0,0,507.68693,111.533Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M516.287,107.21779a2.15763,2.15763,0,1,0,2.15772,2.15765A2.15784,2.15784,0,0,0,516.287,107.21779Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M524.90227,107.21779a2.15763,2.15763,0,1,0,2.15771,2.15765A2.15743,2.15743,0,0,0,524.90227,107.21779Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M533.50285,107.21779a2.15763,2.15763,0,1,0,2.15723,2.15765A2.15753,2.15753,0,0,0,533.50285,107.21779Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M542.11809,107.21779a2.15763,2.15763,0,1,0,2.15771,2.15765A2.15785,2.15785,0,0,0,542.11809,107.21779Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M550.71818,107.21779a2.15763,2.15763,0,1,0,2.15772,2.15765A2.15784,2.15784,0,0,0,550.71818,107.21779Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M559.31877,107.21779a2.15763,2.15763,0,1,0,2.15772,2.15765A2.15753,2.15753,0,0,0,559.31877,107.21779Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M567.934,107.21779a2.15763,2.15763,0,1,0,2.15771,2.15765A2.15753,2.15753,0,0,0,567.934,107.21779Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="210.49844" cy="77.75543" r="2.15765" fill="#ccc" />
      <circle cx="236.31435" cy="77.75543" r="2.15765" fill="#ccc" />
      <path
        d="M473.25529,120.14839a2.15759,2.15759,0,1,0-2.15722-2.15759A2.15752,2.15752,0,0,0,473.25529,120.14839Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M481.871,120.14839a2.15759,2.15759,0,1,0-2.15772-2.15759A2.15741,2.15741,0,0,0,481.871,120.14839Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="262.13027" cy="77.75543" r="2.15765" fill="#ccc" />
      <path
        d="M499.07121,120.14839a2.15759,2.15759,0,1,0-2.15722-2.15759A2.15752,2.15752,0,0,0,499.07121,120.14839Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="279.34609" cy="77.75543" r="2.15765" fill="#ccc" />
      <circle cx="287.94619" cy="77.75543" r="2.15765" fill="#ccc" />
      <path
        d="M524.90227,115.83321A2.15759,2.15759,0,1,0,527.06,117.9908,2.15742,2.15742,0,0,0,524.90227,115.83321Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M533.50285,115.83321a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,533.50285,115.83321Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M576.5341,115.83321a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15741,2.15741,0,0,0,576.5341,115.83321Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M585.14983,115.83321a2.15759,2.15759,0,1,0,2.15722,2.15759A2.15752,2.15752,0,0,0,585.14983,115.83321Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="365.40908" cy="77.75543" r="2.15765" fill="#ccc" />
      <circle cx="201.8832" cy="86.35571" r="2.15765" fill="#ccc" />
      <path
        d="M447.43938,128.74867a2.15759,2.15759,0,1,0-2.15723-2.15759A2.15752,2.15752,0,0,0,447.43938,128.74867Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M456.04,128.74867a2.15759,2.15759,0,1,0-2.15771-2.15759A2.15742,2.15742,0,0,0,456.04,128.74867Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="236.31435" cy="86.35571" r="2.15765" fill="#ccc" />
      <path
        d="M473.25529,128.74867a2.15759,2.15759,0,1,0-2.15722-2.15759A2.15752,2.15752,0,0,0,473.25529,128.74867Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M481.871,128.74867a2.15759,2.15759,0,1,0-2.15772-2.15759A2.15741,2.15741,0,0,0,481.871,128.74867Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="262.13027" cy="86.35571" r="2.15765" fill="#ccc" />
      <path
        d="M499.07121,128.74867a2.15759,2.15759,0,1,0-2.15722-2.15759A2.15752,2.15752,0,0,0,499.07121,128.74867Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="279.34609" cy="86.35571" r="2.15765" fill="#ccc" />
      <circle cx="287.94619" cy="86.35571" r="2.15765" fill="#ccc" />
      <path
        d="M524.90227,124.43349a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15738,2.15738,0,0,0,524.90227,124.43349Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M533.50285,124.43349a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,533.50285,124.43349Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="313.77725" cy="86.35571" r="2.15765" fill="#ccc" />
      <circle cx="322.37734" cy="86.35571" r="2.15765" fill="#ccc" />
      <circle cx="330.97793" cy="86.35571" r="2.15765" fill="#ccc" />
      <circle cx="339.59316" cy="86.35571" r="2.15765" fill="#ccc" />
      <path
        d="M576.5341,124.43349a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15738,2.15738,0,0,0,576.5341,124.43349Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M688.42863,124.43349a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,688.42863,124.43349Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="468.68789" cy="86.35571" r="2.15765" fill="#ccc" />
      <circle cx="477.30312" cy="86.35571" r="2.15765" fill="#ccc" />
      <circle cx="503.11904" cy="86.35571" r="2.15765" fill="#ccc" />
      <path
        d="M740.06047,128.74867a2.15759,2.15759,0,1,0-2.15771-2.15759A2.15742,2.15742,0,0,0,740.06047,128.74867Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="546.15078" cy="86.35571" r="2.15765" fill="#ccc" />
      <circle cx="554.75088" cy="86.35571" r="2.15765" fill="#ccc" />
      <path
        d="M447.43938,137.349a2.15759,2.15759,0,1,0-2.15723-2.15759A2.15752,2.15752,0,0,0,447.43938,137.349Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M456.04,137.349a2.15759,2.15759,0,1,0-2.15771-2.15759A2.15742,2.15742,0,0,0,456.04,137.349Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="236.31435" cy="94.95599" r="2.15765" fill="#ccc" />
      <path
        d="M473.25529,137.349a2.15759,2.15759,0,1,0-2.15722-2.15759A2.15752,2.15752,0,0,0,473.25529,137.349Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M481.871,137.349a2.15759,2.15759,0,1,0-2.15772-2.15759A2.15741,2.15741,0,0,0,481.871,137.349Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="262.13027" cy="94.95599" r="2.15765" fill="#ccc" />
      <path
        d="M499.07121,137.349a2.15759,2.15759,0,1,0-2.15722-2.15759A2.15752,2.15752,0,0,0,499.07121,137.349Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="279.34609" cy="94.95599" r="2.15765" fill="#ccc" />
      <circle cx="287.94619" cy="94.95599" r="2.15765" fill="#ccc" />
      <path
        d="M524.90227,133.03377a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15738,2.15738,0,0,0,524.90227,133.03377Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M533.50285,133.03377a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,533.50285,133.03377Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="313.77725" cy="94.95599" r="2.15765" fill="#ccc" />
      <circle cx="322.37734" cy="94.95599" r="2.15765" fill="#ccc" />
      <circle cx="330.97793" cy="94.95599" r="2.15765" fill="#ccc" />
      <circle cx="339.59316" cy="94.95599" r="2.15765" fill="#ccc" />
      <path
        d="M576.5341,133.03377a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15738,2.15738,0,0,0,576.5341,133.03377Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="442.87197" cy="94.95599" r="2.15765" fill="#ccc" />
      <path
        d="M679.81291,133.03377a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15738,2.15738,0,0,0,679.81291,133.03377Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M688.42863,133.03377a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,688.42863,133.03377Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="468.68789" cy="94.95599" r="2.15765" fill="#ccc" />
      <circle cx="477.30312" cy="94.95599" r="2.15765" fill="#ccc" />
      <path
        d="M714.24406,133.03377a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15738,2.15738,0,0,0,714.24406,133.03377Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="210.49844" cy="103.57135" r="2.15765" fill="#ccc" />
      <path
        d="M447.43938,145.96431a2.1576,2.1576,0,1,0-2.15723-2.15759A2.15752,2.15752,0,0,0,447.43938,145.96431Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M456.04,145.96431a2.1576,2.1576,0,1,0-2.15771-2.15759A2.15742,2.15742,0,0,0,456.04,145.96431Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="236.31435" cy="103.57135" r="2.15765" fill="#ccc" />
      <path
        d="M473.25529,145.96431a2.1576,2.1576,0,1,0-2.15722-2.15759A2.15752,2.15752,0,0,0,473.25529,145.96431Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M481.871,145.96431a2.1576,2.1576,0,1,0-2.15772-2.15759A2.15741,2.15741,0,0,0,481.871,145.96431Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="262.13027" cy="103.57135" r="2.15765" fill="#ccc" />
      <path
        d="M499.07121,145.96431a2.1576,2.1576,0,1,0-2.15722-2.15759A2.15752,2.15752,0,0,0,499.07121,145.96431Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="279.34609" cy="103.57135" r="2.15765" fill="#ccc" />
      <circle cx="287.94619" cy="103.57135" r="2.15765" fill="#ccc" />
      <path
        d="M524.90227,141.64912a2.1576,2.1576,0,1,0,2.15771,2.1576A2.15742,2.15742,0,0,0,524.90227,141.64912Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M533.50285,141.64912a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,533.50285,141.64912Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="313.77725" cy="103.57135" r="2.15765" fill="#ccc" />
      <circle cx="322.37734" cy="103.57135" r="2.15765" fill="#ccc" />
      <circle cx="330.97793" cy="103.57135" r="2.15765" fill="#ccc" />
      <circle cx="339.59316" cy="103.57135" r="2.15765" fill="#ccc" />
      <path
        d="M576.5341,141.64912a2.1576,2.1576,0,1,0,2.15772,2.1576A2.15742,2.15742,0,0,0,576.5341,141.64912Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M679.81291,141.64912a2.1576,2.1576,0,1,0,2.15772,2.1576A2.15743,2.15743,0,0,0,679.81291,141.64912Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M688.42863,141.64912a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,688.42863,141.64912Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="468.68789" cy="103.57135" r="2.15765" fill="#ccc" />
      <circle cx="477.30312" cy="103.57135" r="2.15765" fill="#ccc" />
      <path
        d="M404.40813,150.2494a2.1576,2.1576,0,1,0,2.15722,2.1576A2.15753,2.15753,0,0,0,404.40813,150.2494Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="210.49844" cy="112.17163" r="2.15765" fill="#ccc" />
      <path
        d="M447.43938,154.56459a2.1576,2.1576,0,1,0-2.15723-2.15759A2.15752,2.15752,0,0,0,447.43938,154.56459Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M456.04,154.56459a2.1576,2.1576,0,1,0-2.15771-2.15759A2.15742,2.15742,0,0,0,456.04,154.56459Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="236.31435" cy="112.17163" r="2.15765" fill="#ccc" />
      <path
        d="M473.25529,154.56459a2.1576,2.1576,0,1,0-2.15722-2.15759A2.15752,2.15752,0,0,0,473.25529,154.56459Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M481.871,154.56459a2.1576,2.1576,0,1,0-2.15772-2.15759A2.15741,2.15741,0,0,0,481.871,154.56459Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="262.13027" cy="112.17163" r="2.15765" fill="#ccc" />
      <path
        d="M499.07121,154.56459A2.1576,2.1576,0,1,0,496.914,152.407,2.15752,2.15752,0,0,0,499.07121,154.56459Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="279.34609" cy="112.17163" r="2.15765" fill="#ccc" />
      <circle cx="287.94619" cy="112.17163" r="2.15765" fill="#ccc" />
      <path
        d="M524.90227,150.2494A2.1576,2.1576,0,1,0,527.06,152.407,2.15742,2.15742,0,0,0,524.90227,150.2494Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M533.50285,150.2494a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,533.50285,150.2494Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="313.77725" cy="112.17163" r="2.15765" fill="#ccc" />
      <circle cx="322.37734" cy="112.17163" r="2.15765" fill="#ccc" />
      <circle cx="330.97793" cy="112.17163" r="2.15765" fill="#ccc" />
      <circle cx="339.59316" cy="112.17163" r="2.15765" fill="#ccc" />
      <path
        d="M576.5341,150.2494a2.1576,2.1576,0,1,0,2.15772,2.1576A2.15742,2.15742,0,0,0,576.5341,150.2494Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="571.9667" cy="112.17163" r="2.15765" fill="#ccc" />
      <circle cx="580.58193" cy="112.17163" r="2.15765" fill="#ccc" />
      <path
        d="M395.79289,158.86476a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,395.79289,158.86476Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M404.40813,158.86476a2.1576,2.1576,0,1,0,2.15722,2.15759A2.15748,2.15748,0,0,0,404.40813,158.86476Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M473.25529,163.18a2.1576,2.1576,0,1,0-2.15722-2.1576A2.15753,2.15753,0,0,0,473.25529,163.18Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M481.871,163.18a2.1576,2.1576,0,1,0-2.15772-2.1576A2.15742,2.15742,0,0,0,481.871,163.18Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="262.13027" cy="120.78699" r="2.15765" fill="#ccc" />
      <path
        d="M499.07121,163.18a2.1576,2.1576,0,1,0-2.15722-2.1576A2.15753,2.15753,0,0,0,499.07121,163.18Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="279.34609" cy="120.78699" r="2.15765" fill="#ccc" />
      <circle cx="287.94619" cy="120.78699" r="2.15765" fill="#ccc" />
      <path
        d="M524.90227,158.86476a2.1576,2.1576,0,1,0,2.15771,2.15759A2.15738,2.15738,0,0,0,524.90227,158.86476Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M533.50285,158.86476a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,533.50285,158.86476Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="313.77725" cy="120.78699" r="2.15765" fill="#ccc" />
      <circle cx="322.37734" cy="120.78699" r="2.15765" fill="#ccc" />
      <circle cx="330.97793" cy="120.78699" r="2.15765" fill="#ccc" />
      <circle cx="339.59316" cy="120.78699" r="2.15765" fill="#ccc" />
      <path
        d="M576.5341,158.86476a2.1576,2.1576,0,1,0,2.15772,2.15759A2.15738,2.15738,0,0,0,576.5341,158.86476Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="571.9667" cy="120.78699" r="2.15765" fill="#ccc" />
      <circle cx="158.85146" cy="129.38727" r="2.15765" fill="#ccc" />
      <path
        d="M395.79289,167.465a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,395.79289,167.465Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M404.40813,167.465a2.1576,2.1576,0,1,0,2.15722,2.15759A2.15753,2.15753,0,0,0,404.40813,167.465Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="184.66738" cy="129.38727" r="2.15765" fill="#ccc" />
      <path
        d="M481.871,171.78023a2.1576,2.1576,0,1,0-2.15772-2.1576A2.15738,2.15738,0,0,0,481.871,171.78023Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="262.13027" cy="129.38727" r="2.15765" fill="#ccc" />
      <path
        d="M499.07121,171.78023a2.1576,2.1576,0,1,0-2.15722-2.1576A2.15748,2.15748,0,0,0,499.07121,171.78023Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="279.34609" cy="129.38727" r="2.15765" fill="#ccc" />
      <circle cx="287.94619" cy="129.38727" r="2.15765" fill="#ccc" />
      <path
        d="M524.90227,167.465a2.1576,2.1576,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,524.90227,167.465Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M533.50285,167.465a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,533.50285,167.465Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="313.77725" cy="129.38727" r="2.15765" fill="#ccc" />
      <circle cx="322.37734" cy="129.38727" r="2.15765" fill="#ccc" />
      <circle cx="330.97793" cy="129.38727" r="2.15765" fill="#ccc" />
      <path
        d="M791.707,167.465a2.1576,2.1576,0,1,0,2.15772,2.15759A2.15742,2.15742,0,0,0,791.707,167.465Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="150.25137" cy="138.00262" r="2.15765" fill="#ccc" />
      <circle cx="158.85146" cy="138.00262" r="2.15765" fill="#ccc" />
      <path
        d="M395.79289,176.0804a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,395.79289,176.0804Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M404.40813,176.0804a2.15759,2.15759,0,1,0,2.15722,2.15759A2.15752,2.15752,0,0,0,404.40813,176.0804Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="184.66738" cy="138.00262" r="2.15765" fill="#ccc" />
      <path
        d="M421.62346,176.0804a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,421.62346,176.0804Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="201.8832" cy="138.00262" r="2.15765" fill="#ccc" />
      <path
        d="M481.871,180.39558a2.15759,2.15759,0,1,0-2.15772-2.15759A2.15741,2.15741,0,0,0,481.871,180.39558Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="262.13027" cy="138.00262" r="2.15765" fill="#ccc" />
      <path
        d="M499.07121,180.39558A2.15759,2.15759,0,1,0,496.914,178.238,2.15752,2.15752,0,0,0,499.07121,180.39558Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="279.34609" cy="138.00262" r="2.15765" fill="#ccc" />
      <circle cx="287.94619" cy="138.00262" r="2.15765" fill="#ccc" />
      <path
        d="M524.90227,176.0804A2.15759,2.15759,0,1,0,527.06,178.238,2.15742,2.15742,0,0,0,524.90227,176.0804Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M533.50285,176.0804a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,533.50285,176.0804Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="313.77725" cy="138.00262" r="2.15765" fill="#ccc" />
      <circle cx="322.37734" cy="138.00262" r="2.15765" fill="#ccc" />
      <circle cx="330.97793" cy="138.00262" r="2.15765" fill="#ccc" />
      <circle cx="339.59316" cy="138.00262" r="2.15765" fill="#ccc" />
      <circle cx="477.30312" cy="138.00262" r="2.15765" fill="#ccc" />
      <path
        d="M714.24406,176.0804a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15741,2.15741,0,0,0,714.24406,176.0804Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M791.707,176.0804a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15741,2.15741,0,0,0,791.707,176.0804Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M369.97649,184.68068a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,369.97649,184.68068Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="150.25137" cy="146.60291" r="2.15765" fill="#ccc" />
      <circle cx="158.85146" cy="146.60291" r="2.15765" fill="#ccc" />
      <path
        d="M395.79289,184.68068a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,395.79289,184.68068Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="184.66738" cy="146.60291" r="2.15765" fill="#ccc" />
      <path
        d="M421.62346,184.68068a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,421.62346,184.68068Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="201.8832" cy="146.60291" r="2.15765" fill="#ccc" />
      <circle cx="210.49844" cy="146.60291" r="2.15765" fill="#ccc" />
      <circle cx="262.13027" cy="146.60291" r="2.15765" fill="#ccc" />
      <path
        d="M499.07121,188.99586a2.15759,2.15759,0,1,0-2.15722-2.15759A2.15752,2.15752,0,0,0,499.07121,188.99586Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="279.34609" cy="146.60291" r="2.15765" fill="#ccc" />
      <circle cx="287.94619" cy="146.60291" r="2.15765" fill="#ccc" />
      <path
        d="M524.90227,184.68068a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,524.90227,184.68068Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M533.50285,184.68068a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,533.50285,184.68068Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="313.77725" cy="146.60291" r="2.15765" fill="#ccc" />
      <circle cx="322.37734" cy="146.60291" r="2.15765" fill="#ccc" />
      <circle cx="330.97793" cy="146.60291" r="2.15765" fill="#ccc" />
      <path
        d="M688.42863,184.68068a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,688.42863,184.68068Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="468.68789" cy="146.60291" r="2.15765" fill="#ccc" />
      <circle cx="477.30312" cy="146.60291" r="2.15765" fill="#ccc" />
      <path
        d="M714.24406,184.68068a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15741,2.15741,0,0,0,714.24406,184.68068Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="494.50381" cy="146.60291" r="2.15765" fill="#ccc" />
      <circle cx="503.11904" cy="146.60291" r="2.15765" fill="#ccc" />
      <circle cx="580.58193" cy="146.60291" r="2.15765" fill="#ccc" />
      <path
        d="M817.52336,184.68068a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,817.52336,184.68068Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="597.78262" cy="146.60291" r="2.15765" fill="#ccc" />
      <circle cx="606.39785" cy="146.60291" r="2.15765" fill="#ccc" />
      <path
        d="M369.97649,193.281a2.1576,2.1576,0,1,0,2.15771,2.15759A2.15738,2.15738,0,0,0,369.97649,193.281Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="150.25137" cy="155.20319" r="2.15765" fill="#ccc" />
      <circle cx="158.85146" cy="155.20319" r="2.15765" fill="#ccc" />
      <path
        d="M395.79289,193.281a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,395.79289,193.281Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="201.8832" cy="155.20319" r="2.15765" fill="#ccc" />
      <circle cx="210.49844" cy="155.20319" r="2.15765" fill="#ccc" />
      <path
        d="M447.43938,197.59615a2.1576,2.1576,0,1,0-2.15723-2.1576A2.15753,2.15753,0,0,0,447.43938,197.59615Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M456.04,197.59615a2.1576,2.1576,0,1,0-2.15771-2.1576A2.15742,2.15742,0,0,0,456.04,197.59615Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="262.13027" cy="155.20319" r="2.15765" fill="#ccc" />
      <path
        d="M499.07121,197.59615a2.1576,2.1576,0,1,0-2.15722-2.1576A2.15753,2.15753,0,0,0,499.07121,197.59615Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="279.34609" cy="155.20319" r="2.15765" fill="#ccc" />
      <circle cx="287.94619" cy="155.20319" r="2.15765" fill="#ccc" />
      <path
        d="M524.90227,193.281a2.1576,2.1576,0,1,0,2.15771,2.15759A2.15738,2.15738,0,0,0,524.90227,193.281Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M533.50285,193.281a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,533.50285,193.281Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M679.81291,193.281a2.1576,2.1576,0,1,0,2.15772,2.15759A2.15738,2.15738,0,0,0,679.81291,193.281Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M688.42863,193.281a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,688.42863,193.281Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="468.68789" cy="155.20319" r="2.15765" fill="#ccc" />
      <circle cx="477.30312" cy="155.20319" r="2.15765" fill="#ccc" />
      <path
        d="M714.24406,193.281a2.1576,2.1576,0,1,0,2.15772,2.15759A2.15738,2.15738,0,0,0,714.24406,193.281Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="494.50381" cy="155.20319" r="2.15765" fill="#ccc" />
      <circle cx="503.11904" cy="155.20319" r="2.15765" fill="#ccc" />
      <path
        d="M740.06047,193.281a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,740.06047,193.281Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="546.15078" cy="155.20319" r="2.15765" fill="#ccc" />
      <circle cx="554.75088" cy="155.20319" r="2.15765" fill="#ccc" />
      <path
        d="M791.707,193.281a2.1576,2.1576,0,1,0,2.15772,2.15759A2.15738,2.15738,0,0,0,791.707,193.281Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="571.9667" cy="155.20319" r="2.15765" fill="#ccc" />
      <circle cx="580.58193" cy="155.20319" r="2.15765" fill="#ccc" />
      <path
        d="M817.52336,193.281a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,817.52336,193.281Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="597.78262" cy="155.20319" r="2.15765" fill="#ccc" />
      <circle cx="606.39785" cy="155.20319" r="2.15765" fill="#ccc" />
      <path
        d="M361.37639,201.89632a2.15762,2.15762,0,1,0,2.15771,2.15765A2.15785,2.15785,0,0,0,361.37639,201.89632Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M369.97649,201.89632a2.15762,2.15762,0,1,0,2.15771,2.15765A2.15743,2.15743,0,0,0,369.97649,201.89632Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M378.59221,201.89632a2.15762,2.15762,0,1,0,2.15771,2.15765A2.15753,2.15753,0,0,0,378.59221,201.89632Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M387.19231,201.89632A2.15762,2.15762,0,1,0,389.35,204.054,2.15785,2.15785,0,0,0,387.19231,201.89632Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M395.79289,201.89632a2.15762,2.15762,0,1,0,2.15723,2.15765A2.15753,2.15753,0,0,0,395.79289,201.89632Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M421.62346,201.89632a2.15762,2.15762,0,1,0,2.15771,2.15765A2.15743,2.15743,0,0,0,421.62346,201.89632Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M430.224,201.89632a2.15762,2.15762,0,1,0,2.15772,2.15765A2.15753,2.15753,0,0,0,430.224,201.89632Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M438.83928,201.89632A2.15762,2.15762,0,1,0,440.997,204.054,2.15785,2.15785,0,0,0,438.83928,201.89632Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M447.43938,206.21156a2.15762,2.15762,0,1,0-2.15723-2.15759A2.15752,2.15752,0,0,0,447.43938,206.21156Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M456.04,206.21156a2.15762,2.15762,0,1,0-2.15771-2.15759A2.15742,2.15742,0,0,0,456.04,206.21156Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M490.47111,206.21156a2.15762,2.15762,0,1,0-2.15771-2.15759A2.15752,2.15752,0,0,0,490.47111,206.21156Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M499.07121,206.21156A2.15762,2.15762,0,1,0,496.914,204.054,2.15752,2.15752,0,0,0,499.07121,206.21156Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M507.68693,206.21156a2.15762,2.15762,0,1,0-2.15771-2.15759A2.15783,2.15783,0,0,0,507.68693,206.21156Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M516.287,201.89632a2.15762,2.15762,0,1,0,2.15772,2.15765A2.15784,2.15784,0,0,0,516.287,201.89632Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M524.90227,201.89632A2.15762,2.15762,0,1,0,527.06,204.054,2.15743,2.15743,0,0,0,524.90227,201.89632Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M567.934,201.89632a2.15762,2.15762,0,1,0,2.15771,2.15765A2.15753,2.15753,0,0,0,567.934,201.89632Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M576.5341,201.89632a2.15762,2.15762,0,1,0,2.15772,2.15765A2.15743,2.15743,0,0,0,576.5341,201.89632Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M585.14983,201.89632a2.15762,2.15762,0,1,0,2.15722,2.15765A2.15753,2.15753,0,0,0,585.14983,201.89632Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M593.74992,201.89632a2.15762,2.15762,0,1,0,2.15772,2.15765A2.15784,2.15784,0,0,0,593.74992,201.89632Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M671.21281,201.89632a2.15762,2.15762,0,1,0,2.15772,2.15765A2.15753,2.15753,0,0,0,671.21281,201.89632Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M679.81291,201.89632a2.15762,2.15762,0,1,0,2.15772,2.15765A2.15744,2.15744,0,0,0,679.81291,201.89632Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M688.42863,201.89632a2.15762,2.15762,0,1,0,2.15723,2.15765A2.15753,2.15753,0,0,0,688.42863,201.89632Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M697.02873,201.89632a2.15762,2.15762,0,1,0,2.15772,2.15765A2.15753,2.15753,0,0,0,697.02873,201.89632Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M705.644,201.89632a2.15762,2.15762,0,1,0,2.15771,2.15765A2.15785,2.15785,0,0,0,705.644,201.89632Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M714.24406,201.89632a2.15762,2.15762,0,1,0,2.15772,2.15765A2.15743,2.15743,0,0,0,714.24406,201.89632Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M722.84465,201.89632a2.15762,2.15762,0,1,0,2.15771,2.15765A2.15754,2.15754,0,0,0,722.84465,201.89632Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M774.49162,201.89632a2.15762,2.15762,0,1,0,2.15772,2.15765A2.15753,2.15753,0,0,0,774.49162,201.89632Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M783.09172,201.89632a2.15762,2.15762,0,1,0,2.15771,2.15765A2.15784,2.15784,0,0,0,783.09172,201.89632Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M791.707,201.89632a2.15762,2.15762,0,1,0,2.15772,2.15765A2.15743,2.15743,0,0,0,791.707,201.89632Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M800.30754,201.89632a2.15762,2.15762,0,1,0,2.15772,2.15765A2.15753,2.15753,0,0,0,800.30754,201.89632Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M808.92277,201.89632a2.15762,2.15762,0,1,0,2.15772,2.15765A2.15784,2.15784,0,0,0,808.92277,201.89632Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M817.52336,201.89632a2.15762,2.15762,0,1,0,2.15723,2.15765A2.15753,2.15753,0,0,0,817.52336,201.89632Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M826.12346,201.89632a2.15762,2.15762,0,1,0,2.15771,2.15765A2.15753,2.15753,0,0,0,826.12346,201.89632Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M834.73869,201.89632a2.15762,2.15762,0,1,0,2.15772,2.15765A2.15784,2.15784,0,0,0,834.73869,201.89632Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M843.33928,201.89632a2.15762,2.15762,0,1,0,2.15723,2.15765A2.15753,2.15753,0,0,0,843.33928,201.89632Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="124.42031" cy="172.41882" r="2.15765" fill="#ccc" />
      <circle cx="133.03555" cy="172.41882" r="2.15765" fill="#ccc" />
      <path
        d="M369.97649,210.4966a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,369.97649,210.4966Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="150.25137" cy="172.41882" r="2.15765" fill="#ccc" />
      <circle cx="158.85146" cy="172.41882" r="2.15765" fill="#ccc" />
      <path
        d="M395.79289,210.4966a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,395.79289,210.4966Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="201.8832" cy="172.41882" r="2.15765" fill="#ccc" />
      <circle cx="210.49844" cy="172.41882" r="2.15765" fill="#ccc" />
      <path
        d="M447.43938,214.81178a2.15759,2.15759,0,1,0-2.15723-2.15759A2.15752,2.15752,0,0,0,447.43938,214.81178Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="262.13027" cy="172.41882" r="2.15765" fill="#ccc" />
      <path
        d="M499.07121,214.81178a2.15759,2.15759,0,1,0-2.15722-2.15759A2.15752,2.15752,0,0,0,499.07121,214.81178Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="279.34609" cy="172.41882" r="2.15765" fill="#ccc" />
      <path
        d="M576.5341,210.4966a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15741,2.15741,0,0,0,576.5341,210.4966Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M585.14983,210.4966a2.15759,2.15759,0,1,0,2.15722,2.15759A2.15752,2.15752,0,0,0,585.14983,210.4966Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M662.59758,210.4966a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,662.59758,210.4966Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="442.87197" cy="172.41882" r="2.15765" fill="#ccc" />
      <path
        d="M679.81291,210.4966a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15742,2.15742,0,0,0,679.81291,210.4966Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M688.42863,210.4966a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,688.42863,210.4966Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="477.30312" cy="172.41882" r="2.15765" fill="#ccc" />
      <path
        d="M714.24406,210.4966a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15741,2.15741,0,0,0,714.24406,210.4966Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="494.50381" cy="172.41882" r="2.15765" fill="#ccc" />
      <circle cx="503.11904" cy="172.41882" r="2.15765" fill="#ccc" />
      <path
        d="M748.6757,210.4966a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,748.6757,210.4966Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M765.89152,210.4966a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,765.89152,210.4966Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="546.15078" cy="172.41882" r="2.15765" fill="#ccc" />
      <circle cx="554.75088" cy="172.41882" r="2.15765" fill="#ccc" />
      <path
        d="M791.707,210.4966a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15741,2.15741,0,0,0,791.707,210.4966Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="571.9667" cy="172.41882" r="2.15765" fill="#ccc" />
      <circle cx="580.58193" cy="172.41882" r="2.15765" fill="#ccc" />
      <path
        d="M817.52336,210.4966a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,817.52336,210.4966Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="597.78262" cy="172.41882" r="2.15765" fill="#ccc" />
      <circle cx="606.39785" cy="172.41882" r="2.15765" fill="#ccc" />
      <path
        d="M843.33928,210.4966a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,843.33928,210.4966Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="623.61367" cy="172.41882" r="2.15765" fill="#ccc" />
      <circle cx="124.42031" cy="181.03418" r="2.15765" fill="#ccc" />
      <circle cx="133.03555" cy="181.03418" r="2.15765" fill="#ccc" />
      <path
        d="M369.97649,219.112a2.1576,2.1576,0,1,0,2.15771,2.1576A2.15742,2.15742,0,0,0,369.97649,219.112Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="184.66738" cy="181.03418" r="2.15765" fill="#ccc" />
      <path
        d="M421.62346,219.112a2.1576,2.1576,0,1,0,2.15771,2.1576A2.15742,2.15742,0,0,0,421.62346,219.112Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="210.49844" cy="181.03418" r="2.15765" fill="#ccc" />
      <circle cx="262.13027" cy="181.03418" r="2.15765" fill="#ccc" />
      <path
        d="M499.07121,223.42714a2.1576,2.1576,0,1,0-2.15722-2.15759A2.15752,2.15752,0,0,0,499.07121,223.42714Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="279.34609" cy="181.03418" r="2.15765" fill="#ccc" />
      <circle cx="425.65615" cy="181.03418" r="2.15765" fill="#ccc" />
      <path
        d="M662.59758,219.112a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,662.59758,219.112Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="442.87197" cy="181.03418" r="2.15765" fill="#ccc" />
      <path
        d="M679.81291,219.112a2.1576,2.1576,0,1,0,2.15772,2.1576A2.15743,2.15743,0,0,0,679.81291,219.112Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="477.30312" cy="181.03418" r="2.15765" fill="#ccc" />
      <path
        d="M714.24406,219.112a2.1576,2.1576,0,1,0,2.15772,2.1576A2.15742,2.15742,0,0,0,714.24406,219.112Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="494.50381" cy="181.03418" r="2.15765" fill="#ccc" />
      <circle cx="503.11904" cy="181.03418" r="2.15765" fill="#ccc" />
      <path
        d="M740.06047,219.112a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,740.06047,219.112Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M748.6757,219.112a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,748.6757,219.112Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="528.93496" cy="181.03418" r="2.15765" fill="#ccc" />
      <path
        d="M765.89152,219.112a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,765.89152,219.112Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="546.15078" cy="181.03418" r="2.15765" fill="#ccc" />
      <circle cx="554.75088" cy="181.03418" r="2.15765" fill="#ccc" />
      <path
        d="M791.707,219.112a2.1576,2.1576,0,1,0,2.15772,2.1576A2.15742,2.15742,0,0,0,791.707,219.112Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="571.9667" cy="181.03418" r="2.15765" fill="#ccc" />
      <circle cx="580.58193" cy="181.03418" r="2.15765" fill="#ccc" />
      <path
        d="M817.52336,219.112a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,817.52336,219.112Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="597.78262" cy="181.03418" r="2.15765" fill="#ccc" />
      <circle cx="606.39785" cy="181.03418" r="2.15765" fill="#ccc" />
      <path
        d="M843.33928,219.112a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,843.33928,219.112Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="623.61367" cy="181.03418" r="2.15765" fill="#ccc" />
      <path
        d="M344.16057,227.71223a2.1576,2.1576,0,1,0,2.15771,2.1576A2.15742,2.15742,0,0,0,344.16057,227.71223Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="124.42031" cy="189.63446" r="2.15765" fill="#ccc" />
      <circle cx="133.03555" cy="189.63446" r="2.15765" fill="#ccc" />
      <circle cx="184.66738" cy="189.63446" r="2.15765" fill="#ccc" />
      <path
        d="M421.62346,227.71223a2.1576,2.1576,0,1,0,2.15771,2.1576A2.15742,2.15742,0,0,0,421.62346,227.71223Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="201.8832" cy="189.63446" r="2.15765" fill="#ccc" />
      <circle cx="279.34609" cy="189.63446" r="2.15765" fill="#ccc" />
      <circle cx="391.225" cy="189.63446" r="2.15765" fill="#ccc" />
      <circle cx="399.84023" cy="189.63446" r="2.15765" fill="#ccc" />
      <circle cx="425.65615" cy="189.63446" r="2.15765" fill="#ccc" />
      <path
        d="M662.59758,227.71223a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,662.59758,227.71223Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="442.87197" cy="189.63446" r="2.15765" fill="#ccc" />
      <path
        d="M679.81291,227.71223a2.1576,2.1576,0,1,0,2.15772,2.1576A2.15743,2.15743,0,0,0,679.81291,227.71223Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="503.11904" cy="189.63446" r="2.15765" fill="#ccc" />
      <path
        d="M740.06047,227.71223a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,740.06047,227.71223Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M748.6757,227.71223a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,748.6757,227.71223Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="528.93496" cy="189.63446" r="2.15765" fill="#ccc" />
      <path
        d="M765.89152,227.71223a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,765.89152,227.71223Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="546.15078" cy="189.63446" r="2.15765" fill="#ccc" />
      <circle cx="554.75088" cy="189.63446" r="2.15765" fill="#ccc" />
      <path
        d="M791.707,227.71223a2.1576,2.1576,0,1,0,2.15772,2.1576A2.15742,2.15742,0,0,0,791.707,227.71223Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="571.9667" cy="189.63446" r="2.15765" fill="#ccc" />
      <circle cx="580.58193" cy="189.63446" r="2.15765" fill="#ccc" />
      <path
        d="M817.52336,227.71223a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,817.52336,227.71223Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="597.78262" cy="189.63446" r="2.15765" fill="#ccc" />
      <circle cx="606.39785" cy="189.63446" r="2.15765" fill="#ccc" />
      <path
        d="M843.33928,227.71223a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,843.33928,227.71223Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="623.61367" cy="189.63446" r="2.15765" fill="#ccc" />
      <circle cx="632.21377" cy="189.63446" r="2.15765" fill="#ccc" />
      <path
        d="M344.16057,236.31252a2.15762,2.15762,0,1,0,2.15771,2.15765A2.15743,2.15743,0,0,0,344.16057,236.31252Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M352.76115,236.31252a2.15762,2.15762,0,1,0,2.15772,2.15765A2.15753,2.15753,0,0,0,352.76115,236.31252Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M361.37639,236.31252a2.15762,2.15762,0,1,0,2.15771,2.15765A2.15785,2.15785,0,0,0,361.37639,236.31252Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M369.97649,236.31252a2.15762,2.15762,0,1,0,2.15771,2.15765A2.15743,2.15743,0,0,0,369.97649,236.31252Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M413.00822,236.31252a2.15762,2.15762,0,1,0,2.15772,2.15765A2.15784,2.15784,0,0,0,413.00822,236.31252Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M421.62346,236.31252a2.15762,2.15762,0,1,0,2.15771,2.15765A2.15743,2.15743,0,0,0,421.62346,236.31252Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M430.224,236.31252a2.15762,2.15762,0,1,0,2.15772,2.15765A2.15753,2.15753,0,0,0,430.224,236.31252Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M438.83928,236.31252a2.15762,2.15762,0,1,0,2.15771,2.15765A2.15785,2.15785,0,0,0,438.83928,236.31252Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M619.56584,236.31252a2.15762,2.15762,0,1,0,2.15772,2.15765A2.15753,2.15753,0,0,0,619.56584,236.31252Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M628.18108,236.31252a2.15762,2.15762,0,1,0,2.15771,2.15765A2.15785,2.15785,0,0,0,628.18108,236.31252Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M653.997,236.31252a2.15762,2.15762,0,1,0,2.15772,2.15765A2.15784,2.15784,0,0,0,653.997,236.31252Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M671.21281,236.31252a2.15762,2.15762,0,1,0,2.15772,2.15765A2.15753,2.15753,0,0,0,671.21281,236.31252Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M679.81291,236.31252a2.15762,2.15762,0,1,0,2.15772,2.15765A2.15744,2.15744,0,0,0,679.81291,236.31252Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M705.644,236.31252a2.15762,2.15762,0,1,0,2.15771,2.15765A2.15785,2.15785,0,0,0,705.644,236.31252Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M714.24406,236.31252a2.15762,2.15762,0,1,0,2.15772,2.15765A2.15743,2.15743,0,0,0,714.24406,236.31252Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M722.84465,236.31252a2.15762,2.15762,0,1,0,2.15771,2.15765A2.15754,2.15754,0,0,0,722.84465,236.31252Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M731.45988,236.31252a2.15762,2.15762,0,1,0,2.15772,2.15765A2.15784,2.15784,0,0,0,731.45988,236.31252Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M740.06047,236.31252a2.15762,2.15762,0,1,0,2.15723,2.15765A2.15753,2.15753,0,0,0,740.06047,236.31252Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M748.6757,236.31252a2.15762,2.15762,0,1,0,2.15723,2.15765A2.15753,2.15753,0,0,0,748.6757,236.31252Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M757.2758,236.31252a2.15762,2.15762,0,1,0,2.15772,2.15765A2.15784,2.15784,0,0,0,757.2758,236.31252Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M765.89152,236.31252a2.15762,2.15762,0,1,0,2.15723,2.15765A2.15753,2.15753,0,0,0,765.89152,236.31252Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M774.49162,236.31252a2.15762,2.15762,0,1,0,2.15772,2.15765A2.15753,2.15753,0,0,0,774.49162,236.31252Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M783.09172,236.31252a2.15762,2.15762,0,1,0,2.15771,2.15765A2.15784,2.15784,0,0,0,783.09172,236.31252Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M791.707,236.31252a2.15762,2.15762,0,1,0,2.15772,2.15765A2.15743,2.15743,0,0,0,791.707,236.31252Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M800.30754,236.31252a2.15762,2.15762,0,1,0,2.15772,2.15765A2.15753,2.15753,0,0,0,800.30754,236.31252Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M808.92277,236.31252a2.15762,2.15762,0,1,0,2.15772,2.15765A2.15784,2.15784,0,0,0,808.92277,236.31252Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M817.52336,236.31252a2.15762,2.15762,0,1,0,2.15723,2.15765A2.15753,2.15753,0,0,0,817.52336,236.31252Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M826.12346,236.31252a2.15762,2.15762,0,1,0,2.15771,2.15765A2.15753,2.15753,0,0,0,826.12346,236.31252Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M834.73869,236.31252a2.15762,2.15762,0,1,0,2.15772,2.15765A2.15784,2.15784,0,0,0,834.73869,236.31252Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M843.33928,236.31252a2.15762,2.15762,0,1,0,2.15723,2.15765A2.15753,2.15753,0,0,0,843.33928,236.31252Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M851.95451,236.31252a2.15762,2.15762,0,1,0,2.15772,2.15765A2.15753,2.15753,0,0,0,851.95451,236.31252Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M860.55461,236.31252a2.15762,2.15762,0,1,0,2.15772,2.15765A2.15784,2.15784,0,0,0,860.55461,236.31252Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M344.16057,244.92787a2.15763,2.15763,0,1,0,2.15771,2.15766A2.15743,2.15743,0,0,0,344.16057,244.92787Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M352.76115,244.92787a2.15763,2.15763,0,1,0,2.15772,2.15766A2.15753,2.15753,0,0,0,352.76115,244.92787Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M361.37639,244.92787a2.15763,2.15763,0,1,0,2.15771,2.15766A2.15785,2.15785,0,0,0,361.37639,244.92787Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M369.97649,244.92787a2.15763,2.15763,0,1,0,2.15771,2.15766A2.15743,2.15743,0,0,0,369.97649,244.92787Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M378.59221,244.92787a2.15763,2.15763,0,1,0,2.15771,2.15766A2.15754,2.15754,0,0,0,378.59221,244.92787Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M413.00822,244.92787a2.15763,2.15763,0,1,0,2.15772,2.15766A2.15785,2.15785,0,0,0,413.00822,244.92787Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M421.62346,244.92787a2.15763,2.15763,0,1,0,2.15771,2.15766A2.15743,2.15743,0,0,0,421.62346,244.92787Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M430.224,244.92787a2.15763,2.15763,0,1,0,2.15772,2.15766A2.15753,2.15753,0,0,0,430.224,244.92787Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M438.83928,244.92787a2.15763,2.15763,0,1,0,2.15771,2.15766A2.15785,2.15785,0,0,0,438.83928,244.92787Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M447.43938,244.92787a2.15763,2.15763,0,1,0,2.00537,2.942c1.50976,2.45623,3.11767,4.79425,4.65527,6.8872a2.13657,2.13657,0,0,0,2.31494,3.04853c2.917,3.72186,5.14209,6.13586,5.14209,6.13586s17.93164-19.46985,18.76758-33.25116c.02979-.34338.04541-.67181.04541-1.0003A18.69963,18.69963,0,0,0,464.498,211.44441a19.07013,19.07013,0,0,0-2.94092-.209,19.66014,19.66014,0,0,0-2.98633.209,18.74069,18.74069,0,0,0-6.61474,2.359,14.54442,14.54442,0,0,0-1.792,1.20941,18.58017,18.58017,0,0,0-7.4502,14.67713c0,.32849.01563.65692.04443,1.0003.27,4.44605,2.32569,9.48255,4.98243,14.268A2.13251,2.13251,0,0,0,447.43938,244.92787Zm14.37109-21.85229A6.59653,6.59653,0,0,1,468.2758,229.69a6.23435,6.23435,0,0,1-.08984,1.07507,6.596,6.596,0,0,1-6.46533,5.56915h-.16358v-.05975a6.581,6.581,0,0,1-6.37549-5.55426,4.91554,4.91554,0,0,1-.08984-1.16467A6.60262,6.60262,0,0,1,461.81047,223.07558Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ff6584"
      />
      <path
        d="M619.56584,244.92787a2.15763,2.15763,0,1,0,2.15772,2.15766A2.15753,2.15753,0,0,0,619.56584,244.92787Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M628.18108,244.92787a2.15763,2.15763,0,1,0,2.15771,2.15766A2.15785,2.15785,0,0,0,628.18108,244.92787Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M662.59758,244.92787a2.15763,2.15763,0,1,0,2.15723,2.15766A2.15754,2.15754,0,0,0,662.59758,244.92787Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M697.02873,244.92787a2.15763,2.15763,0,1,0,2.15772,2.15766A2.15753,2.15753,0,0,0,697.02873,244.92787Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M705.644,244.92787a2.15763,2.15763,0,1,0,2.15771,2.15766A2.15785,2.15785,0,0,0,705.644,244.92787Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M714.24406,244.92787a2.15763,2.15763,0,1,0,2.15772,2.15766A2.15743,2.15743,0,0,0,714.24406,244.92787Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M722.84465,244.92787a2.15763,2.15763,0,1,0,2.15771,2.15766A2.15754,2.15754,0,0,0,722.84465,244.92787Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M731.45988,244.92787a2.15763,2.15763,0,1,0,2.15772,2.15766A2.15785,2.15785,0,0,0,731.45988,244.92787Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M740.06047,244.92787a2.15763,2.15763,0,1,0,2.15723,2.15766A2.15753,2.15753,0,0,0,740.06047,244.92787Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M748.6757,244.92787a2.15763,2.15763,0,1,0,2.15723,2.15766A2.15753,2.15753,0,0,0,748.6757,244.92787Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M757.2758,244.92787a2.15763,2.15763,0,1,0,2.15772,2.15766A2.15785,2.15785,0,0,0,757.2758,244.92787Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M765.89152,244.92787a2.15763,2.15763,0,1,0,2.15723,2.15766A2.15753,2.15753,0,0,0,765.89152,244.92787Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M774.49162,244.92787a2.15763,2.15763,0,1,0,2.15772,2.15766A2.15753,2.15753,0,0,0,774.49162,244.92787Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M783.09172,244.92787a2.15763,2.15763,0,1,0,2.15771,2.15766A2.15784,2.15784,0,0,0,783.09172,244.92787Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M791.707,244.92787a2.15763,2.15763,0,1,0,2.15772,2.15766A2.15743,2.15743,0,0,0,791.707,244.92787Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M800.30754,244.92787a2.15763,2.15763,0,1,0,2.15772,2.15766A2.15753,2.15753,0,0,0,800.30754,244.92787Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M808.92277,244.92787a2.15763,2.15763,0,1,0,2.15772,2.15766A2.15785,2.15785,0,0,0,808.92277,244.92787Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M817.52336,244.92787a2.15763,2.15763,0,1,0,2.15723,2.15766A2.15753,2.15753,0,0,0,817.52336,244.92787Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M826.12346,244.92787a2.15763,2.15763,0,1,0,2.15771,2.15766A2.15754,2.15754,0,0,0,826.12346,244.92787Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M834.73869,244.92787a2.15763,2.15763,0,1,0,2.15772,2.15766A2.15785,2.15785,0,0,0,834.73869,244.92787Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M843.33928,244.92787a2.15763,2.15763,0,1,0,2.15723,2.15766A2.15754,2.15754,0,0,0,843.33928,244.92787Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M851.95451,244.92787a2.15763,2.15763,0,1,0,2.15772,2.15766A2.15753,2.15753,0,0,0,851.95451,244.92787Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M860.55461,244.92787a2.15763,2.15763,0,1,0,2.15772,2.15766A2.15784,2.15784,0,0,0,860.55461,244.92787Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M869.16985,244.92787a2.15763,2.15763,0,1,0,2.15771,2.15766A2.15743,2.15743,0,0,0,869.16985,244.92787Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="107.21963" cy="215.45044" r="2.15765" fill="#ccc" />
      <path
        d="M344.16057,253.52821a2.1576,2.1576,0,1,0,2.15771,2.1576A2.15739,2.15739,0,0,0,344.16057,253.52821Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="124.42031" cy="215.45044" r="2.15765" fill="#ccc" />
      <circle cx="133.03555" cy="215.45044" r="2.15765" fill="#ccc" />
      <path
        d="M369.97649,253.52821a2.1576,2.1576,0,1,0,2.15771,2.1576A2.15739,2.15739,0,0,0,369.97649,253.52821Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="150.25137" cy="215.45044" r="2.15765" fill="#ccc" />
      <circle cx="158.85146" cy="215.45044" r="2.15765" fill="#ccc" />
      <path
        d="M395.79289,253.52821a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15749,2.15749,0,0,0,395.79289,253.52821Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="184.66738" cy="215.45044" r="2.15765" fill="#ccc" />
      <path
        d="M421.62346,253.52821a2.1576,2.1576,0,1,0,2.15771,2.1576A2.15739,2.15739,0,0,0,421.62346,253.52821Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="201.8832" cy="215.45044" r="2.15765" fill="#ccc" />
      <circle cx="210.49844" cy="215.45044" r="2.15765" fill="#ccc" />
      <path
        d="M447.43938,253.52821a2.1576,2.1576,0,1,0,2.15771,2.1576A2.15739,2.15739,0,0,0,447.43938,253.52821Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M610.96574,253.52821a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15749,2.15749,0,0,0,610.96574,253.52821Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="399.84023" cy="215.45044" r="2.15765" fill="#ccc" />
      <path
        d="M662.59758,253.52821a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15749,2.15749,0,0,0,662.59758,253.52821Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M688.42863,253.52821a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15749,2.15749,0,0,0,688.42863,253.52821Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="468.68789" cy="215.45044" r="2.15765" fill="#ccc" />
      <circle cx="477.30312" cy="215.45044" r="2.15765" fill="#ccc" />
      <path
        d="M714.24406,253.52821a2.1576,2.1576,0,1,0,2.15772,2.1576A2.15738,2.15738,0,0,0,714.24406,253.52821Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="494.50381" cy="215.45044" r="2.15765" fill="#ccc" />
      <circle cx="503.11904" cy="215.45044" r="2.15765" fill="#ccc" />
      <path
        d="M740.06047,253.52821a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15749,2.15749,0,0,0,740.06047,253.52821Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M748.6757,253.52821a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15749,2.15749,0,0,0,748.6757,253.52821Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="528.93496" cy="215.45044" r="2.15765" fill="#ccc" />
      <path
        d="M765.89152,253.52821a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15749,2.15749,0,0,0,765.89152,253.52821Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="546.15078" cy="215.45044" r="2.15765" fill="#ccc" />
      <circle cx="554.75088" cy="215.45044" r="2.15765" fill="#ccc" />
      <path
        d="M791.707,253.52821a2.1576,2.1576,0,1,0,2.15772,2.1576A2.15738,2.15738,0,0,0,791.707,253.52821Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="571.9667" cy="215.45044" r="2.15765" fill="#ccc" />
      <circle cx="580.58193" cy="215.45044" r="2.15765" fill="#ccc" />
      <path
        d="M817.52336,253.52821a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15749,2.15749,0,0,0,817.52336,253.52821Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="597.78262" cy="215.45044" r="2.15765" fill="#ccc" />
      <circle cx="606.39785" cy="215.45044" r="2.15765" fill="#ccc" />
      <path
        d="M843.33928,253.52821a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15749,2.15749,0,0,0,843.33928,253.52821Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="623.61367" cy="215.45044" r="2.15765" fill="#ccc" />
      <circle cx="632.21377" cy="215.45044" r="2.15765" fill="#ccc" />
      <path
        d="M869.16985,253.52821a2.1576,2.1576,0,1,0,2.15771,2.1576A2.15739,2.15739,0,0,0,869.16985,253.52821Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="107.21963" cy="224.0658" r="2.15765" fill="#ccc" />
      <path
        d="M344.16057,262.14357a2.1576,2.1576,0,1,0,2.15771,2.15759A2.15738,2.15738,0,0,0,344.16057,262.14357Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="124.42031" cy="224.0658" r="2.15765" fill="#ccc" />
      <circle cx="133.03555" cy="224.0658" r="2.15765" fill="#ccc" />
      <path
        d="M369.97649,262.14357a2.1576,2.1576,0,1,0,2.15771,2.15759A2.15738,2.15738,0,0,0,369.97649,262.14357Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="150.25137" cy="224.0658" r="2.15765" fill="#ccc" />
      <circle cx="158.85146" cy="224.0658" r="2.15765" fill="#ccc" />
      <path
        d="M395.79289,262.14357a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,395.79289,262.14357Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="184.66738" cy="224.0658" r="2.15765" fill="#ccc" />
      <path
        d="M421.62346,262.14357a2.1576,2.1576,0,1,0,2.15771,2.15759A2.15738,2.15738,0,0,0,421.62346,262.14357Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="201.8832" cy="224.0658" r="2.15765" fill="#ccc" />
      <circle cx="210.49844" cy="224.0658" r="2.15765" fill="#ccc" />
      <path
        d="M447.43938,262.14357a2.1576,2.1576,0,1,0,2.15771,2.15759A2.15738,2.15738,0,0,0,447.43938,262.14357Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M456.04,262.14357a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,456.04,262.14357Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="236.31435" cy="224.0658" r="2.15765" fill="#ccc" />
      <path
        d="M473.25529,262.14357a2.1576,2.1576,0,1,0,2.15772,2.15759A2.15738,2.15738,0,0,0,473.25529,262.14357Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M610.96574,262.14357a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,610.96574,262.14357Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="399.84023" cy="224.0658" r="2.15765" fill="#ccc" />
      <path
        d="M636.78166,262.14357a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,636.78166,262.14357Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="425.65615" cy="224.0658" r="2.15765" fill="#ccc" />
      <path
        d="M662.59758,262.14357a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,662.59758,262.14357Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="442.87197" cy="224.0658" r="2.15765" fill="#ccc" />
      <path
        d="M679.81291,262.14357a2.1576,2.1576,0,1,0,2.15772,2.15759A2.15738,2.15738,0,0,0,679.81291,262.14357Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M688.42863,262.14357a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,688.42863,262.14357Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="468.68789" cy="224.0658" r="2.15765" fill="#ccc" />
      <circle cx="477.30312" cy="224.0658" r="2.15765" fill="#ccc" />
      <path
        d="M714.24406,262.14357a2.1576,2.1576,0,1,0,2.15772,2.15759A2.15738,2.15738,0,0,0,714.24406,262.14357Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="494.50381" cy="224.0658" r="2.15765" fill="#ccc" />
      <circle cx="503.11904" cy="224.0658" r="2.15765" fill="#ccc" />
      <path
        d="M740.06047,262.14357a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,740.06047,262.14357Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M748.6757,262.14357a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,748.6757,262.14357Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="528.93496" cy="224.0658" r="2.15765" fill="#ccc" />
      <path
        d="M765.89152,262.14357a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,765.89152,262.14357Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="546.15078" cy="224.0658" r="2.15765" fill="#ccc" />
      <circle cx="554.75088" cy="224.0658" r="2.15765" fill="#ccc" />
      <path
        d="M791.707,262.14357a2.1576,2.1576,0,1,0,2.15772,2.15759A2.15738,2.15738,0,0,0,791.707,262.14357Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="571.9667" cy="224.0658" r="2.15765" fill="#ccc" />
      <circle cx="580.58193" cy="224.0658" r="2.15765" fill="#ccc" />
      <path
        d="M817.52336,262.14357a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,817.52336,262.14357Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="597.78262" cy="224.0658" r="2.15765" fill="#ccc" />
      <circle cx="606.39785" cy="224.0658" r="2.15765" fill="#ccc" />
      <path
        d="M843.33928,262.14357a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,843.33928,262.14357Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="623.61367" cy="224.0658" r="2.15765" fill="#ccc" />
      <circle cx="632.21377" cy="224.0658" r="2.15765" fill="#ccc" />
      <path
        d="M869.16985,262.14357a2.1576,2.1576,0,1,0,2.15771,2.15759A2.15738,2.15738,0,0,0,869.16985,262.14357Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="107.21963" cy="232.66602" r="2.15765" fill="#ccc" />
      <path
        d="M344.16057,270.74379a2.1576,2.1576,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,344.16057,270.74379Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="124.42031" cy="232.66602" r="2.15765" fill="#ccc" />
      <circle cx="133.03555" cy="232.66602" r="2.15765" fill="#ccc" />
      <path
        d="M369.97649,270.74379a2.1576,2.1576,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,369.97649,270.74379Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="150.25137" cy="232.66602" r="2.15765" fill="#ccc" />
      <circle cx="158.85146" cy="232.66602" r="2.15765" fill="#ccc" />
      <path
        d="M395.79289,270.74379a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,395.79289,270.74379Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M404.40813,270.74379a2.1576,2.1576,0,1,0,2.15722,2.15759A2.15752,2.15752,0,0,0,404.40813,270.74379Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="184.66738" cy="232.66602" r="2.15765" fill="#ccc" />
      <path
        d="M421.62346,270.74379a2.1576,2.1576,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,421.62346,270.74379Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="201.8832" cy="232.66602" r="2.15765" fill="#ccc" />
      <circle cx="210.49844" cy="232.66602" r="2.15765" fill="#ccc" />
      <path
        d="M447.43938,270.74379a2.1576,2.1576,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,447.43938,270.74379Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M456.04,270.74379a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,456.04,270.74379Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M473.25529,270.74379a2.1576,2.1576,0,1,0,2.15772,2.15759A2.15741,2.15741,0,0,0,473.25529,270.74379Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="417.05605" cy="232.66602" r="2.15765" fill="#ccc" />
      <circle cx="425.65615" cy="232.66602" r="2.15765" fill="#ccc" />
      <path
        d="M662.59758,270.74379a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,662.59758,270.74379Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="442.87197" cy="232.66602" r="2.15765" fill="#ccc" />
      <path
        d="M679.81291,270.74379a2.1576,2.1576,0,1,0,2.15772,2.15759A2.15742,2.15742,0,0,0,679.81291,270.74379Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M688.42863,270.74379a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,688.42863,270.74379Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="468.68789" cy="232.66602" r="2.15765" fill="#ccc" />
      <circle cx="477.30312" cy="232.66602" r="2.15765" fill="#ccc" />
      <path
        d="M714.24406,270.74379a2.1576,2.1576,0,1,0,2.15772,2.15759A2.15741,2.15741,0,0,0,714.24406,270.74379Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="494.50381" cy="232.66602" r="2.15765" fill="#ccc" />
      <circle cx="503.11904" cy="232.66602" r="2.15765" fill="#ccc" />
      <path
        d="M740.06047,270.74379a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,740.06047,270.74379Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M748.6757,270.74379a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,748.6757,270.74379Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="528.93496" cy="232.66602" r="2.15765" fill="#ccc" />
      <path
        d="M765.89152,270.74379a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,765.89152,270.74379Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="546.15078" cy="232.66602" r="2.15765" fill="#ccc" />
      <circle cx="554.75088" cy="232.66602" r="2.15765" fill="#ccc" />
      <path
        d="M791.707,270.74379a2.1576,2.1576,0,1,0,2.15772,2.15759A2.15741,2.15741,0,0,0,791.707,270.74379Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="571.9667" cy="232.66602" r="2.15765" fill="#ccc" />
      <circle cx="580.58193" cy="232.66602" r="2.15765" fill="#ccc" />
      <path
        d="M817.52336,270.74379a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,817.52336,270.74379Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="597.78262" cy="232.66602" r="2.15765" fill="#ccc" />
      <circle cx="606.39785" cy="232.66602" r="2.15765" fill="#ccc" />
      <path
        d="M843.33928,270.74379a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,843.33928,270.74379Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="623.61367" cy="232.66602" r="2.15765" fill="#ccc" />
      <circle cx="632.21377" cy="232.66602" r="2.15765" fill="#ccc" />
      <path
        d="M869.16985,270.74379a2.1576,2.1576,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,869.16985,270.74379Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M877.77043,270.74379a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,877.77043,270.74379Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M326.94524,279.35921a2.15759,2.15759,0,1,0,2.15722,2.15759A2.15752,2.15752,0,0,0,326.94524,279.35921Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="107.21963" cy="241.28143" r="2.15765" fill="#ccc" />
      <path
        d="M344.16057,279.35921a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,344.16057,279.35921Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="124.42031" cy="241.28143" r="2.15765" fill="#ccc" />
      <circle cx="133.03555" cy="241.28143" r="2.15765" fill="#ccc" />
      <path
        d="M369.97649,279.35921a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,369.97649,279.35921Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="150.25137" cy="241.28143" r="2.15765" fill="#ccc" />
      <circle cx="158.85146" cy="241.28143" r="2.15765" fill="#ccc" />
      <path
        d="M395.79289,279.35921a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,395.79289,279.35921Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M404.40813,279.35921a2.15759,2.15759,0,1,0,2.15722,2.15759A2.15752,2.15752,0,0,0,404.40813,279.35921Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="184.66738" cy="241.28143" r="2.15765" fill="#ccc" />
      <path
        d="M421.62346,279.35921a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,421.62346,279.35921Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="201.8832" cy="241.28143" r="2.15765" fill="#ccc" />
      <circle cx="210.49844" cy="241.28143" r="2.15765" fill="#ccc" />
      <path
        d="M447.43938,279.35921a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,447.43938,279.35921Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="236.31435" cy="241.28143" r="2.15765" fill="#ccc" />
      <path
        d="M473.25529,279.35921a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15741,2.15741,0,0,0,473.25529,279.35921Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M481.871,279.35921a2.15759,2.15759,0,1,0,2.15722,2.15759A2.15752,2.15752,0,0,0,481.871,279.35921Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="399.84023" cy="241.28143" r="2.15765" fill="#ccc" />
      <path
        d="M636.78166,279.35921a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,636.78166,279.35921Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="417.05605" cy="241.28143" r="2.15765" fill="#ccc" />
      <circle cx="425.65615" cy="241.28143" r="2.15765" fill="#ccc" />
      <path
        d="M662.59758,279.35921a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,662.59758,279.35921Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="442.87197" cy="241.28143" r="2.15765" fill="#ccc" />
      <path
        d="M679.81291,279.35921a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15742,2.15742,0,0,0,679.81291,279.35921Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M688.42863,279.35921a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,688.42863,279.35921Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="468.68789" cy="241.28143" r="2.15765" fill="#ccc" />
      <circle cx="477.30312" cy="241.28143" r="2.15765" fill="#ccc" />
      <path
        d="M714.24406,279.35921a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15741,2.15741,0,0,0,714.24406,279.35921Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="494.50381" cy="241.28143" r="2.15765" fill="#ccc" />
      <circle cx="503.11904" cy="241.28143" r="2.15765" fill="#ccc" />
      <path
        d="M740.06047,279.35921a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,740.06047,279.35921Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M748.6757,279.35921a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,748.6757,279.35921Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="528.93496" cy="241.28143" r="2.15765" fill="#ccc" />
      <path
        d="M765.89152,279.35921a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,765.89152,279.35921Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="546.15078" cy="241.28143" r="2.15765" fill="#ccc" />
      <circle cx="554.75088" cy="241.28143" r="2.15765" fill="#ccc" />
      <path
        d="M791.707,279.35921a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15741,2.15741,0,0,0,791.707,279.35921Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="571.9667" cy="241.28143" r="2.15765" fill="#ccc" />
      <circle cx="580.58193" cy="241.28143" r="2.15765" fill="#ccc" />
      <path
        d="M817.52336,279.35921a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,817.52336,279.35921Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="597.78262" cy="241.28143" r="2.15765" fill="#ccc" />
      <circle cx="606.39785" cy="241.28143" r="2.15765" fill="#ccc" />
      <path
        d="M843.33928,279.35921a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,843.33928,279.35921Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="623.61367" cy="241.28143" r="2.15765" fill="#ccc" />
      <circle cx="632.21377" cy="241.28143" r="2.15765" fill="#ccc" />
      <path
        d="M869.16985,279.35921a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,869.16985,279.35921Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M877.77043,279.35921a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,877.77043,279.35921Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M326.94524,287.95943a2.15762,2.15762,0,1,0,2.15722,2.15759A2.15752,2.15752,0,0,0,326.94524,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M335.56047,287.95943a2.15762,2.15762,0,1,0,2.15771,2.15759A2.15783,2.15783,0,0,0,335.56047,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M344.16057,287.95943a2.15762,2.15762,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,344.16057,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M352.76115,287.95943a2.15762,2.15762,0,1,0,2.15772,2.15759A2.15752,2.15752,0,0,0,352.76115,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M361.37639,287.95943a2.15762,2.15762,0,1,0,2.15771,2.15759A2.15783,2.15783,0,0,0,361.37639,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M369.97649,287.95943a2.15762,2.15762,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,369.97649,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M378.59221,287.95943a2.15762,2.15762,0,1,0,2.15771,2.15759A2.15752,2.15752,0,0,0,378.59221,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M387.19231,287.95943A2.15762,2.15762,0,1,0,389.35,290.117,2.15783,2.15783,0,0,0,387.19231,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M395.79289,287.95943a2.15762,2.15762,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,395.79289,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M404.40813,287.95943a2.15762,2.15762,0,1,0,2.15722,2.15759A2.15752,2.15752,0,0,0,404.40813,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M413.00822,287.95943a2.15762,2.15762,0,1,0,2.15772,2.15759A2.15783,2.15783,0,0,0,413.00822,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M421.62346,287.95943a2.15762,2.15762,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,421.62346,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M430.224,287.95943a2.15762,2.15762,0,1,0,2.15772,2.15759A2.15752,2.15752,0,0,0,430.224,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M438.83928,287.95943A2.15762,2.15762,0,1,0,440.997,290.117,2.15783,2.15783,0,0,0,438.83928,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M447.43938,287.95943a2.15762,2.15762,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,447.43938,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M456.04,287.95943a2.15762,2.15762,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,456.04,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M636.78166,287.95943a2.15762,2.15762,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,636.78166,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M645.3969,287.95943a2.15762,2.15762,0,1,0,2.15771,2.15759A2.15752,2.15752,0,0,0,645.3969,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M653.997,287.95943a2.15762,2.15762,0,1,0,2.15772,2.15759A2.15783,2.15783,0,0,0,653.997,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M662.59758,287.95943a2.15762,2.15762,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,662.59758,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M671.21281,287.95943a2.15762,2.15762,0,1,0,2.15772,2.15759A2.15752,2.15752,0,0,0,671.21281,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M679.81291,287.95943a2.15762,2.15762,0,1,0,2.15772,2.15759A2.15742,2.15742,0,0,0,679.81291,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M688.42863,287.95943a2.15762,2.15762,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,688.42863,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M697.02873,287.95943a2.15762,2.15762,0,1,0,2.15772,2.15759A2.15752,2.15752,0,0,0,697.02873,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M705.644,287.95943a2.15762,2.15762,0,1,0,2.15771,2.15759A2.15783,2.15783,0,0,0,705.644,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M714.24406,287.95943a2.15762,2.15762,0,1,0,2.15772,2.15759A2.15741,2.15741,0,0,0,714.24406,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M722.84465,287.95943a2.15762,2.15762,0,1,0,2.15771,2.15759A2.15753,2.15753,0,0,0,722.84465,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M731.45988,287.95943a2.15762,2.15762,0,1,0,2.15772,2.15759A2.15783,2.15783,0,0,0,731.45988,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M740.06047,287.95943a2.15762,2.15762,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,740.06047,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M748.6757,287.95943a2.15762,2.15762,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,748.6757,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M757.2758,287.95943a2.15762,2.15762,0,1,0,2.15772,2.15759A2.15783,2.15783,0,0,0,757.2758,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M765.89152,287.95943a2.15762,2.15762,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,765.89152,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M774.49162,287.95943a2.15762,2.15762,0,1,0,2.15772,2.15759A2.15752,2.15752,0,0,0,774.49162,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M783.09172,287.95943a2.15762,2.15762,0,1,0,2.15771,2.15759A2.15783,2.15783,0,0,0,783.09172,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M791.707,287.95943a2.15762,2.15762,0,1,0,2.15772,2.15759A2.15741,2.15741,0,0,0,791.707,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M800.30754,287.95943a2.15762,2.15762,0,1,0,2.15772,2.15759A2.15752,2.15752,0,0,0,800.30754,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M808.92277,287.95943a2.15762,2.15762,0,1,0,2.15772,2.15759A2.15783,2.15783,0,0,0,808.92277,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M817.52336,287.95943a2.15762,2.15762,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,817.52336,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M826.12346,287.95943a2.15762,2.15762,0,1,0,2.15771,2.15759A2.15752,2.15752,0,0,0,826.12346,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M834.73869,287.95943a2.15762,2.15762,0,1,0,2.15772,2.15759A2.15783,2.15783,0,0,0,834.73869,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M843.33928,287.95943a2.15762,2.15762,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,843.33928,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M851.95451,287.95943a2.15762,2.15762,0,1,0,2.15772,2.15759A2.15752,2.15752,0,0,0,851.95451,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M860.55461,287.95943a2.15762,2.15762,0,1,0,2.15772,2.15759A2.15783,2.15783,0,0,0,860.55461,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M869.16985,287.95943a2.15762,2.15762,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,869.16985,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M877.77043,287.95943a2.15762,2.15762,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,877.77043,287.95943Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M326.94524,296.55977a2.15759,2.15759,0,1,0,2.15722,2.15759A2.15748,2.15748,0,0,0,326.94524,296.55977Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="107.21963" cy="258.48199" r="2.15765" fill="#ccc" />
      <path
        d="M344.16057,296.55977a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15738,2.15738,0,0,0,344.16057,296.55977Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="124.42031" cy="258.48199" r="2.15765" fill="#ccc" />
      <circle cx="133.03555" cy="258.48199" r="2.15765" fill="#ccc" />
      <path
        d="M369.97649,296.55977a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15738,2.15738,0,0,0,369.97649,296.55977Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="150.25137" cy="258.48199" r="2.15765" fill="#ccc" />
      <circle cx="158.85146" cy="258.48199" r="2.15765" fill="#ccc" />
      <path
        d="M395.79289,296.55977a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,395.79289,296.55977Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M404.40813,296.55977a2.15759,2.15759,0,1,0,2.15722,2.15759A2.15748,2.15748,0,0,0,404.40813,296.55977Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="184.66738" cy="258.48199" r="2.15765" fill="#ccc" />
      <path
        d="M421.62346,296.55977a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15738,2.15738,0,0,0,421.62346,296.55977Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="201.8832" cy="258.48199" r="2.15765" fill="#ccc" />
      <path
        d="M447.43938,296.55977a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15738,2.15738,0,0,0,447.43938,296.55977Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M610.96574,296.55977a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,610.96574,296.55977Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="391.225" cy="258.48199" r="2.15765" fill="#ccc" />
      <circle cx="399.84023" cy="258.48199" r="2.15765" fill="#ccc" />
      <path
        d="M636.78166,296.55977a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,636.78166,296.55977Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="417.05605" cy="258.48199" r="2.15765" fill="#ccc" />
      <circle cx="425.65615" cy="258.48199" r="2.15765" fill="#ccc" />
      <circle cx="442.87197" cy="258.48199" r="2.15765" fill="#ccc" />
      <path
        d="M688.42863,296.55977a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,688.42863,296.55977Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="468.68789" cy="258.48199" r="2.15765" fill="#ccc" />
      <circle cx="477.30312" cy="258.48199" r="2.15765" fill="#ccc" />
      <path
        d="M714.24406,296.55977a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15738,2.15738,0,0,0,714.24406,296.55977Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="494.50381" cy="258.48199" r="2.15765" fill="#ccc" />
      <circle cx="503.11904" cy="258.48199" r="2.15765" fill="#ccc" />
      <path
        d="M740.06047,296.55977a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,740.06047,296.55977Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M748.6757,296.55977a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,748.6757,296.55977Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="528.93496" cy="258.48199" r="2.15765" fill="#ccc" />
      <path
        d="M765.89152,296.55977a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,765.89152,296.55977Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="546.15078" cy="258.48199" r="2.15765" fill="#ccc" />
      <circle cx="554.75088" cy="258.48199" r="2.15765" fill="#ccc" />
      <path
        d="M791.707,296.55977a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15738,2.15738,0,0,0,791.707,296.55977Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="571.9667" cy="258.48199" r="2.15765" fill="#ccc" />
      <circle cx="580.58193" cy="258.48199" r="2.15765" fill="#ccc" />
      <path
        d="M817.52336,296.55977a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,817.52336,296.55977Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="597.78262" cy="258.48199" r="2.15765" fill="#ccc" />
      <circle cx="606.39785" cy="258.48199" r="2.15765" fill="#ccc" />
      <path
        d="M843.33928,296.55977a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,843.33928,296.55977Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="623.61367" cy="258.48199" r="2.15765" fill="#ccc" />
      <circle cx="632.21377" cy="258.48199" r="2.15765" fill="#ccc" />
      <path
        d="M869.16985,296.55977a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15738,2.15738,0,0,0,869.16985,296.55977Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M877.77043,296.55977a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,877.77043,296.55977Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M326.94524,305.17512a2.1576,2.1576,0,1,0,2.15722,2.1576A2.15749,2.15749,0,0,0,326.94524,305.17512Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="107.21963" cy="267.09735" r="2.15765" fill="#ccc" />
      <path
        d="M344.16057,305.17512a2.1576,2.1576,0,1,0,2.15771,2.1576A2.15739,2.15739,0,0,0,344.16057,305.17512Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="124.42031" cy="267.09735" r="2.15765" fill="#ccc" />
      <circle cx="133.03555" cy="267.09735" r="2.15765" fill="#ccc" />
      <path
        d="M369.97649,305.17512a2.1576,2.1576,0,1,0,2.15771,2.1576A2.15739,2.15739,0,0,0,369.97649,305.17512Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="150.25137" cy="267.09735" r="2.15765" fill="#ccc" />
      <circle cx="158.85146" cy="267.09735" r="2.15765" fill="#ccc" />
      <path
        d="M395.79289,305.17512a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15749,2.15749,0,0,0,395.79289,305.17512Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M404.40813,305.17512a2.1576,2.1576,0,1,0,2.15722,2.1576A2.15749,2.15749,0,0,0,404.40813,305.17512Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="184.66738" cy="267.09735" r="2.15765" fill="#ccc" />
      <path
        d="M421.62346,305.17512a2.1576,2.1576,0,1,0,2.15771,2.1576A2.15739,2.15739,0,0,0,421.62346,305.17512Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="201.8832" cy="267.09735" r="2.15765" fill="#ccc" />
      <path
        d="M610.96574,305.17512a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15749,2.15749,0,0,0,610.96574,305.17512Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="391.225" cy="267.09735" r="2.15765" fill="#ccc" />
      <circle cx="399.84023" cy="267.09735" r="2.15765" fill="#ccc" />
      <path
        d="M636.78166,305.17512a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15749,2.15749,0,0,0,636.78166,305.17512Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="442.87197" cy="267.09735" r="2.15765" fill="#ccc" />
      <path
        d="M679.81291,305.17512a2.1576,2.1576,0,1,0,2.15772,2.1576A2.15739,2.15739,0,0,0,679.81291,305.17512Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="468.68789" cy="267.09735" r="2.15765" fill="#ccc" />
      <circle cx="477.30312" cy="267.09735" r="2.15765" fill="#ccc" />
      <path
        d="M714.24406,305.17512a2.1576,2.1576,0,1,0,2.15772,2.1576A2.15738,2.15738,0,0,0,714.24406,305.17512Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="494.50381" cy="267.09735" r="2.15765" fill="#ccc" />
      <circle cx="503.11904" cy="267.09735" r="2.15765" fill="#ccc" />
      <path
        d="M740.06047,305.17512a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15749,2.15749,0,0,0,740.06047,305.17512Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M748.6757,305.17512a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15749,2.15749,0,0,0,748.6757,305.17512Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="528.93496" cy="267.09735" r="2.15765" fill="#ccc" />
      <path
        d="M765.89152,305.17512a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15749,2.15749,0,0,0,765.89152,305.17512Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="546.15078" cy="267.09735" r="2.15765" fill="#ccc" />
      <circle cx="554.75088" cy="267.09735" r="2.15765" fill="#ccc" />
      <path
        d="M791.707,305.17512a2.1576,2.1576,0,1,0,2.15772,2.1576A2.15738,2.15738,0,0,0,791.707,305.17512Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="571.9667" cy="267.09735" r="2.15765" fill="#ccc" />
      <circle cx="580.58193" cy="267.09735" r="2.15765" fill="#ccc" />
      <path
        d="M817.52336,305.17512a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15749,2.15749,0,0,0,817.52336,305.17512Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="597.78262" cy="267.09735" r="2.15765" fill="#ccc" />
      <circle cx="606.39785" cy="267.09735" r="2.15765" fill="#ccc" />
      <path
        d="M843.33928,305.17512a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15749,2.15749,0,0,0,843.33928,305.17512Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="623.61367" cy="267.09735" r="2.15765" fill="#ccc" />
      <circle cx="632.21377" cy="267.09735" r="2.15765" fill="#ccc" />
      <path
        d="M869.16985,305.17512a2.1576,2.1576,0,1,0,2.15771,2.1576A2.15739,2.15739,0,0,0,869.16985,305.17512Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M877.77043,305.17512a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15749,2.15749,0,0,0,877.77043,305.17512Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="658.02969" cy="267.09735" r="2.15765" fill="#ccc" />
      <path
        d="M326.94524,313.77541a2.15759,2.15759,0,1,0,2.15722,2.15759A2.15752,2.15752,0,0,0,326.94524,313.77541Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="107.21963" cy="275.69763" r="2.15765" fill="#ccc" />
      <path
        d="M344.16057,313.77541a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,344.16057,313.77541Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="124.42031" cy="275.69763" r="2.15765" fill="#ccc" />
      <circle cx="133.03555" cy="275.69763" r="2.15765" fill="#ccc" />
      <path
        d="M369.97649,313.77541a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,369.97649,313.77541Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="150.25137" cy="275.69763" r="2.15765" fill="#ccc" />
      <circle cx="158.85146" cy="275.69763" r="2.15765" fill="#ccc" />
      <path
        d="M395.79289,313.77541a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,395.79289,313.77541Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M404.40813,313.77541a2.15759,2.15759,0,1,0,2.15722,2.15759A2.15752,2.15752,0,0,0,404.40813,313.77541Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="184.66738" cy="275.69763" r="2.15765" fill="#ccc" />
      <path
        d="M610.96574,313.77541A2.15759,2.15759,0,1,0,613.123,315.933,2.15752,2.15752,0,0,0,610.96574,313.77541Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="391.225" cy="275.69763" r="2.15765" fill="#ccc" />
      <circle cx="399.84023" cy="275.69763" r="2.15765" fill="#ccc" />
      <circle cx="468.68789" cy="275.69763" r="2.15765" fill="#ccc" />
      <path
        d="M714.24406,313.77541a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15741,2.15741,0,0,0,714.24406,313.77541Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="494.50381" cy="275.69763" r="2.15765" fill="#ccc" />
      <circle cx="503.11904" cy="275.69763" r="2.15765" fill="#ccc" />
      <path
        d="M740.06047,313.77541a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,740.06047,313.77541Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M748.6757,313.77541a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,748.6757,313.77541Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="528.93496" cy="275.69763" r="2.15765" fill="#ccc" />
      <path
        d="M765.89152,313.77541a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,765.89152,313.77541Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="546.15078" cy="275.69763" r="2.15765" fill="#ccc" />
      <circle cx="554.75088" cy="275.69763" r="2.15765" fill="#ccc" />
      <path
        d="M791.707,313.77541a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15741,2.15741,0,0,0,791.707,313.77541Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="571.9667" cy="275.69763" r="2.15765" fill="#ccc" />
      <circle cx="580.58193" cy="275.69763" r="2.15765" fill="#ccc" />
      <path
        d="M817.52336,313.77541a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,817.52336,313.77541Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="597.78262" cy="275.69763" r="2.15765" fill="#ccc" />
      <circle cx="606.39785" cy="275.69763" r="2.15765" fill="#ccc" />
      <path
        d="M843.33928,313.77541a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,843.33928,313.77541Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="623.61367" cy="275.69763" r="2.15765" fill="#ccc" />
      <circle cx="632.21377" cy="275.69763" r="2.15765" fill="#ccc" />
      <path
        d="M869.16985,313.77541a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,869.16985,313.77541Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M877.77043,313.77541a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,877.77043,313.77541Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="658.02969" cy="275.69763" r="2.15765" fill="#ccc" />
      <path
        d="M326.94524,322.39076a2.1576,2.1576,0,1,0,2.15722,2.15759A2.15753,2.15753,0,0,0,326.94524,322.39076Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="107.21963" cy="284.31299" r="2.15765" fill="#ccc" />
      <path
        d="M344.16057,322.39076a2.1576,2.1576,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,344.16057,322.39076Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="124.42031" cy="284.31299" r="2.15765" fill="#ccc" />
      <circle cx="133.03555" cy="284.31299" r="2.15765" fill="#ccc" />
      <path
        d="M369.97649,322.39076a2.1576,2.1576,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,369.97649,322.39076Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="150.25137" cy="284.31299" r="2.15765" fill="#ccc" />
      <circle cx="158.85146" cy="284.31299" r="2.15765" fill="#ccc" />
      <path
        d="M395.79289,322.39076a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,395.79289,322.39076Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M404.40813,322.39076a2.1576,2.1576,0,1,0,2.15722,2.15759A2.15753,2.15753,0,0,0,404.40813,322.39076Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="184.66738" cy="284.31299" r="2.15765" fill="#ccc" />
      <circle cx="391.225" cy="284.31299" r="2.15765" fill="#ccc" />
      <circle cx="417.05605" cy="284.31299" r="2.15765" fill="#ccc" />
      <circle cx="425.65615" cy="284.31299" r="2.15765" fill="#ccc" />
      <path
        d="M662.59758,322.39076a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15753,2.15753,0,0,0,662.59758,322.39076Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M714.24406,322.39076a2.1576,2.1576,0,1,0,2.15772,2.15759A2.15742,2.15742,0,0,0,714.24406,322.39076Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="494.50381" cy="284.31299" r="2.15765" fill="#ccc" />
      <circle cx="503.11904" cy="284.31299" r="2.15765" fill="#ccc" />
      <path
        d="M740.06047,322.39076a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,740.06047,322.39076Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M748.6757,322.39076a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,748.6757,322.39076Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="528.93496" cy="284.31299" r="2.15765" fill="#ccc" />
      <path
        d="M765.89152,322.39076a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,765.89152,322.39076Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="546.15078" cy="284.31299" r="2.15765" fill="#ccc" />
      <circle cx="554.75088" cy="284.31299" r="2.15765" fill="#ccc" />
      <path
        d="M791.707,322.39076a2.1576,2.1576,0,1,0,2.15772,2.15759A2.15742,2.15742,0,0,0,791.707,322.39076Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="571.9667" cy="284.31299" r="2.15765" fill="#ccc" />
      <circle cx="580.58193" cy="284.31299" r="2.15765" fill="#ccc" />
      <path
        d="M817.52336,322.39076a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,817.52336,322.39076Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="597.78262" cy="284.31299" r="2.15765" fill="#ccc" />
      <circle cx="606.39785" cy="284.31299" r="2.15765" fill="#ccc" />
      <path
        d="M843.33928,322.39076a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15753,2.15753,0,0,0,843.33928,322.39076Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="623.61367" cy="284.31299" r="2.15765" fill="#ccc" />
      <circle cx="632.21377" cy="284.31299" r="2.15765" fill="#ccc" />
      <path
        d="M869.16985,322.39076a2.1576,2.1576,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,869.16985,322.39076Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M877.77043,322.39076a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,877.77043,322.39076Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="658.02969" cy="284.31299" r="2.15765" fill="#ccc" />
      <path
        d="M326.94524,330.991a2.1576,2.1576,0,1,0,2.15722,2.1576A2.15753,2.15753,0,0,0,326.94524,330.991Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="107.21963" cy="292.91327" r="2.15765" fill="#ccc" />
      <path
        d="M344.16057,330.991a2.1576,2.1576,0,1,0,2.15771,2.1576A2.15742,2.15742,0,0,0,344.16057,330.991Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="124.42031" cy="292.91327" r="2.15765" fill="#ccc" />
      <circle cx="133.03555" cy="292.91327" r="2.15765" fill="#ccc" />
      <path
        d="M369.97649,330.991a2.1576,2.1576,0,1,0,2.15771,2.1576A2.15742,2.15742,0,0,0,369.97649,330.991Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="150.25137" cy="292.91327" r="2.15765" fill="#ccc" />
      <circle cx="158.85146" cy="292.91327" r="2.15765" fill="#ccc" />
      <path
        d="M395.79289,330.991a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,395.79289,330.991Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M404.40813,330.991a2.1576,2.1576,0,1,0,2.15722,2.1576A2.15753,2.15753,0,0,0,404.40813,330.991Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="391.225" cy="292.91327" r="2.15765" fill="#ccc" />
      <circle cx="399.84023" cy="292.91327" r="2.15765" fill="#ccc" />
      <path
        d="M636.78166,330.991a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,636.78166,330.991Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="417.05605" cy="292.91327" r="2.15765" fill="#ccc" />
      <circle cx="425.65615" cy="292.91327" r="2.15765" fill="#ccc" />
      <path
        d="M662.59758,330.991a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,662.59758,330.991Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M740.06047,330.991a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,740.06047,330.991Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M748.6757,330.991a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,748.6757,330.991Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="528.93496" cy="292.91327" r="2.15765" fill="#ccc" />
      <path
        d="M765.89152,330.991a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,765.89152,330.991Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="546.15078" cy="292.91327" r="2.15765" fill="#ccc" />
      <circle cx="554.75088" cy="292.91327" r="2.15765" fill="#ccc" />
      <path
        d="M791.707,330.991a2.1576,2.1576,0,1,0,2.15772,2.1576A2.15742,2.15742,0,0,0,791.707,330.991Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="571.9667" cy="292.91327" r="2.15765" fill="#ccc" />
      <circle cx="580.58193" cy="292.91327" r="2.15765" fill="#ccc" />
      <path
        d="M817.52336,330.991a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,817.52336,330.991Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="597.78262" cy="292.91327" r="2.15765" fill="#ccc" />
      <circle cx="606.39785" cy="292.91327" r="2.15765" fill="#ccc" />
      <path
        d="M843.33928,330.991a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,843.33928,330.991Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="623.61367" cy="292.91327" r="2.15765" fill="#ccc" />
      <circle cx="632.21377" cy="292.91327" r="2.15765" fill="#ccc" />
      <path
        d="M869.16985,330.991a2.1576,2.1576,0,1,0,2.15771,2.1576A2.15742,2.15742,0,0,0,869.16985,330.991Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M877.77043,330.991a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,877.77043,330.991Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="658.02969" cy="292.91327" r="2.15765" fill="#ccc" />
      <path
        d="M326.94524,339.6064a2.15759,2.15759,0,1,0,2.15722,2.15759A2.15752,2.15752,0,0,0,326.94524,339.6064Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="107.21963" cy="301.52863" r="2.15765" fill="#ccc" />
      <path
        d="M344.16057,339.6064a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,344.16057,339.6064Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="124.42031" cy="301.52863" r="2.15765" fill="#ccc" />
      <circle cx="133.03555" cy="301.52863" r="2.15765" fill="#ccc" />
      <path
        d="M369.97649,339.6064a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,369.97649,339.6064Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="150.25137" cy="301.52863" r="2.15765" fill="#ccc" />
      <circle cx="158.85146" cy="301.52863" r="2.15765" fill="#ccc" />
      <path
        d="M395.79289,339.6064a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,395.79289,339.6064Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M404.40813,339.6064a2.15759,2.15759,0,1,0,2.15722,2.15759A2.15752,2.15752,0,0,0,404.40813,339.6064Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M610.96574,339.6064A2.15759,2.15759,0,1,0,613.123,341.764,2.15752,2.15752,0,0,0,610.96574,339.6064Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="391.225" cy="301.52863" r="2.15765" fill="#ccc" />
      <circle cx="399.84023" cy="301.52863" r="2.15765" fill="#ccc" />
      <path
        d="M636.78166,339.6064a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,636.78166,339.6064Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="417.05605" cy="301.52863" r="2.15765" fill="#ccc" />
      <circle cx="425.65615" cy="301.52863" r="2.15765" fill="#ccc" />
      <path
        d="M662.59758,339.6064a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,662.59758,339.6064Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="442.87197" cy="301.52863" r="2.15765" fill="#ccc" />
      <circle cx="468.68789" cy="301.52863" r="2.15765" fill="#ccc" />
      <path
        d="M740.06047,339.6064a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,740.06047,339.6064Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M748.6757,339.6064a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,748.6757,339.6064Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="528.93496" cy="301.52863" r="2.15765" fill="#ccc" />
      <path
        d="M765.89152,339.6064a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,765.89152,339.6064Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="546.15078" cy="301.52863" r="2.15765" fill="#ccc" />
      <circle cx="554.75088" cy="301.52863" r="2.15765" fill="#ccc" />
      <path
        d="M791.707,339.6064a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15741,2.15741,0,0,0,791.707,339.6064Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="571.9667" cy="301.52863" r="2.15765" fill="#ccc" />
      <circle cx="580.58193" cy="301.52863" r="2.15765" fill="#ccc" />
      <path
        d="M817.52336,339.6064a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,817.52336,339.6064Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="597.78262" cy="301.52863" r="2.15765" fill="#ccc" />
      <circle cx="606.39785" cy="301.52863" r="2.15765" fill="#ccc" />
      <path
        d="M843.33928,339.6064a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,843.33928,339.6064Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="623.61367" cy="301.52863" r="2.15765" fill="#ccc" />
      <circle cx="632.21377" cy="301.52863" r="2.15765" fill="#ccc" />
      <path
        d="M869.16985,339.6064a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,869.16985,339.6064Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M877.77043,339.6064a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,877.77043,339.6064Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="658.02969" cy="301.52863" r="2.15765" fill="#ccc" />
      <path
        d="M326.94524,348.20668a2.15763,2.15763,0,1,0,2.15722,2.15759A2.15752,2.15752,0,0,0,326.94524,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M335.56047,348.20668a2.15763,2.15763,0,1,0,2.15771,2.15759A2.15783,2.15783,0,0,0,335.56047,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M344.16057,348.20668a2.15763,2.15763,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,344.16057,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M352.76115,348.20668a2.15763,2.15763,0,1,0,2.15772,2.15759A2.15752,2.15752,0,0,0,352.76115,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M361.37639,348.20668a2.15763,2.15763,0,1,0,2.15771,2.15759A2.15783,2.15783,0,0,0,361.37639,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M369.97649,348.20668a2.15763,2.15763,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,369.97649,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M378.59221,348.20668a2.15763,2.15763,0,1,0,2.15771,2.15759A2.15752,2.15752,0,0,0,378.59221,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M387.19231,348.20668a2.15763,2.15763,0,1,0,2.15771,2.15759A2.15783,2.15783,0,0,0,387.19231,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M395.79289,348.20668a2.15763,2.15763,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,395.79289,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M610.96574,348.20668a2.15763,2.15763,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,610.96574,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M619.56584,348.20668a2.15763,2.15763,0,1,0,2.15772,2.15759A2.15752,2.15752,0,0,0,619.56584,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M628.18108,348.20668a2.15763,2.15763,0,1,0,2.15771,2.15759A2.15783,2.15783,0,0,0,628.18108,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M636.78166,348.20668a2.15763,2.15763,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,636.78166,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M645.3969,348.20668a2.15763,2.15763,0,1,0,2.15771,2.15759A2.15752,2.15752,0,0,0,645.3969,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M653.997,348.20668a2.15763,2.15763,0,1,0,2.15772,2.15759A2.15783,2.15783,0,0,0,653.997,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M662.59758,348.20668a2.15763,2.15763,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,662.59758,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M671.21281,348.20668a2.15763,2.15763,0,1,0,2.15772,2.15759A2.15752,2.15752,0,0,0,671.21281,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M679.81291,348.20668a2.15763,2.15763,0,1,0,2.15772,2.15759A2.15742,2.15742,0,0,0,679.81291,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M688.42863,348.20668a2.15763,2.15763,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,688.42863,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M697.02873,348.20668a2.15763,2.15763,0,1,0,2.15772,2.15759A2.15752,2.15752,0,0,0,697.02873,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M705.644,348.20668a2.15763,2.15763,0,1,0,2.15771,2.15759A2.15783,2.15783,0,0,0,705.644,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M714.24406,348.20668a2.15763,2.15763,0,1,0,2.15772,2.15759A2.15741,2.15741,0,0,0,714.24406,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M722.84465,348.20668a2.15763,2.15763,0,1,0,2.15771,2.15759A2.15753,2.15753,0,0,0,722.84465,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M731.45988,348.20668a2.15763,2.15763,0,1,0,2.15772,2.15759A2.15783,2.15783,0,0,0,731.45988,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M740.06047,348.20668a2.15763,2.15763,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,740.06047,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M748.6757,348.20668a2.15763,2.15763,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,748.6757,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M757.2758,348.20668a2.15763,2.15763,0,1,0,2.15772,2.15759A2.15783,2.15783,0,0,0,757.2758,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M765.89152,348.20668a2.15763,2.15763,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,765.89152,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M783.09172,348.20668a2.15763,2.15763,0,1,0,2.15771,2.15759A2.15783,2.15783,0,0,0,783.09172,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M791.707,348.20668a2.15763,2.15763,0,1,0,2.15772,2.15759A2.15741,2.15741,0,0,0,791.707,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M800.30754,348.20668a2.15763,2.15763,0,1,0,2.15772,2.15759A2.15752,2.15752,0,0,0,800.30754,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M808.92277,348.20668a2.15763,2.15763,0,1,0,2.15772,2.15759A2.15783,2.15783,0,0,0,808.92277,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M817.52336,348.20668a2.15763,2.15763,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,817.52336,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M826.12346,348.20668a2.15763,2.15763,0,1,0,2.15771,2.15759A2.15752,2.15752,0,0,0,826.12346,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M834.73869,348.20668a2.15763,2.15763,0,1,0,2.15772,2.15759A2.15783,2.15783,0,0,0,834.73869,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M843.33928,348.20668a2.15763,2.15763,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,843.33928,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M851.95451,348.20668a2.15763,2.15763,0,1,0,2.15772,2.15759A2.15752,2.15752,0,0,0,851.95451,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M860.55461,348.20668a2.15763,2.15763,0,1,0,2.15772,2.15759A2.15783,2.15783,0,0,0,860.55461,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M869.16985,348.20668a2.15763,2.15763,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,869.16985,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M877.77043,348.20668a2.15763,2.15763,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,877.77043,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M886.37053,348.20668a2.15763,2.15763,0,1,0,2.15771,2.15759A2.15783,2.15783,0,0,0,886.37053,348.20668Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M326.94524,356.807a2.1576,2.1576,0,1,0,2.15722,2.15759A2.15752,2.15752,0,0,0,326.94524,356.807Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="107.21963" cy="318.72919" r="2.15765" fill="#ccc" />
      <path
        d="M344.16057,356.807a2.1576,2.1576,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,344.16057,356.807Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="124.42031" cy="318.72919" r="2.15765" fill="#ccc" />
      <path
        d="M395.79289,356.807a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,395.79289,356.807Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M404.40813,356.807a2.1576,2.1576,0,1,0,2.15722,2.15759A2.15752,2.15752,0,0,0,404.40813,356.807Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M602.36516,356.807a2.1576,2.1576,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,602.36516,356.807Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M610.96574,356.807a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,610.96574,356.807Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="391.225" cy="318.72919" r="2.15765" fill="#ccc" />
      <circle cx="399.84023" cy="318.72919" r="2.15765" fill="#ccc" />
      <path
        d="M636.78166,356.807a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,636.78166,356.807Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="417.05605" cy="318.72919" r="2.15765" fill="#ccc" />
      <circle cx="425.65615" cy="318.72919" r="2.15765" fill="#ccc" />
      <path
        d="M662.59758,356.807a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,662.59758,356.807Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="442.87197" cy="318.72919" r="2.15765" fill="#ccc" />
      <path
        d="M679.81291,356.807a2.1576,2.1576,0,1,0,2.15772,2.15759A2.15742,2.15742,0,0,0,679.81291,356.807Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M688.42863,356.807a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,688.42863,356.807Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="468.68789" cy="318.72919" r="2.15765" fill="#ccc" />
      <circle cx="477.30312" cy="318.72919" r="2.15765" fill="#ccc" />
      <path
        d="M714.24406,356.807a2.1576,2.1576,0,1,0,2.15772,2.15759A2.15741,2.15741,0,0,0,714.24406,356.807Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="494.50381" cy="318.72919" r="2.15765" fill="#ccc" />
      <path
        d="M748.6757,356.807a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,748.6757,356.807Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="528.93496" cy="318.72919" r="2.15765" fill="#ccc" />
      <path
        d="M765.89152,356.807a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,765.89152,356.807Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="546.15078" cy="318.72919" r="2.15765" fill="#ccc" />
      <circle cx="571.9667" cy="318.72919" r="2.15765" fill="#ccc" />
      <circle cx="580.58193" cy="318.72919" r="2.15765" fill="#ccc" />
      <path
        d="M817.52336,356.807a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,817.52336,356.807Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="597.78262" cy="318.72919" r="2.15765" fill="#ccc" />
      <circle cx="606.39785" cy="318.72919" r="2.15765" fill="#ccc" />
      <path
        d="M843.33928,356.807a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,843.33928,356.807Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="623.61367" cy="318.72919" r="2.15765" fill="#ccc" />
      <circle cx="632.21377" cy="318.72919" r="2.15765" fill="#ccc" />
      <path
        d="M869.16985,356.807a2.1576,2.1576,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,869.16985,356.807Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M877.77043,356.807a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,877.77043,356.807Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="658.02969" cy="318.72919" r="2.15765" fill="#ccc" />
      <path
        d="M326.94524,365.42232a2.15759,2.15759,0,1,0,2.15722,2.15759A2.15748,2.15748,0,0,0,326.94524,365.42232Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="107.21963" cy="327.34454" r="2.15765" fill="#ccc" />
      <path
        d="M344.16057,365.42232a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15738,2.15738,0,0,0,344.16057,365.42232Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="124.42031" cy="327.34454" r="2.15765" fill="#ccc" />
      <path
        d="M404.40813,365.42232a2.15759,2.15759,0,1,0,2.15722,2.15759A2.15748,2.15748,0,0,0,404.40813,365.42232Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="184.66738" cy="327.34454" r="2.15765" fill="#ccc" />
      <circle cx="365.40908" cy="327.34454" r="2.15765" fill="#ccc" />
      <path
        d="M602.36516,365.42232a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15738,2.15738,0,0,0,602.36516,365.42232Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M610.96574,365.42232a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,610.96574,365.42232Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="391.225" cy="327.34454" r="2.15765" fill="#ccc" />
      <circle cx="399.84023" cy="327.34454" r="2.15765" fill="#ccc" />
      <path
        d="M636.78166,365.42232a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,636.78166,365.42232Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="417.05605" cy="327.34454" r="2.15765" fill="#ccc" />
      <circle cx="425.65615" cy="327.34454" r="2.15765" fill="#ccc" />
      <path
        d="M662.59758,365.42232a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,662.59758,365.42232Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="442.87197" cy="327.34454" r="2.15765" fill="#ccc" />
      <path
        d="M679.81291,365.42232a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15738,2.15738,0,0,0,679.81291,365.42232Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M688.42863,365.42232a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,688.42863,365.42232Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="468.68789" cy="327.34454" r="2.15765" fill="#ccc" />
      <circle cx="477.30312" cy="327.34454" r="2.15765" fill="#ccc" />
      <path
        d="M714.24406,365.42232a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15738,2.15738,0,0,0,714.24406,365.42232Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="494.50381" cy="327.34454" r="2.15765" fill="#ccc" />
      <circle cx="503.11904" cy="327.34454" r="2.15765" fill="#ccc" />
      <path
        d="M748.6757,365.42232a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,748.6757,365.42232Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="528.93496" cy="327.34454" r="2.15765" fill="#ccc" />
      <path
        d="M765.89152,365.42232a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,765.89152,365.42232Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="546.15078" cy="327.34454" r="2.15765" fill="#ccc" />
      <circle cx="580.58193" cy="327.34454" r="2.15765" fill="#ccc" />
      <path
        d="M817.52336,365.42232a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,817.52336,365.42232Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="597.78262" cy="327.34454" r="2.15765" fill="#ccc" />
      <circle cx="606.39785" cy="327.34454" r="2.15765" fill="#ccc" />
      <path
        d="M843.33928,365.42232a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,843.33928,365.42232Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="623.61367" cy="327.34454" r="2.15765" fill="#ccc" />
      <circle cx="632.21377" cy="327.34454" r="2.15765" fill="#ccc" />
      <path
        d="M869.16985,365.42232a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15738,2.15738,0,0,0,869.16985,365.42232Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M877.77043,365.42232a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,877.77043,365.42232Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="107.21963" cy="335.94482" r="2.15765" fill="#ccc" />
      <path
        d="M344.16057,374.0226a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,344.16057,374.0226Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="124.42031" cy="335.94482" r="2.15765" fill="#ccc" />
      <path
        d="M395.79289,374.0226a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,395.79289,374.0226Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M404.40813,374.0226a2.15759,2.15759,0,1,0,2.15722,2.15759A2.15752,2.15752,0,0,0,404.40813,374.0226Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M421.62346,374.0226a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,421.62346,374.0226Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="201.8832" cy="335.94482" r="2.15765" fill="#ccc" />
      <circle cx="365.40908" cy="335.94482" r="2.15765" fill="#ccc" />
      <path
        d="M602.36516,374.0226a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,602.36516,374.0226Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M610.96574,374.0226a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,610.96574,374.0226Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="391.225" cy="335.94482" r="2.15765" fill="#ccc" />
      <circle cx="399.84023" cy="335.94482" r="2.15765" fill="#ccc" />
      <path
        d="M636.78166,374.0226a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,636.78166,374.0226Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="417.05605" cy="335.94482" r="2.15765" fill="#ccc" />
      <circle cx="425.65615" cy="335.94482" r="2.15765" fill="#ccc" />
      <path
        d="M662.59758,374.0226a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,662.59758,374.0226Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="442.87197" cy="335.94482" r="2.15765" fill="#ccc" />
      <path
        d="M679.81291,374.0226a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15742,2.15742,0,0,0,679.81291,374.0226Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M688.42863,374.0226a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,688.42863,374.0226Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="468.68789" cy="335.94482" r="2.15765" fill="#ccc" />
      <circle cx="477.30312" cy="335.94482" r="2.15765" fill="#ccc" />
      <path
        d="M714.24406,374.0226a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15741,2.15741,0,0,0,714.24406,374.0226Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="494.50381" cy="335.94482" r="2.15765" fill="#ccc" />
      <circle cx="503.11904" cy="335.94482" r="2.15765" fill="#ccc" />
      <circle cx="528.93496" cy="335.94482" r="2.15765" fill="#ccc" />
      <path
        d="M765.89152,374.0226a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,765.89152,374.0226Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="546.15078" cy="335.94482" r="2.15765" fill="#ccc" />
      <circle cx="554.75088" cy="335.94482" r="2.15765" fill="#ccc" />
      <path
        d="M791.707,374.0226a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15741,2.15741,0,0,0,791.707,374.0226Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="571.9667" cy="335.94482" r="2.15765" fill="#ccc" />
      <circle cx="606.39785" cy="335.94482" r="2.15765" fill="#ccc" />
      <path
        d="M843.33928,374.0226a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,843.33928,374.0226Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="623.61367" cy="335.94482" r="2.15765" fill="#ccc" />
      <circle cx="632.21377" cy="335.94482" r="2.15765" fill="#ccc" />
      <path
        d="M869.16985,374.0226a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,869.16985,374.0226Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M877.77043,374.0226a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,877.77043,374.0226Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="107.21963" cy="344.56018" r="2.15765" fill="#ccc" />
      <path
        d="M344.16057,382.638a2.1576,2.1576,0,1,0,2.15771,2.1576A2.15742,2.15742,0,0,0,344.16057,382.638Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="124.42031" cy="344.56018" r="2.15765" fill="#ccc" />
      <circle cx="150.25137" cy="344.56018" r="2.15765" fill="#ccc" />
      <circle cx="184.66738" cy="344.56018" r="2.15765" fill="#ccc" />
      <path
        d="M421.62346,382.638a2.1576,2.1576,0,1,0,2.15771,2.1576A2.15742,2.15742,0,0,0,421.62346,382.638Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="365.40908" cy="344.56018" r="2.15765" fill="#ccc" />
      <path
        d="M602.36516,382.638a2.1576,2.1576,0,1,0,2.15771,2.1576A2.15742,2.15742,0,0,0,602.36516,382.638Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M610.96574,382.638a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,610.96574,382.638Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="391.225" cy="344.56018" r="2.15765" fill="#ccc" />
      <circle cx="399.84023" cy="344.56018" r="2.15765" fill="#ccc" />
      <path
        d="M636.78166,382.638a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,636.78166,382.638Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="417.05605" cy="344.56018" r="2.15765" fill="#ccc" />
      <circle cx="425.65615" cy="344.56018" r="2.15765" fill="#ccc" />
      <path
        d="M662.59758,382.638a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,662.59758,382.638Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="442.87197" cy="344.56018" r="2.15765" fill="#ccc" />
      <path
        d="M679.81291,382.638a2.1576,2.1576,0,1,0,2.15772,2.1576A2.15743,2.15743,0,0,0,679.81291,382.638Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M688.42863,382.638a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,688.42863,382.638Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="468.68789" cy="344.56018" r="2.15765" fill="#ccc" />
      <circle cx="477.30312" cy="344.56018" r="2.15765" fill="#ccc" />
      <path
        d="M714.24406,382.638a2.1576,2.1576,0,1,0,2.15772,2.1576A2.15742,2.15742,0,0,0,714.24406,382.638Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="494.50381" cy="344.56018" r="2.15765" fill="#ccc" />
      <circle cx="503.11904" cy="344.56018" r="2.15765" fill="#ccc" />
      <circle cx="528.93496" cy="344.56018" r="2.15765" fill="#ccc" />
      <path
        d="M765.89152,382.638a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,765.89152,382.638Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="546.15078" cy="344.56018" r="2.15765" fill="#ccc" />
      <circle cx="554.75088" cy="344.56018" r="2.15765" fill="#ccc" />
      <path
        d="M791.707,382.638a2.1576,2.1576,0,1,0,2.15772,2.1576A2.15742,2.15742,0,0,0,791.707,382.638Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="571.9667" cy="344.56018" r="2.15765" fill="#ccc" />
      <path
        d="M843.33928,382.638a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,843.33928,382.638Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="623.61367" cy="344.56018" r="2.15765" fill="#ccc" />
      <circle cx="632.21377" cy="344.56018" r="2.15765" fill="#ccc" />
      <path
        d="M869.16985,382.638a2.1576,2.1576,0,1,0,2.15771,2.1576A2.15742,2.15742,0,0,0,869.16985,382.638Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M877.77043,382.638a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,877.77043,382.638Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M344.16057,391.23824a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,344.16057,391.23824Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="124.42031" cy="353.16046" r="2.15765" fill="#ccc" />
      <circle cx="133.03555" cy="353.16046" r="2.15765" fill="#ccc" />
      <path
        d="M369.97649,391.23824a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,369.97649,391.23824Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="150.25137" cy="353.16046" r="2.15765" fill="#ccc" />
      <circle cx="184.66738" cy="353.16046" r="2.15765" fill="#ccc" />
      <circle cx="201.8832" cy="353.16046" r="2.15765" fill="#ccc" />
      <path
        d="M447.43938,391.23824a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,447.43938,391.23824Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="365.40908" cy="353.16046" r="2.15765" fill="#ccc" />
      <path
        d="M602.36516,391.23824a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,602.36516,391.23824Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M610.96574,391.23824a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,610.96574,391.23824Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="391.225" cy="353.16046" r="2.15765" fill="#ccc" />
      <circle cx="399.84023" cy="353.16046" r="2.15765" fill="#ccc" />
      <path
        d="M636.78166,391.23824a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,636.78166,391.23824Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="417.05605" cy="353.16046" r="2.15765" fill="#ccc" />
      <circle cx="425.65615" cy="353.16046" r="2.15765" fill="#ccc" />
      <path
        d="M662.59758,391.23824a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,662.59758,391.23824Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="442.87197" cy="353.16046" r="2.15765" fill="#ccc" />
      <path
        d="M679.81291,391.23824a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15742,2.15742,0,0,0,679.81291,391.23824Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M688.42863,391.23824a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,688.42863,391.23824Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="468.68789" cy="353.16046" r="2.15765" fill="#ccc" />
      <circle cx="477.30312" cy="353.16046" r="2.15765" fill="#ccc" />
      <path
        d="M714.24406,391.23824a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15741,2.15741,0,0,0,714.24406,391.23824Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="494.50381" cy="353.16046" r="2.15765" fill="#ccc" />
      <circle cx="503.11904" cy="353.16046" r="2.15765" fill="#ccc" />
      <path
        d="M740.06047,391.23824a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,740.06047,391.23824Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="528.93496" cy="353.16046" r="2.15765" fill="#ccc" />
      <path
        d="M765.89152,391.23824a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,765.89152,391.23824Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="546.15078" cy="353.16046" r="2.15765" fill="#ccc" />
      <circle cx="554.75088" cy="353.16046" r="2.15765" fill="#ccc" />
      <path
        d="M791.707,391.23824a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15741,2.15741,0,0,0,791.707,391.23824Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M843.33928,391.23824a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,843.33928,391.23824Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="623.61367" cy="353.16046" r="2.15765" fill="#ccc" />
      <circle cx="632.21377" cy="353.16046" r="2.15765" fill="#ccc" />
      <path
        d="M869.16985,391.23824a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,869.16985,391.23824Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M369.97649,399.83852a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15738,2.15738,0,0,0,369.97649,399.83852Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="150.25137" cy="361.76074" r="2.15765" fill="#ccc" />
      <circle cx="158.85146" cy="361.76074" r="2.15765" fill="#ccc" />
      <path
        d="M395.79289,399.83852a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,395.79289,399.83852Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M456.04,399.83852a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,456.04,399.83852Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M585.14983,399.83852a2.15759,2.15759,0,1,0,2.15722,2.15759A2.15748,2.15748,0,0,0,585.14983,399.83852Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="365.40908" cy="361.76074" r="2.15765" fill="#ccc" />
      <path
        d="M602.36516,399.83852a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15738,2.15738,0,0,0,602.36516,399.83852Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M610.96574,399.83852a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,610.96574,399.83852Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="391.225" cy="361.76074" r="2.15765" fill="#ccc" />
      <circle cx="399.84023" cy="361.76074" r="2.15765" fill="#ccc" />
      <path
        d="M636.78166,399.83852a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,636.78166,399.83852Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="417.05605" cy="361.76074" r="2.15765" fill="#ccc" />
      <circle cx="425.65615" cy="361.76074" r="2.15765" fill="#ccc" />
      <path
        d="M662.59758,399.83852a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,662.59758,399.83852Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="442.87197" cy="361.76074" r="2.15765" fill="#ccc" />
      <path
        d="M679.81291,399.83852a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15738,2.15738,0,0,0,679.81291,399.83852Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M688.42863,399.83852a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,688.42863,399.83852Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="468.68789" cy="361.76074" r="2.15765" fill="#ccc" />
      <circle cx="477.30312" cy="361.76074" r="2.15765" fill="#ccc" />
      <path
        d="M714.24406,399.83852a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15738,2.15738,0,0,0,714.24406,399.83852Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="494.50381" cy="361.76074" r="2.15765" fill="#ccc" />
      <circle cx="503.11904" cy="361.76074" r="2.15765" fill="#ccc" />
      <path
        d="M740.06047,399.83852a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,740.06047,399.83852Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M765.89152,399.83852a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,765.89152,399.83852Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="546.15078" cy="361.76074" r="2.15765" fill="#ccc" />
      <circle cx="623.61367" cy="361.76074" r="2.15765" fill="#ccc" />
      <circle cx="632.21377" cy="361.76074" r="2.15765" fill="#ccc" />
      <path
        d="M387.19231,408.45387a2.15763,2.15763,0,1,0,2.15771,2.15766A2.15786,2.15786,0,0,0,387.19231,408.45387Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M395.79289,408.45387a2.15763,2.15763,0,1,0,2.15723,2.15766A2.15754,2.15754,0,0,0,395.79289,408.45387Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M438.83928,408.45387a2.15763,2.15763,0,1,0,2.15771,2.15766A2.15786,2.15786,0,0,0,438.83928,408.45387Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M456.04,408.45387a2.15763,2.15763,0,1,0,2.15723,2.15766A2.15754,2.15754,0,0,0,456.04,408.45387Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M593.74992,408.45387a2.15763,2.15763,0,1,0,2.15772,2.15766A2.15785,2.15785,0,0,0,593.74992,408.45387Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M602.36516,408.45387a2.15763,2.15763,0,1,0,2.15771,2.15766A2.15744,2.15744,0,0,0,602.36516,408.45387Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M610.96574,408.45387a2.15763,2.15763,0,1,0,2.15723,2.15766A2.15754,2.15754,0,0,0,610.96574,408.45387Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M619.56584,408.45387a2.15763,2.15763,0,1,0,2.15772,2.15766A2.15754,2.15754,0,0,0,619.56584,408.45387Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M628.18108,408.45387a2.15763,2.15763,0,1,0,2.15771,2.15766A2.15786,2.15786,0,0,0,628.18108,408.45387Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M636.78166,408.45387a2.15763,2.15763,0,1,0,2.15723,2.15766A2.15754,2.15754,0,0,0,636.78166,408.45387Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M645.3969,408.45387a2.15763,2.15763,0,1,0,2.15771,2.15766A2.15754,2.15754,0,0,0,645.3969,408.45387Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M653.997,408.45387a2.15763,2.15763,0,1,0,2.15772,2.15766A2.15785,2.15785,0,0,0,653.997,408.45387Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M662.59758,408.45387a2.15763,2.15763,0,1,0,2.15723,2.15766A2.15754,2.15754,0,0,0,662.59758,408.45387Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M671.21281,408.45387a2.15763,2.15763,0,1,0,2.15772,2.15766A2.15754,2.15754,0,0,0,671.21281,408.45387Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M679.81291,408.45387a2.15763,2.15763,0,1,0,2.15772,2.15766A2.15744,2.15744,0,0,0,679.81291,408.45387Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M688.42863,408.45387a2.15763,2.15763,0,1,0,2.15723,2.15766A2.15754,2.15754,0,0,0,688.42863,408.45387Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M697.02873,408.45387a2.15763,2.15763,0,1,0,2.15772,2.15766A2.15754,2.15754,0,0,0,697.02873,408.45387Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M705.644,408.45387a2.15763,2.15763,0,1,0,2.15771,2.15766A2.15786,2.15786,0,0,0,705.644,408.45387Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M714.24406,408.45387a2.15763,2.15763,0,1,0,2.15772,2.15766A2.15744,2.15744,0,0,0,714.24406,408.45387Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M722.84465,408.45387a2.15763,2.15763,0,1,0,2.15771,2.15766A2.15755,2.15755,0,0,0,722.84465,408.45387Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M731.45988,408.45387a2.15763,2.15763,0,1,0,2.15772,2.15766A2.15785,2.15785,0,0,0,731.45988,408.45387Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M740.06047,408.45387a2.15763,2.15763,0,1,0,2.15723,2.15766A2.15754,2.15754,0,0,0,740.06047,408.45387Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M748.6757,408.45387a2.15763,2.15763,0,1,0,2.15723,2.15766A2.15754,2.15754,0,0,0,748.6757,408.45387Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M851.95451,408.45387a2.15763,2.15763,0,1,0,2.15772,2.15766A2.15754,2.15754,0,0,0,851.95451,408.45387Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M860.55461,408.45387a2.15763,2.15763,0,1,0,2.15772,2.15766A2.15785,2.15785,0,0,0,860.55461,408.45387Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="158.85146" cy="378.97638" r="2.15765" fill="#ccc" />
      <path
        d="M395.79289,417.05415a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,395.79289,417.05415Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M404.40813,417.05415a2.1576,2.1576,0,1,0,2.15722,2.1576A2.15753,2.15753,0,0,0,404.40813,417.05415Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="184.66738" cy="378.97638" r="2.15765" fill="#ccc" />
      <path
        d="M421.62346,417.05415a2.1576,2.1576,0,1,0,2.15771,2.1576A2.15742,2.15742,0,0,0,421.62346,417.05415Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="201.8832" cy="378.97638" r="2.15765" fill="#ccc" />
      <circle cx="210.49844" cy="378.97638" r="2.15765" fill="#ccc" />
      <path
        d="M447.43938,417.05415a2.1576,2.1576,0,1,0,2.15771,2.1576A2.15742,2.15742,0,0,0,447.43938,417.05415Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M456.04,417.05415a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,456.04,417.05415Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="365.40908" cy="378.97638" r="2.15765" fill="#ccc" />
      <path
        d="M602.36516,417.05415a2.1576,2.1576,0,1,0,2.15771,2.1576A2.15742,2.15742,0,0,0,602.36516,417.05415Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M610.96574,417.05415a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,610.96574,417.05415Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="391.225" cy="378.97638" r="2.15765" fill="#ccc" />
      <circle cx="399.84023" cy="378.97638" r="2.15765" fill="#ccc" />
      <path
        d="M636.78166,417.05415a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,636.78166,417.05415Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="417.05605" cy="378.97638" r="2.15765" fill="#ccc" />
      <circle cx="425.65615" cy="378.97638" r="2.15765" fill="#ccc" />
      <path
        d="M662.59758,417.05415a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,662.59758,417.05415Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="442.87197" cy="378.97638" r="2.15765" fill="#ccc" />
      <path
        d="M679.81291,417.05415a2.1576,2.1576,0,1,0,2.15772,2.1576A2.15743,2.15743,0,0,0,679.81291,417.05415Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M688.42863,417.05415a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,688.42863,417.05415Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="468.68789" cy="378.97638" r="2.15765" fill="#ccc" />
      <circle cx="477.30312" cy="378.97638" r="2.15765" fill="#ccc" />
      <path
        d="M714.24406,417.05415a2.1576,2.1576,0,1,0,2.15772,2.1576A2.15742,2.15742,0,0,0,714.24406,417.05415Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="494.50381" cy="378.97638" r="2.15765" fill="#ccc" />
      <circle cx="503.11904" cy="378.97638" r="2.15765" fill="#ccc" />
      <path
        d="M740.06047,417.05415a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,740.06047,417.05415Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M748.6757,417.05415a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,748.6757,417.05415Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="528.93496" cy="378.97638" r="2.15765" fill="#ccc" />
      <path
        d="M765.89152,417.05415a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,765.89152,417.05415Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="546.15078" cy="378.97638" r="2.15765" fill="#ccc" />
      <circle cx="623.61367" cy="378.97638" r="2.15765" fill="#ccc" />
      <circle cx="632.21377" cy="378.97638" r="2.15765" fill="#ccc" />
      <path
        d="M404.40813,425.66957a2.1576,2.1576,0,1,0,2.15722,2.15759A2.15748,2.15748,0,0,0,404.40813,425.66957Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="184.66738" cy="387.5918" r="2.15765" fill="#ccc" />
      <path
        d="M421.62346,425.66957a2.1576,2.1576,0,1,0,2.15771,2.15759A2.15738,2.15738,0,0,0,421.62346,425.66957Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="201.8832" cy="387.5918" r="2.15765" fill="#ccc" />
      <circle cx="210.49844" cy="387.5918" r="2.15765" fill="#ccc" />
      <path
        d="M447.43938,425.66957a2.1576,2.1576,0,1,0,2.15771,2.15759A2.15738,2.15738,0,0,0,447.43938,425.66957Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M456.04,425.66957a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,456.04,425.66957Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="236.31435" cy="387.5918" r="2.15765" fill="#ccc" />
      <path
        d="M602.36516,425.66957a2.1576,2.1576,0,1,0,2.15771,2.15759A2.15738,2.15738,0,0,0,602.36516,425.66957Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M610.96574,425.66957a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,610.96574,425.66957Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="391.225" cy="387.5918" r="2.15765" fill="#ccc" />
      <circle cx="399.84023" cy="387.5918" r="2.15765" fill="#ccc" />
      <path
        d="M636.78166,425.66957a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,636.78166,425.66957Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="417.05605" cy="387.5918" r="2.15765" fill="#ccc" />
      <circle cx="425.65615" cy="387.5918" r="2.15765" fill="#ccc" />
      <path
        d="M662.59758,425.66957a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,662.59758,425.66957Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="442.87197" cy="387.5918" r="2.15765" fill="#ccc" />
      <path
        d="M679.81291,425.66957a2.1576,2.1576,0,1,0,2.15772,2.15759A2.15738,2.15738,0,0,0,679.81291,425.66957Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M688.42863,425.66957a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,688.42863,425.66957Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="468.68789" cy="387.5918" r="2.15765" fill="#ccc" />
      <circle cx="477.30312" cy="387.5918" r="2.15765" fill="#ccc" />
      <path
        d="M714.24406,425.66957a2.1576,2.1576,0,1,0,2.15772,2.15759A2.15738,2.15738,0,0,0,714.24406,425.66957Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="494.50381" cy="387.5918" r="2.15765" fill="#ccc" />
      <circle cx="503.11904" cy="387.5918" r="2.15765" fill="#ccc" />
      <path
        d="M740.06047,425.66957a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,740.06047,425.66957Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M748.6757,425.66957a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,748.6757,425.66957Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="528.93496" cy="387.5918" r="2.15765" fill="#ccc" />
      <path
        d="M765.89152,425.66957a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,765.89152,425.66957Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="546.15078" cy="387.5918" r="2.15765" fill="#ccc" />
      <circle cx="632.21377" cy="387.5918" r="2.15765" fill="#ccc" />
      <circle cx="184.66738" cy="396.19202" r="2.15765" fill="#ccc" />
      <path
        d="M421.62346,434.26979a2.1576,2.1576,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,421.62346,434.26979Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="201.8832" cy="396.19202" r="2.15765" fill="#ccc" />
      <circle cx="210.49844" cy="396.19202" r="2.15765" fill="#ccc" />
      <path
        d="M447.43938,434.26979a2.1576,2.1576,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,447.43938,434.26979Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M456.04,434.26979a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,456.04,434.26979Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="236.31435" cy="396.19202" r="2.15765" fill="#ccc" />
      <path
        d="M473.25529,434.26979a2.1576,2.1576,0,1,0,2.15772,2.15759A2.15741,2.15741,0,0,0,473.25529,434.26979Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M610.96574,434.26979a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,610.96574,434.26979Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="391.225" cy="396.19202" r="2.15765" fill="#ccc" />
      <circle cx="399.84023" cy="396.19202" r="2.15765" fill="#ccc" />
      <circle cx="425.65615" cy="396.19202" r="2.15765" fill="#ccc" />
      <path
        d="M662.59758,434.26979a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,662.59758,434.26979Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="442.87197" cy="396.19202" r="2.15765" fill="#ccc" />
      <path
        d="M679.81291,434.26979a2.1576,2.1576,0,1,0,2.15772,2.15759A2.15742,2.15742,0,0,0,679.81291,434.26979Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M688.42863,434.26979a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,688.42863,434.26979Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="468.68789" cy="396.19202" r="2.15765" fill="#ccc" />
      <circle cx="477.30312" cy="396.19202" r="2.15765" fill="#ccc" />
      <path
        d="M714.24406,434.26979a2.1576,2.1576,0,1,0,2.15772,2.15759A2.15741,2.15741,0,0,0,714.24406,434.26979Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="494.50381" cy="396.19202" r="2.15765" fill="#ccc" />
      <circle cx="503.11904" cy="396.19202" r="2.15765" fill="#ccc" />
      <path
        d="M740.06047,434.26979a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,740.06047,434.26979Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M748.6757,434.26979a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,748.6757,434.26979Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="528.93496" cy="396.19202" r="2.15765" fill="#ccc" />
      <path
        d="M765.89152,434.26979a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,765.89152,434.26979Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="184.66738" cy="404.80743" r="2.15765" fill="#ccc" />
      <path
        d="M421.62346,442.88521a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,421.62346,442.88521Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="201.8832" cy="404.80743" r="2.15765" fill="#ccc" />
      <circle cx="210.49844" cy="404.80743" r="2.15765" fill="#ccc" />
      <path
        d="M447.43938,442.88521a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,447.43938,442.88521Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M456.04,442.88521a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,456.04,442.88521Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="236.31435" cy="404.80743" r="2.15765" fill="#ccc" />
      <path
        d="M473.25529,442.88521a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15741,2.15741,0,0,0,473.25529,442.88521Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M481.871,442.88521a2.15759,2.15759,0,1,0,2.15722,2.15759A2.15752,2.15752,0,0,0,481.871,442.88521Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M662.59758,442.88521a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,662.59758,442.88521Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="442.87197" cy="404.80743" r="2.15765" fill="#ccc" />
      <path
        d="M679.81291,442.88521a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15742,2.15742,0,0,0,679.81291,442.88521Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M688.42863,442.88521a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,688.42863,442.88521Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="468.68789" cy="404.80743" r="2.15765" fill="#ccc" />
      <circle cx="477.30312" cy="404.80743" r="2.15765" fill="#ccc" />
      <path
        d="M714.24406,442.88521a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15741,2.15741,0,0,0,714.24406,442.88521Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="494.50381" cy="404.80743" r="2.15765" fill="#ccc" />
      <circle cx="503.11904" cy="404.80743" r="2.15765" fill="#ccc" />
      <path
        d="M740.06047,442.88521a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,740.06047,442.88521Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M748.6757,442.88521a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,748.6757,442.88521Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="528.93496" cy="404.80743" r="2.15765" fill="#ccc" />
      <path
        d="M765.89152,442.88521a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,765.89152,442.88521Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M404.40813,451.48543a2.15762,2.15762,0,1,0,2.15722,2.15759A2.15752,2.15752,0,0,0,404.40813,451.48543Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M413.00822,451.48543a2.15762,2.15762,0,1,0,2.15772,2.15759A2.15783,2.15783,0,0,0,413.00822,451.48543Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M421.62346,451.48543a2.15762,2.15762,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,421.62346,451.48543Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M430.224,451.48543a2.15762,2.15762,0,1,0,2.15772,2.15759A2.15752,2.15752,0,0,0,430.224,451.48543Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M438.83928,451.48543A2.15762,2.15762,0,1,0,440.997,453.643,2.15783,2.15783,0,0,0,438.83928,451.48543Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M447.43938,451.48543a2.15762,2.15762,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,447.43938,451.48543Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M456.04,451.48543a2.15762,2.15762,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,456.04,451.48543Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M464.6552,451.48543a2.15762,2.15762,0,1,0,2.15771,2.15759A2.15783,2.15783,0,0,0,464.6552,451.48543Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M473.25529,451.48543A2.15762,2.15762,0,1,0,475.413,453.643,2.15741,2.15741,0,0,0,473.25529,451.48543Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M481.871,451.48543a2.15762,2.15762,0,1,0,2.15722,2.15759A2.15752,2.15752,0,0,0,481.871,451.48543Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M490.47111,451.48543a2.15762,2.15762,0,1,0,2.15772,2.15759A2.15783,2.15783,0,0,0,490.47111,451.48543Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M662.59758,451.48543a2.15762,2.15762,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,662.59758,451.48543Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M671.21281,451.48543a2.15762,2.15762,0,1,0,2.15772,2.15759A2.15752,2.15752,0,0,0,671.21281,451.48543Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M679.81291,451.48543a2.15762,2.15762,0,1,0,2.15772,2.15759A2.15742,2.15742,0,0,0,679.81291,451.48543Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M688.42863,451.48543a2.15762,2.15762,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,688.42863,451.48543Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M697.02873,451.48543a2.15762,2.15762,0,1,0,2.15772,2.15759A2.15752,2.15752,0,0,0,697.02873,451.48543Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M705.644,451.48543a2.15762,2.15762,0,1,0,2.15771,2.15759A2.15783,2.15783,0,0,0,705.644,451.48543Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M714.24406,451.48543a2.15762,2.15762,0,1,0,2.15772,2.15759A2.15741,2.15741,0,0,0,714.24406,451.48543Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M722.84465,451.48543a2.15762,2.15762,0,1,0,2.15771,2.15759A2.15753,2.15753,0,0,0,722.84465,451.48543Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M731.45988,451.48543a2.15762,2.15762,0,1,0,2.15772,2.15759A2.15783,2.15783,0,0,0,731.45988,451.48543Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M740.06047,451.48543a2.15762,2.15762,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,740.06047,451.48543Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M748.6757,451.48543a2.15762,2.15762,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,748.6757,451.48543Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M757.2758,451.48543a2.15762,2.15762,0,1,0,2.15772,2.15759A2.15783,2.15783,0,0,0,757.2758,451.48543Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M404.40813,460.08577a2.1576,2.1576,0,1,0,2.15722,2.15759A2.15748,2.15748,0,0,0,404.40813,460.08577Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="184.66738" cy="422.008" r="2.15765" fill="#ccc" />
      <path
        d="M421.62346,460.08577a2.1576,2.1576,0,1,0,2.15771,2.15759A2.15738,2.15738,0,0,0,421.62346,460.08577Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="201.8832" cy="422.008" r="2.15765" fill="#ccc" />
      <circle cx="210.49844" cy="422.008" r="2.15765" fill="#ccc" />
      <path
        d="M447.43938,460.08577a2.1576,2.1576,0,1,0,2.15771,2.15759A2.15738,2.15738,0,0,0,447.43938,460.08577Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M456.04,460.08577a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,456.04,460.08577Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="236.31435" cy="422.008" r="2.15765" fill="#ccc" />
      <path
        d="M473.25529,460.08577a2.1576,2.1576,0,1,0,2.15772,2.15759A2.15738,2.15738,0,0,0,473.25529,460.08577Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M481.871,460.08577a2.1576,2.1576,0,1,0,2.15722,2.15759A2.15748,2.15748,0,0,0,481.871,460.08577Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="262.13027" cy="422.008" r="2.15765" fill="#ccc" />
      <path
        d="M499.07121,460.08577a2.1576,2.1576,0,1,0,2.15772,2.15759A2.15738,2.15738,0,0,0,499.07121,460.08577Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="279.34609" cy="422.008" r="2.15765" fill="#ccc" />
      <circle cx="287.94619" cy="422.008" r="2.15765" fill="#ccc" />
      <path
        d="M524.90227,460.08577a2.1576,2.1576,0,1,0,2.15771,2.15759A2.15738,2.15738,0,0,0,524.90227,460.08577Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M662.59758,460.08577a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,662.59758,460.08577Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="442.87197" cy="422.008" r="2.15765" fill="#ccc" />
      <path
        d="M679.81291,460.08577a2.1576,2.1576,0,1,0,2.15772,2.15759A2.15738,2.15738,0,0,0,679.81291,460.08577Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M688.42863,460.08577a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,688.42863,460.08577Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="468.68789" cy="422.008" r="2.15765" fill="#ccc" />
      <circle cx="477.30312" cy="422.008" r="2.15765" fill="#ccc" />
      <path
        d="M714.24406,460.08577a2.1576,2.1576,0,1,0,2.15772,2.15759A2.15738,2.15738,0,0,0,714.24406,460.08577Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="494.50381" cy="422.008" r="2.15765" fill="#ccc" />
      <circle cx="503.11904" cy="422.008" r="2.15765" fill="#ccc" />
      <path
        d="M740.06047,460.08577a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,740.06047,460.08577Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M748.6757,460.08577a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,748.6757,460.08577Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M404.40813,468.70113a2.15759,2.15759,0,1,0,2.15722,2.15759A2.15748,2.15748,0,0,0,404.40813,468.70113Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="184.66738" cy="430.62335" r="2.15765" fill="#ccc" />
      <path
        d="M421.62346,468.70113a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15738,2.15738,0,0,0,421.62346,468.70113Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="201.8832" cy="430.62335" r="2.15765" fill="#ccc" />
      <circle cx="210.49844" cy="430.62335" r="2.15765" fill="#ccc" />
      <path
        d="M447.43938,468.70113a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15738,2.15738,0,0,0,447.43938,468.70113Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="287.94619" cy="430.62335" r="2.15765" fill="#ccc" />
      <path
        d="M524.90227,468.70113a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15738,2.15738,0,0,0,524.90227,468.70113Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M533.50285,468.70113a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,533.50285,468.70113Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="442.87197" cy="430.62335" r="2.15765" fill="#ccc" />
      <path
        d="M679.81291,468.70113a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15738,2.15738,0,0,0,679.81291,468.70113Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M688.42863,468.70113a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,688.42863,468.70113Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="468.68789" cy="430.62335" r="2.15765" fill="#ccc" />
      <circle cx="477.30312" cy="430.62335" r="2.15765" fill="#ccc" />
      <path
        d="M714.24406,468.70113a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15738,2.15738,0,0,0,714.24406,468.70113Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="494.50381" cy="430.62335" r="2.15765" fill="#ccc" />
      <path
        d="M404.40813,477.30141a2.15759,2.15759,0,1,0,2.15722,2.15759A2.15752,2.15752,0,0,0,404.40813,477.30141Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="184.66738" cy="439.22363" r="2.15765" fill="#ccc" />
      <path
        d="M421.62346,477.30141a2.15759,2.15759,0,1,0,2.15771,2.15759A2.15742,2.15742,0,0,0,421.62346,477.30141Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="201.8832" cy="439.22363" r="2.15765" fill="#ccc" />
      <path
        d="M524.90227,477.30141A2.15759,2.15759,0,1,0,527.06,479.459,2.15742,2.15742,0,0,0,524.90227,477.30141Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M533.50285,477.30141a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,533.50285,477.30141Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M679.81291,477.30141a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15742,2.15742,0,0,0,679.81291,477.30141Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M688.42863,477.30141a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,688.42863,477.30141Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="468.68789" cy="439.22363" r="2.15765" fill="#ccc" />
      <circle cx="477.30312" cy="439.22363" r="2.15765" fill="#ccc" />
      <circle cx="184.66738" cy="447.83899" r="2.15765" fill="#ccc" />
      <path
        d="M421.62346,485.91676a2.1576,2.1576,0,1,0,2.15771,2.1576A2.15742,2.15742,0,0,0,421.62346,485.91676Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M679.81291,485.91676a2.1576,2.1576,0,1,0,2.15772,2.1576A2.15743,2.15743,0,0,0,679.81291,485.91676Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M688.42863,485.91676a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,688.42863,485.91676Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="468.68789" cy="447.83899" r="2.15765" fill="#ccc" />
      <path
        d="M413.00822,494.517a2.15763,2.15763,0,1,0,2.15772,2.1576A2.15784,2.15784,0,0,0,413.00822,494.517Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M679.81291,494.517a2.15763,2.15763,0,1,0,2.15772,2.1576A2.15743,2.15743,0,0,0,679.81291,494.517Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M688.42863,494.517a2.15763,2.15763,0,1,0,2.15723,2.1576A2.15753,2.15753,0,0,0,688.42863,494.517Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M697.02873,494.517a2.15763,2.15763,0,1,0,2.15772,2.1576A2.15753,2.15753,0,0,0,697.02873,494.517Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="442.87197" cy="465.03955" r="2.15765" fill="#ccc" />
      <path
        d="M679.81291,503.11732a2.1576,2.1576,0,1,0,2.15772,2.1576A2.15739,2.15739,0,0,0,679.81291,503.11732Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M688.42863,503.11732a2.1576,2.1576,0,1,0,2.15723,2.1576A2.15749,2.15749,0,0,0,688.42863,503.11732Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="468.68789" cy="465.03955" r="2.15765" fill="#ccc" />
      <circle cx="442.87197" cy="473.65491" r="2.15765" fill="#ccc" />
      <path
        d="M679.81291,511.73268a2.1576,2.1576,0,1,0,2.15772,2.15759A2.15738,2.15738,0,0,0,679.81291,511.73268Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M688.42863,511.73268a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,688.42863,511.73268Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <circle cx="468.68789" cy="473.65491" r="2.15765" fill="#ccc" />
      <path
        d="M679.81291,520.333a2.1576,2.1576,0,1,0,2.15772,2.15759A2.15743,2.15743,0,0,0,679.81291,520.333Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M688.42863,520.333a2.1576,2.1576,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,688.42863,520.333Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M679.81291,528.94832a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15742,2.15742,0,0,0,679.81291,528.94832Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M688.42863,528.94832a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,688.42863,528.94832Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M679.81291,537.5486a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15742,2.15742,0,0,0,679.81291,537.5486Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M679.81291,546.164a2.15759,2.15759,0,1,0,2.15772,2.15759A2.15742,2.15742,0,0,0,679.81291,546.164Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M688.42863,554.76424a2.15762,2.15762,0,1,0,2.15723,2.15759A2.15752,2.15752,0,0,0,688.42863,554.76424Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M688.42863,563.36452a2.15759,2.15759,0,1,0,2.15723,2.15759A2.15748,2.15748,0,0,0,688.42863,563.36452Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M688.42863,571.97987a2.15763,2.15763,0,1,0,2.15723,2.15766A2.15754,2.15754,0,0,0,688.42863,571.97987Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#ccc"
      />
      <path
        d="M596.59318,291.5583a29.02435,29.02435,0,0,0-4.185-.28637,27.01678,27.01678,0,0,0-4.252.30841,27.64905,27.64905,0,0,0-23.54785,26.91858c0,.52862.02246,1.07934.06543,1.63006a29.61644,29.61644,0,0,0,.439,3.32605,2.153,2.153,0,0,0,.92724,4.09839c.02588,0,.0503-.00677.07617-.00769.07227.23773.13282.4729.21.71167a66.00975,66.00975,0,0,0,5.26464,11.9834c1.36621,2.55536,2.88623,5.08856,4.42871,7.48968a178.20433,178.20433,0,0,0,14.416,19.09668,2.16047,2.16047,0,1,0,3.36963.661,175.99491,175.99491,0,0,0,14.94775-19.75769c1.564-2.40112,3.0625-4.91223,4.44971-7.46759a67.62681,67.62681,0,0,0,5.24268-12.00549,33.27345,33.27345,0,0,0,1.65234-8.28265c.04394-.5066.06641-.99115.06641-1.47583A27.61715,27.61715,0,0,0,596.59318,291.5583Zm5.59522,28.50464a9.67513,9.67513,0,0,1-5.68311,7.33545,9.34676,9.34676,0,0,1-3.92089.837h-.17627a9.49857,9.49857,0,0,1-4.252-1.07935,9.68356,9.68356,0,0,1-5.15429-7.11511,7.28777,7.28777,0,0,1-.13233-1.71826,9.68157,9.68157,0,0,1,5.44141-8.54688,9.79042,9.79042,0,0,1,4.47168-.99133,9.5673,9.5673,0,0,1,3.72265.81506,9.74186,9.74186,0,0,1,5.81543,8.89942A9.15,9.15,0,0,1,602.1884,320.06294Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#3f3d56"
      />
      <path
        d="M784.10149,164.37038c.042-.48236.0625-.94373.0625-1.40522a26.443,26.443,0,0,0-52.874,0c0,.46149.022.92286.064,1.40522a32.69722,32.69722,0,0,0,1.67724,8.24249,70.05447,70.05447,0,0,0,5.707,12.5318,2.15214,2.15214,0,0,0,2.17481,3.67456,166.08122,166.08122,0,0,0,16.28662,21.68506,2.14244,2.14244,0,1,0,.92578.16717c.51221-.56817,1.50733-1.68792,2.8418-3.26373,0-.021,0-.021.02148-.021q.81738-1.00671,1.8252-2.20214c.2959-.36579.6084-.75721.92187-1.1507l-.002.02008a2.19168,2.19168,0,1,0,1.624-2.08319c2.54688-3.28729,5.44483-7.25195,8.17285-11.53052,1.50977-2.36987,2.978-4.82385,4.29932-7.2987a64.25938,64.25938,0,0,0,4.78272-11.13685A31.50243,31.50243,0,0,0,784.10149,164.37038Zm-17.05127.10492a9.24833,9.24833,0,0,1-9.12354,7.76007h-.18945a9.22317,9.22317,0,0,1-8.95459-7.76007,7.38748,7.38748,0,0,1-.126-1.67792,9.26013,9.26013,0,0,1,18.51856.16778A8.79583,8.79583,0,0,1,767.05022,164.4753Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#3f3d56"
      />
      <path
        d="M629.61418,690.83534a6.50506,6.50506,0,0,0-2.95019-4.05l-9.50977-5.8-44.6001-27.22-44.23-26.99006a6.53465,6.53465,0,0,0-3.78027-.95h-.02a6.48585,6.48585,0,0,0-1.15967.17,6.30643,6.30643,0,0,0-1.64014.65,4.99485,4.99485,0,0,0-.98.67,6.28739,6.28739,0,0,0-1.43017,1.64l-7.08985,11.62-.10986.18a6.51159,6.51159,0,0,0-.78027,4.94995,6.65806,6.65806,0,0,0,.78027,1.88,6.405,6.405,0,0,0,2.17969,2.18006l.03027.01995,43.37988,26.47.62989.38,54.30029,33.14a6.57217,6.57217,0,0,0,9.00977-2.18l7.20019-11.8A6.52991,6.52991,0,0,0,629.61418,690.83534Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#6c63ff"
      />
      <path
        d="M572.704,650.74538a6.45061,6.45061,0,0,0-2.74023-4.24l-33.29-19.02a16.34636,16.34636,0,0,0-12.12988-1.66h-.02a16.81186,16.81186,0,0,0-2.65967.94c-.38037.16-.75.35-1.12012.55a16.36383,16.36383,0,0,0-8.52,13.26,16.49378,16.49378,0,0,0,2.1001,9.21,16.29472,16.29472,0,0,0,3.83985,4.56l29.79,24.47a6.5396,6.5396,0,0,0,9.12989-1.64l.62011-.92.27-.4,13.66016-20.21a6.3146,6.3146,0,0,0,.67969-1.22q.13549-.33.24023-.66A6.55,6.55,0,0,0,572.704,650.74538Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#2f2e41"
      />
      <circle cx="262.35446" cy="513.46519" r="50.12206" fill="#6c63ff" />
      <path
        d="M490.29387,729.60536H476.46379a6.56807,6.56807,0,0,0-6.5498,6.56v115.21a6.5595,6.5595,0,0,0,6.5498,6.55h13.83008a6.5596,6.5596,0,0,0,6.55029-6.55v-115.21A6.56817,6.56817,0,0,0,490.29387,729.60536Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#6c63ff"
      />
      <path
        d="M520.25383,729.60536H506.434a6.56807,6.56807,0,0,0-6.54981,6.56v115.21a6.5595,6.5595,0,0,0,6.54981,6.55h13.81982a6.56168,6.56168,0,0,0,6.56006-6.55v-115.21A6.57023,6.57023,0,0,0,520.25383,729.60536Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#6c63ff"
      />
      <path
        d="M570.453,724.70691c0,40.86108-38.43624,54.04142-79.29731,54.04142S422.482,765.568,422.482,724.70691s27.50529-110.51,68.36636-110.51S570.453,683.84584,570.453,724.70691Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#2f2e41"
      />
      <path
        d="M489.64223,572.27746l-.05213-1.87305c3.48453-.09694,6.55659-.31553,8.865-2.002a5.75769,5.75769,0,0,0,2.23019-4.24088,3.29128,3.29128,0,0,0-1.07965-2.71081c-1.53237-1.29412-3.99807-.87524-5.79521-.05121l-1.54975.71062,2.97191-21.71843,1.85568.25426-2.52789,18.47443a6.61182,6.61182,0,0,1,6.25433.89994,5.12369,5.12369,0,0,1,1.74226,4.207,7.6164,7.6164,0,0,1-2.99706,5.68774C496.59392,572.08266,492.73944,572.19058,489.64223,572.27746Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#2f2e41"
      />
      <rect
        x="277.92472"
        y="502.00377"
        width="10.08777"
        height="1.87305"
        fill="#2f2e41"
      />
      <rect
        x="246.08285"
        y="502.00377"
        width="10.08777"
        height="1.87305"
        fill="#2f2e41"
      />
      <path
        d="M450.69368,539.92915c3.89813-5.86788,10.75721-8.35524,17.521-8.96031,7.61315-.68106,14.96945.787,22.40249,2.21331,8.18044,1.56974,16.83815,3.03841,25.08093,1.05378a24.725,24.725,0,0,0,16.80711-13.5776,31.6274,31.6274,0,0,0,1.84145-21.08877c-1.93156-7.47935-6.726-14.06049-12.5382-19.0312-11.86273-10.14531-27.916-14.70479-43.35644-14.27531a73.30107,73.30107,0,0,0-42.49445,14.9418,69.0194,69.0194,0,0,0-24.48539,38.371,71.952,71.952,0,0,0,3.48422,45.25668c3.37881,7.83812,8.26549,14.90444,12.03622,22.54518,3.5108,7.11405,6.21461,15.38523,4.03016,23.32022-1.91682,6.96281-7.54445,13.14865-14.6094,15.039a15.34168,15.34168,0,0,1-17.06032-6.96473c-3.53174-5.569-3.61624-12.86329,1.68647-17.35977a13.01622,13.01622,0,0,1,18.77733,1.87369,13.39825,13.39825,0,0,1,2.68,9.82336c-.26123,1.76869,2.44556,2.532,2.70921.74689a15.84886,15.84886,0,0,0-29.55026-10.23364c-3.19781,6.01244-1.47241,13.2644,2.58692,18.40376a17.93772,17.93772,0,0,0,18.289,6.5766c7.3891-1.7065,13.43377-7.57932,16.27641-14.48054,3.16468-7.68307,1.71471-16.25681-1.37037-23.71223-3.33487-8.05909-8.28714-15.28693-12.20347-23.05312a65.65575,65.65575,0,0,1-6.66212-22.43247,70.98032,70.98032,0,0,1,10.12325-44.96015,65.9702,65.9702,0,0,1,36.33575-27.6153,68.94394,68.94394,0,0,1,45.62064,1.20628c14.27615,5.625,28.06144,17.88347,27.8959,34.3474-.07071,7.03282-2.68144,14.205-8.0684,18.89582-6.43783,5.60593-15.40169,6.08516-23.49451,5.21026-8.14416-.88045-16.05286-3.25891-24.23217-3.86564-7.11426-.52774-14.78947.02091-21.14164,3.55546a21.13817,21.13817,0,0,0-7.34328,6.8122c-1.00333,1.51033,1.429,2.91884,2.426,1.418Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#2f2e41"
      />
      <path
        d="M458.86418,650.5154a6.50618,6.50618,0,0,0-4.29-2.6l-8.89013-1.35-4.77979-.73a6.55172,6.55172,0,0,0-7.17041,4.33,6.23305,6.23305,0,0,0-.28955,1.17l-5.78027,37.98-.4502,2.94-4.71,30.97-6.3999,42a6.57288,6.57288,0,0,0,5.5,7.47l13.66015,2.08a6.59064,6.59064,0,0,0,7.46973-5.5l.18994-1.28,10.79-70.87,6.3501-41.74A6.47074,6.47074,0,0,0,458.86418,650.5154Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#6c63ff"
      />
      <path
        d="M452.74406,636.46535a15.30688,15.30688,0,0,0-2.46-.64,16.37055,16.37055,0,0,0-18.51025,11.41l-.6499,2.15-10.43994,34.77a6.56583,6.56583,0,0,0,5.02978,7.8l1.2002.24.2998.06,23.98047,4.82a6.5696,6.5696,0,0,0,1.38965.15,6.08439,6.08439,0,0,0,1.12988-.1,6.55491,6.55491,0,0,0,5.28027-5.12l4.7002-38.05005A16.359,16.359,0,0,0,452.74406,636.46535Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#2f2e41"
      />
      <path
        d="M768.18959,859.549H753.42982a6.50753,6.50753,0,0,1-6.5-6.5V730.0353a6.50753,6.50753,0,0,1,6.5-6.5h14.75977a6.50753,6.50753,0,0,1,6.5,6.5V853.049A6.50753,6.50753,0,0,1,768.18959,859.549Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#6c63ff"
      />
      <path
        d="M738.99721,859.549H724.23744a6.50753,6.50753,0,0,1-6.5-6.5V730.0353a6.50753,6.50753,0,0,1,6.5-6.5h14.75977a6.50753,6.50753,0,0,1,6.5,6.5V853.049A6.50753,6.50753,0,0,1,738.99721,859.549Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#6c63ff"
      />
      <circle cx="517.43179" cy="503.92114" r="51" fill="#6c63ff" />
      <path
        d="M701.7351,556.56544c-3.30591-.0918-7.42029-.20654-10.59-2.522a8.13274,8.13274,0,0,1-3.20007-6.07275,5.47084,5.47084,0,0,1,1.86035-4.49316c1.65552-1.39893,4.073-1.72706,6.67822-.96143l-2.69921-19.72559,1.98144-.27148,3.17322,23.18994-1.65466-.75928c-1.91834-.87988-4.55164-1.32764-6.188.05517a3.51516,3.51516,0,0,0-1.15271,2.89551,6.14681,6.14681,0,0,0,2.38122,4.52783c2.46668,1.80176,5.74622,2.03418,9.46582,2.13819Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#2f2e41"
      />
      <rect
        x="478.87277"
        y="484.25634"
        width="10.77161"
        height="2"
        fill="#2f2e41"
      />
      <path
        d="M737.61974,538.19306a13.984,13.984,0,0,0,8.54693-11.46718,15.25854,15.25854,0,0,0-6.10845-13.41178c-8.69984-6.56548-20.69477-2.41061-29.80393-7.8227a11.22231,11.22231,0,0,1-5.11038-12.59434c1.25217-5.38172,5.32615-9.31084,9.96211-12.008,9.16646-5.33294,20.325-7.3218,30.825-6.9337,21.59124.79806,41.48225,12.00883,54.37994,29.1618a88.79589,88.79589,0,0,1,14.0287,27.98831c3.08505,10.35516,4.79414,21.693,2.83807,32.42466a35.48159,35.48159,0,0,1-16.3251,24.64432c-8.28159,5.39266-17.99382,8.15829-27.58,10.16241-2.3905.49976-4.79149.94708-7.19685,1.36891-1.89819.33288-1.09366,3.22448.79752,2.89283,19.31142-3.3866,42.05115-9.37332,50.50124-29.22963,4.54756-10.686,4.223-22.78541,2.07221-33.9965a93.35948,93.35948,0,0,0-11.372-30.04568c-11.22221-18.86194-29.49-32.97963-51.24613-37.12475a67.57658,67.57658,0,0,0-32.66395,1.57836c-10.00515,3.12229-21.36842,9.34177-22.32885,21.00987a13.91,13.91,0,0,0,6.60677,13.11088c4.3392,2.69715,9.57005,3.356,14.55495,3.79205,5.37338.47,11.53258.79171,15.84115,4.46214,4.013,3.41863,5.69595,9.34236,3.21117,14.14518a10.66246,10.66246,0,0,1-5.22768,4.99968,1.55247,1.55247,0,0,0-1.04765,1.84518,1.50819,1.50819,0,0,0,1.84517,1.04766Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#2f2e41"
      />
      <path
        d="M789.33846,623.215l-.2085-.37537a6.00028,6.00028,0,0,0-5.11377-3.08484l-26.31689-.57672v-3.48053a5.99983,5.99983,0,0,0-6-6h-16a6.00014,6.00014,0,0,0-6,6v2.86694l-23.85694-.52276a6.00005,6.00005,0,0,0-5.269,2.89923v.00006a140.27313,140.27313,0,0,0-2.5127,140.57648l.2085.37536a5.9995,5.9995,0,0,0,5.11328,3.08484l78.17383,1.71314a5.99954,5.99954,0,0,0,5.269-2.89929v-.00007A140.272,140.272,0,0,0,789.33846,623.215Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#2f2e41"
      />
      <path
        d="M628.84416,695.79536l-7.20019,11.8a6.42638,6.42638,0,0,1-1.81006,1.93,6.00526,6.00526,0,0,1-7.79-2.81l-6.56983-13.22a5.994,5.994,0,0,1,2.69-8.04l8.99023-4.47,9.50977,5.8a6.56687,6.56687,0,0,1,2.18017,9.01Z"
        transform="translate(-228.34084 -40.23537)"
        opacity="0.2"
      />
      <path
        d="M667.81283,602.51783h14.75958a6,6,0,0,1,6,6V724.15228a13.37978,13.37978,0,0,1-13.37978,13.37978h0a13.37977,13.37977,0,0,1-13.37977-13.37977V608.51783A6,6,0,0,1,667.81283,602.51783Z"
        transform="translate(147.87765 1532.80285) rotate(-116.46097)"
        fill="#6c63ff"
      />
      <path
        d="M655.371,657.22731c-3.38051-.55472-6.42081,2.70707-6.93073,6.09464s.78037,6.75881,2.04661,9.94193c2.24957,5.65507,4.5516,11.402,8.34846,16.15845s9.3787,8.46337,15.46435,8.53595c5.79179.06909,11.0788-3.05795,16.01767-6.084l46.32834-28.38554c4.901-3.00289,10.13752-6.40521,12.07354-11.81719a17.21847,17.21847,0,0,0-.26646-11.05044,26.66644,26.66644,0,0,0-26.11229-17.79739c-13.13618.76374-23.38549,10.99679-34.12338,18.602a97.03216,97.03216,0,0,1-35.43309,15.59993"
        transform="translate(-228.34084 -40.23537)"
        fill="#2f2e41"
      />
      <path
        d="M970.46847,859.76463h-738.294a1.19068,1.19068,0,1,1,0-2.38136h738.294a1.19068,1.19068,0,1,1,0,2.38136Z"
        transform="translate(-228.34084 -40.23537)"
        fill="#3f3d56"
      />
    </svg>
  );
};

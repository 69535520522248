import React from "react";
import { Props } from "./svg";

export const Treasure: React.FC<Props> = ({ style }) => {
  return (
    <svg
      id="b6b6fb51-5da5-49d9-a163-13a86b5de1cc"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="853.78"
      height="726"
      viewBox="0 0 853.78 726"
      style={style}
    >
      <ellipse cx="284.98" cy="672" rx="277" ry="54" fill="#3f3d56" />
      <path
        d="M236.36,746S185,732.19,175.31,733.21s14.68-47.36,30.91-41.46,37.87,21.42,50.24-.5,19.31-33.72,34.38-32.54,35.94-9.43,51,0,25.64,21.06,46.44,20.56S428.8,631.58,445,650.46s35.63,36.15,50.86,29.87,32.61,3.16,46.52,13.78,38.26,5.9,47.53-2.36,16.24-14.16,35.94-13,82.31,15.34,105.5,33-30.14,40.12-55.65,40.12-30.14,7.08-52.17,17.7-58-5.9-83.47,11.8-58-9.44-102,0-34.78,22.41-60.29,13-16.23-13-53.33-15.34-77.67,5.9-81.15-9.44-26.64-18.88-40.56-23.6S236.36,746,236.36,746Z"
        transform="translate(-173.11 -87)"
        fill="#3f3d56"
      />
      <path
        d="M876.08,618.72l2-1.28.59-.38a19.55,19.55,0,0,1-2-38.1l-.1,8.06,4.43-8.77h0a19.56,19.56,0,0,1,20.45,18.63c0,.38,0,.74,0,1.11a23.3,23.3,0,0,0,3.86-5.7c1.88-4.19,1.91-8.92,1.77-14.68-.3-11.4-.79-22.89-1.43-34.35a19.54,19.54,0,0,1-17.37-18.51,17.38,17.38,0,0,1,0-1.77c0-.15,0-.3,0-.44,0-.57.09-1.13.18-1.69a2.56,2.56,0,0,1,0-.27c.07-.47.17-.93.28-1.39,0-.17.09-.34.13-.51.14-.5.29-1,.47-1.48.06-.16.12-.31.19-.47.14-.36.29-.71.45-1.06.08-.18.16-.37.25-.55l.07-.14h0a19.57,19.57,0,0,1,23-9.92l-.13,10.49,4.38-8.66a19.53,19.53,0,0,1,9.76,16,19.17,19.17,0,0,1-.43,5.05h0c0,.14-.08.27-.11.41s-.13.52-.2.77-.19.65-.3,1-.16.48-.25.72-.29.68-.44,1c-.09.19-.17.39-.26.59-.25.5-.52,1-.81,1.48-.1.17-.22.33-.32.49-.21.32-.42.65-.65,1s-.29.38-.44.56-.43.54-.66.8-.33.36-.5.54-.48.5-.74.74l-.51.48-.89.74-.46.36c-.47.34-.94.66-1.44,1l-.28.16a14.09,14.09,0,0,1-1.27.69l-.56.25c-.35.16-.71.32-1.08.45l-.64.23c-.36.13-.72.24-1.09.34l-.66.17c-.4.1-.81.18-1.22.25h-.08q.4,7.26.72,14.51a19.54,19.54,0,0,1,27.14-8.49l-3.45,9.48,7.11-6.83a19.49,19.49,0,0,1,6.32,13.5,19.21,19.21,0,0,1-.5,5.39,19.55,19.55,0,0,1-36,5.33c0,.49,0,1,0,1.47.13,5.08.29,11.4-2.24,17a34.54,34.54,0,0,1-7.2,9.72c-1.78,1.81-3.62,3.56-5.5,5.26a19.66,19.66,0,0,1-7.07,5.87q-4.65,3.58-9.62,6.71L879,623.34c-1.86,1.16-3.77,2.34-5.64,3.57l1-.08a19.56,19.56,0,0,1,19.25,12.73l-12.22,10,13.45-3.12a19.59,19.59,0,0,1-28,17.62c.31.37.61.75.91,1.13l1.78,2.22c8,10.08,10.17,19.64,6.23,26.93l-4.79-2.59c3.83-7.1-1.93-16.18-5.71-21-.57-.73-1.16-1.46-1.75-2.19-5.08-6.31-10.84-13.47-10.7-22.25C852.91,633.07,865.76,625.11,876.08,618.72Z"
        transform="translate(-173.11 -87)"
        fill="#f1f1f1"
      />
      <path
        d="M779.43,605.39l-3.07-1.92c-.3-.18-.59-.37-.88-.56a29.29,29.29,0,0,0,3-57.06l.15,12.07L772,544.79h-.08a29.29,29.29,0,0,0-30.62,27.9c0,.56,0,1.11,0,1.66a35,35,0,0,1-5.79-8.55c-2.81-6.26-2.86-13.35-2.64-22,.44-17.08,1.18-34.29,2.14-51.44a29.27,29.27,0,0,0,26-27.71c0-.9,0-1.78,0-2.66l0-.66c-.06-.85-.14-1.69-.27-2.52,0-.14,0-.27-.07-.41-.12-.7-.27-1.39-.44-2.08-.06-.26-.12-.51-.19-.77-.21-.75-.43-1.48-.7-2.21-.08-.24-.18-.47-.28-.71-.21-.54-.43-1.06-.67-1.58-.13-.28-.25-.55-.38-.82a2,2,0,0,1-.1-.21h0a29.27,29.27,0,0,0-24.75-16,29.61,29.61,0,0,0-9.64,1.16l.19,15.71-6.55-13a29.11,29.11,0,0,0-14,31.56h0c0,.2.11.4.16.61.09.39.19.77.29,1.15s.3,1,.46,1.45.24.72.38,1.07c.19.52.42,1,.65,1.53.13.3.25.6.39.89.37.75.77,1.49,1.21,2.21.15.26.32.5.48.74.32.49.63,1,1,1.44.21.29.44.56.66.84s.65.81,1,1.2l.74.81q.54.57,1.11,1.11l.77.71c.43.38.88.75,1.34,1.1.23.18.45.37.69.55.69.51,1.4,1,2.14,1.44.14.09.29.16.43.24.62.36,1.25.71,1.89,1l.85.38c.53.24,1.06.47,1.61.67.32.13.64.24,1,.35.54.18,1.09.35,1.64.5l1,.26c.61.14,1.22.26,1.83.37l.12,0q-.62,10.86-1.08,21.72a29.27,29.27,0,0,0-40.65-12.7l5.18,14.18-10.65-10.22a29.13,29.13,0,0,0-9.46,20.22,28.75,28.75,0,0,0,.74,8.07,29.27,29.27,0,0,0,53.85,8c0,.74,0,1.48-.07,2.21-.19,7.61-.44,17.07,3.35,25.52,2.57,5.73,6.71,10.37,10.79,14.54q4,4.08,8.24,7.88a29.31,29.31,0,0,0,10.57,8.8q7,5.35,14.42,10l3.1,1.92c2.78,1.73,5.64,3.51,8.44,5.35-.49,0-1-.1-1.48-.12a29.28,29.28,0,0,0-28.83,19.06l18.29,14.89-20.13-4.66a29.32,29.32,0,0,0,42,26.38c-.46.56-.91,1.12-1.36,1.69-.9,1.12-1.8,2.23-2.67,3.33-11.91,15.09-15.22,29.4-9.33,40.32l7.18-3.87c-5.74-10.63,2.89-24.23,8.55-31.39.85-1.09,1.73-2.18,2.62-3.28,7.61-9.45,16.23-20.17,16-33.31C814.12,626.89,794.89,615,779.43,605.39Z"
        transform="translate(-173.11 -87)"
        fill="#f1f1f1"
      />
      <path
        d="M631.08,756.56s-34.61-9.16-41.13-8.49,9.89-31.34,20.82-27.44,25.52,14.18,33.85-.33,13-22.32,23.17-21.54,24.21-6.25,34.36,0,17.27,13.94,31.29,13.61,27.29-31.57,38.22-19.08,24,23.94,34.26,19.78,22,2.09,31.35,9.12,25.77,3.91,32-1.56,10.94-9.37,24.22-8.59,55.45,10.15,71.07,21.87-20.31,26.55-37.49,26.55-20.31,4.69-35.15,11.72-39.05-3.91-56.23,7.81-39.05-6.25-68.73,0-23.43,14.84-40.62,8.59-10.93-8.59-35.92-10.15-52.33,3.9-54.68-6.25-18-12.5-27.33-15.62S631.08,756.56,631.08,756.56Z"
        transform="translate(-173.11 -87)"
        fill="#3f3d56"
      />
      <polygon
        points="744.61 561.58 731.21 602.65 730.63 604.44 708.2 673.2 669.8 699.15 483.33 620.74 501 549.28 501.94 545.52 511.4 507.27 511.42 507.18 568.82 500 744.61 561.58"
        fill="#f8ce6d"
      />
      <polygon
        points="744.61 561.58 708.2 673.2 669.8 699.15 705.05 577.34 511.4 507.27 511.42 507.18 568.82 500 744.61 561.58"
        opacity="0.1"
      />
      <polygon
        points="553.24 514.93 550.15 513.05 581.13 509.8 713.85 556.72 710.11 558.68 579.18 512.51 553.24 514.93"
        opacity="0.1"
      />
      <polyline
        points="731.21 602.65 692.45 620.88 501.94 545.52 501 549.28 691.68 623.56 730.63 604.44"
        opacity="0.1"
      />
      <ellipse
        cx="691.21"
        cy="619.15"
        rx="6.67"
        ry="7.2"
        transform="translate(-278.2 1008.63) rotate(-72.75)"
        fill="#3f3d56"
      />
      <ellipse
        cx="859.13"
        cy="684.17"
        rx="6.67"
        ry="7.2"
        transform="translate(-222.18 1214.73) rotate(-72.75)"
        fill="#3f3d56"
      />
      <ellipse
        cx="832.58"
        cy="764.93"
        rx="6.67"
        ry="7.2"
        transform="translate(-317.97 1246.17) rotate(-72.75)"
        fill="#3f3d56"
      />
      <ellipse
        cx="670.66"
        cy="697.17"
        rx="6.67"
        ry="7.2"
        transform="translate(-367.16 1043.88) rotate(-72.75)"
        fill="#3f3d56"
      />
      <path
        d="M707.22,598.38c-.4,1.3-3.61,0-7.69-1.28s-7.26-2.68-6.86-4,4.24-2,8.32-.73S707.63,597.08,707.22,598.38Z"
        transform="translate(-173.11 -87)"
        fill="#3f3d56"
      />
      <ellipse
        cx="721.08"
        cy="591.65"
        rx="2.46"
        ry="7.02"
        transform="translate(-230.92 1017.81) rotate(-72.75)"
        fill="#3f3d56"
      />
      <path
        d="M793.58,630c-.4,1.3-4.41.1-8.3-1.11s-6.92-2.56-6.52-3.86,4.09-2,8-.83S794,628.68,793.58,630Z"
        transform="translate(-173.11 -87)"
        fill="#3f3d56"
      />
      <path
        d="M876.54,659.79c-.45,1.46-4.71.19-8.84-1.09s-7.34-2.75-6.89-4.21,4.4-2.35,8.53-1.07S877,658.33,876.54,659.79Z"
        transform="translate(-173.11 -87)"
        fill="#3f3d56"
      />
      <path
        d="M900.8,656.29c-.46,1.46-4.71.19-8.84-1.09s-7.35-2.75-6.89-4.21,4.4-2.35,8.53-1.07S901.25,654.83,900.8,656.29Z"
        transform="translate(-173.11 -87)"
        fill="#3f3d56"
      />
      <path
        d="M912.76,649.16c-.46,1.46-4,.42-7.38-.64s-6-2.34-5.59-3.8,3.82-2.54,7.23-1.48S913.21,647.7,912.76,649.16Z"
        transform="translate(-173.11 -87)"
        fill="#3f3d56"
      />
      <path
        d="M832.65,619.94c-.36,1.16-4,.09-7.46-1s-6.23-2.31-5.86-3.47,3.67-1.83,7.17-.75S833,618.77,832.65,619.94Z"
        transform="translate(-173.11 -87)"
        fill="#3f3d56"
      />
      <ellipse
        cx="749.03"
        cy="590.77"
        rx="2.46"
        ry="6.06"
        transform="translate(-210.42 1043.88) rotate(-72.75)"
        fill="#3f3d56"
      />
      <path
        d="M791,669.25a6.32,6.32,0,0,0-2.9-7.36,6.43,6.43,0,0,0,1.31-2.33,6.32,6.32,0,0,0-7.24-8.08,6.32,6.32,0,0,0-11.34-5.25,6.32,6.32,0,0,0-9.74,3.25c0,.14-.07.27-.1.4l-.22.11a6.32,6.32,0,1,0-6.11,10.73,5.65,5.65,0,0,0,1,.22l.08,2.32L786,675.63l-.2-2A6.34,6.34,0,0,0,791,669.25Z"
        transform="translate(-173.11 -87)"
        opacity="0.1"
      />
      <path
        d="M783.93,689.11a6.33,6.33,0,0,1-7.2,3.29,6.18,6.18,0,0,1-.67,2.58,6.32,6.32,0,0,1-8.49,2.81,6.43,6.43,0,0,1-2.33-2,6.32,6.32,0,0,1-11.8-4.09,6.42,6.42,0,0,1-2-.62,6.32,6.32,0,0,1-2.81-8.48l.2-.37-.08-.23a6.25,6.25,0,0,1-2.63-.67A6.32,6.32,0,0,1,751.86,670a6.59,6.59,0,0,1,.85.51l1.66-1.61,30.44,12.17-1.53,1.3A6.32,6.32,0,0,1,783.93,689.11Z"
        transform="translate(-173.11 -87)"
        opacity="0.1"
      />
      <path
        d="M765.35,655.64,758,679.46a7.16,7.16,0,0,0,4.33,8.83h0a7.15,7.15,0,0,0,9.32-4.58L779,659.88a7.14,7.14,0,0,0-5.08-9h0A7.14,7.14,0,0,0,765.35,655.64Z"
        transform="translate(-173.11 -87)"
        opacity="0.1"
      />
      <path
        d="M765.67,657.09,759,678.45a6.4,6.4,0,0,0,3.89,7.91h0a6.41,6.41,0,0,0,8.35-4.11l6.63-21.37a6.42,6.42,0,0,0-4.56-8.12h0A6.42,6.42,0,0,0,765.67,657.09Z"
        transform="translate(-173.11 -87)"
        fill="#3f3d56"
      />
      <path
        d="M678.72,767.49s-34.61-9.16-41.13-8.48,9.89-31.35,20.82-27.44,25.52,14.17,33.85-.34,13-22.31,23.17-21.53,24.21-6.25,34.36,0,17.27,13.93,31.29,13.6,27.29-31.57,38.23-19.07,24,23.93,34.26,19.78,22,2.09,31.34,9.12,25.78,3.9,32-1.56,10.93-9.38,24.21-8.6,55.45,10.16,71.07,21.87-20.3,26.56-37.49,26.56-20.3,4.68-35.14,11.71-39.06-3.9-56.24,7.81-39.05-6.24-68.73,0-23.43,14.84-40.61,8.6-10.94-8.6-35.93-10.16-52.33,3.91-54.67-6.25-17.95-12.49-27.33-15.62S678.72,767.49,678.72,767.49Z"
        transform="translate(-173.11 -87)"
        fill="#3f3d56"
      />
      <path
        d="M972.49,741.17c3-5.51-.4-12.27-4.28-17.18s-8.62-10-8.52-16.29c.15-9,9.7-14.31,17.33-19.09a84,84,0,0,0,15.56-12.51,22.61,22.61,0,0,0,4.78-6.4c1.58-3.52,1.54-7.52,1.44-11.37q-.49-19.26-1.91-38.49"
        transform="translate(-173.11 -87)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
        strokeWidth="4"
      />
      <path
        d="M1011.4,619.14a14,14,0,0,0-7-11.5l-3.14,6.22.1-7.53a14.19,14.19,0,0,0-4.63-.56,14,14,0,1,0,14.68,13.37Z"
        transform="translate(-173.11 -87)"
        fill="#57b793"
      />
      <path
        d="M986.37,714.13a14,14,0,1,1,.68-11.3L978.28,710l9.65-2.23A14,14,0,0,1,986.37,714.13Z"
        transform="translate(-173.11 -87)"
        fill="#57b793"
      />
      <path
        d="M979.44,686.88A14,14,0,0,1,975,659.35l-.07,5.78,3.17-6.29h0A14,14,0,0,1,992.8,672.2a13.84,13.84,0,0,1-.6,4.79A14,14,0,0,1,979.44,686.88Z"
        transform="translate(-173.11 -87)"
        fill="#57b793"
      />
      <path
        d="M1013.51,664.21a14,14,0,1,1,6.21-26.27l-2.48,6.8,5.1-4.9a14,14,0,0,1,4.53,9.69,13.79,13.79,0,0,1-.35,3.87A14,14,0,0,1,1013.51,664.21Z"
        transform="translate(-173.11 -87)"
        fill="#57b793"
      />
      <path
        d="M1009.51,622.88c-3.24.35-6.39,1.36-9.64,1.56s-6.82-.57-8.87-3.1c-1.11-1.36-1.67-3.08-2.6-4.57a10,10,0,0,0-3.54-3.33,14,14,0,1,0,26.24,9.32Q1010.31,622.79,1009.51,622.88Z"
        transform="translate(-173.11 -87)"
        opacity="0.1"
      />
      <path
        d="M1013.51,664.21a14.05,14.05,0,0,1-13.35-20A10.37,10.37,0,0,1,1003,647c1,1.51,1.61,3.26,2.79,4.64,2.18,2.57,5.91,3.41,9.3,3.26s6.66-1.12,10-1.43c.47,0,1-.07,1.42-.08A14,14,0,0,1,1013.51,664.21Z"
        transform="translate(-173.11 -87)"
        opacity="0.1"
      />
      <path
        d="M979.44,686.88A14,14,0,0,1,966,667.12a11.32,11.32,0,0,1,3,2.85c1.09,1.54,1.77,3.32,3,4.74,2.37,2.63,6.35,3.56,9.94,3.48s6.82-.93,10.27-1.2A14,14,0,0,1,979.44,686.88Z"
        transform="translate(-173.11 -87)"
        opacity="0.1"
      />
      <path
        d="M986.37,714.13a14,14,0,0,1-25.58-11.45,14,14,0,0,1,3.07,2.75c1.34,1.62,2.22,3.47,3.76,5,2.87,2.82,7.5,4,11.63,4.09A60,60,0,0,0,986.37,714.13Z"
        transform="translate(-173.11 -87)"
        opacity="0.1"
      />
      <path
        d="M951.32,734.82s11.08-.34,14.42-2.72,17-5.21,17.87-1.4,16.64,19,4.14,19.06-29.06-1.94-32.39-4S951.32,734.82,951.32,734.82Z"
        transform="translate(-173.11 -87)"
        fill="#656380"
      />
      <path
        d="M988,748.44c-12.51.1-29.06-2-32.39-4-2.54-1.55-3.55-7.09-3.89-9.65h-.37s.7,8.94,4,11,19.88,4.07,32.39,4c3.61,0,4.85-1.31,4.79-3.21C992,747.7,990.66,748.41,988,748.44Z"
        transform="translate(-173.11 -87)"
        opacity="0.2"
      />
      <path
        d="M378.31,266.18a4.51,4.51,0,0,1,.31-2.14c.81-1.44,2.93-1.05,4.53-.64,6.44,1.62,13.22.15,19.84.53s14.09,3.69,15.42,10.19a48.25,48.25,0,0,0,12.23-12.57c1.84-2.75,3.44-5.9,3.19-9.2-.36-4.76-4.35-8.32-8.05-11.34l-11-9a16.51,16.51,0,0,0-4.94-3.14c-2.54-.87-5.32-.46-8-.46-8,0-16.57-3.64-23.71,0-3,1.53-5.22,4.15-7.38,6.71l-9.84,11.65a6.13,6.13,0,0,0-1.57,2.67c-.28,1.42.45,2.82.7,4.25.47,2.6-.66,5.37.21,7.87,1.43,4.15,6.46,4.74,9.54,7.11,1.15.89,2.95,3.89,4.31,3.86C376.54,272.5,378.24,268.09,378.31,266.18Z"
        transform="translate(-173.11 -87)"
        fill="#3f3d56"
      />
      <path
        d="M379.93,294c-.11,3.24-2,6.12-3.82,8.81-2.43,3.58-5,7.3-8.84,9.3a84.94,84.94,0,0,0,62.78,6.62c3.37-1,7.35-3.08,7.15-6.59-4-1.09-8.14-2.22-11.54-4.63-4.06-2.88-6.74-7.36-8.47-12s-2.61-9.6-3.82-14.43a2.72,2.72,0,0,0-.62-1.34,2.76,2.76,0,0,0-1.9-.5L397.53,279c-5.47,0-11.41-.13-16.49,2.19C374.72,284.09,380.11,288.62,379.93,294Z"
        transform="translate(-173.11 -87)"
        fill="#ffc0c6"
      />
      <path
        d="M379.93,294c-.11,3.24-2,6.12-3.82,8.81-2.43,3.58-5,7.3-8.84,9.3a84.94,84.94,0,0,0,62.78,6.62c3.37-1,7.35-3.08,7.15-6.59-4-1.09-8.14-2.22-11.54-4.63-4.06-2.88-6.74-7.36-8.47-12s-2.61-9.6-3.82-14.43a2.72,2.72,0,0,0-.62-1.34,2.76,2.76,0,0,0-1.9-.5L397.53,279c-5.47,0-11.41-.13-16.49,2.19C374.72,284.09,380.11,288.62,379.93,294Z"
        transform="translate(-173.11 -87)"
        opacity="0.1"
      />
      <path
        d="M368.34,750.24c-3.28,5-7.57,9.23-11.17,14s-6.61,10.33-6.65,16.3a2.58,2.58,0,0,0,.69,2.1,2.7,2.7,0,0,0,1.37.43l13.9,1.78a23.29,23.29,0,0,0,6.4.22,22.7,22.7,0,0,0,6.56-2.39,9.05,9.05,0,0,0,3.94-3c.67-1.1.85-2.45,1.49-3.57,1.93-3.39,6.79-3.24,10.65-2.65,1.11-10.31-1.74-20.59-4.57-30.57a4,4,0,0,0-.77-1.68,4,4,0,0,0-1.81-1c-3.33-1.09-11.38-5.36-14.18-3.36C372.14,738.33,370,747.59,368.34,750.24Z"
        transform="translate(-173.11 -87)"
        fill="#575a88"
      />
      <path
        d="M435.36,770.57c2.67-1.25,5.52-2.86,8.4-2.2,4.06.91,5.87,5.71,9.32,8,2.12,1.43,4.74,1.85,7.27,2.18a169.42,169.42,0,0,0,23.5,1.39c2.51,0,5.67-.6,6.28-3s-1.7-4.31-3.75-5.56a110,110,0,0,0-13.88-7.21,27.83,27.83,0,0,1-6.62-3.52c-3.43-2.77-5.1-7.11-6.63-11.24-1.23-3.32-2.36-10.49-4.88-13-2.76-2.71-6.47-.91-9.93-.15-5.38,1.18-11.68,2-15.14,6.83C424.24,750.16,420.35,777.52,435.36,770.57Z"
        transform="translate(-173.11 -87)"
        fill="#575a88"
      />
      <path
        d="M355.85,451.46A101.94,101.94,0,0,0,348.39,487c-.24,9.11.74,18.19,1.73,27.25q2.37,22,4.76,44a124.13,124.13,0,0,0,2.87,18.11c1.73,6.65,4.54,13,6.55,19.54,6.22,20.38,4.45,42.36.34,63.27a31.61,31.61,0,0,0-.86,7c.12,3.53,1.48,6.89,2.36,10.31,5.38,20.85-7,44,1.08,64a3.17,3.17,0,0,0,1.27,1.75,3.22,3.22,0,0,0,2.52-.11A34.09,34.09,0,0,0,377.6,739a40.21,40.21,0,0,0,16.34,11.83c4.74-1.17,5.63-6.93,7.18-11.56A79,79,0,0,0,404,729.2a80.15,80.15,0,0,0,1.05-12.68l4.1-144.35-.3,44.05a52,52,0,0,0,.67,10.66c.6,2.93,1.7,5.74,2.33,8.67.89,4.19.78,8.53.74,12.82a376.79,376.79,0,0,0,6.95,75.82c1.6,8.18,3.52,16.44,7.61,23.69a2.61,2.61,0,0,0,1.49,1.46c.87.19,1.67-.5,2.33-1.11a25.49,25.49,0,0,1,29.27-3.18c-.88-4.24,3.16-9.17.27-12.39-.83-.93-2.07-1.38-3-2.19-1.84-1.57-2.28-4.21-2.54-6.62a153.34,153.34,0,0,1,0-32.27c1.41-13.41,4.58-26.93,2.24-40.2-1.7-9.63-6.28-19-5.37-28.69.6-6.5,3.62-12.49,5.7-18.68,9.08-27.06,0-57.22,6.57-85,1.51-6.38,3.79-13.28,1.05-19.23l4.17-1.27-8.66-19.77c-1.84-4.21-3.76-8.54-7-11.78-5.44-5.41-13.57-6.86-21.16-8l-61.74-9.63C365.3,448.44,357.92,446.38,355.85,451.46Z"
        transform="translate(-173.11 -87)"
        fill="#3c354c"
      />
      <circle cx="221.61" cy="180.43" r="26.43" fill="#ffc0c6" />
      <path
        d="M380.31,298.9a6.14,6.14,0,0,0-4.26-.75,7.28,7.28,0,0,0-2,1.09L361.55,308a19.33,19.33,0,0,0-4.73,4.14c-2.14,2.91-2.48,6.78-2.2,10.38.62,8.12,3.9,15.78,5.68,23.72s1.78,17-3.34,23.36a12.38,12.38,0,0,0-2.18,3.09,9,9,0,0,0-.41,3,81.82,81.82,0,0,0,.24,11.4c.56,4.89,2,9.64,2.78,14.49,1.37,8.26,1,16.73,2.06,25a4,4,0,0,0,4,4.34,117.77,117.77,0,0,0,18.67,1.23c7.23,0,14.45-.4,21.67-.78l32.86-1.73a3.24,3.24,0,0,0,3.65-3.35c2.88-13.82,5.78-27.89,4.42-41.93-.88-9.07-3.52-17.86-6-26.62-2-7.1-3.92-14.55-2.42-21.78,1-4.77,3.43-9.18,4.06-14a16.51,16.51,0,0,0-4.52-13.9c-3.68-3.53-9.05-4.87-12.72-8.41a28.93,28.93,0,0,0-3.28-3.18,4.1,4.1,0,0,0-4.31-.5,5.55,5.55,0,0,0-1.64,1.83c-3.2,4.86-8,12.87-14.75,11.61C391.84,308.1,386.74,302,380.31,298.9Z"
        transform="translate(-173.11 -87)"
        fill="#6c63ff"
      />
      <path
        d="M446.35,477.58c-2.34,1.75-4.08,4.86-3,7.57a15.27,15.27,0,0,0,9.36-1.3c1.87-.91,4.2-2.19,5.82-.87a4.93,4.93,0,0,1,1.19,1.8,60.82,60.82,0,0,0,6.34,10.53c1.78-.84,3.65-1.76,4.72-3.41a9.87,9.87,0,0,0,1.23-3.65,97,97,0,0,0,1.39-9.73c.21-2.37.25-5-1.37-6.76C465.08,464.26,452.12,473.27,446.35,477.58Z"
        transform="translate(-173.11 -87)"
        fill="#ffc0c6"
      />
      <path
        d="M376.66,256.26a4.48,4.48,0,0,1,.31-2.13c.81-1.44,2.93-1,4.53-.65,6.44,1.63,13.22.16,19.84.54s14.09,3.69,15.42,10.19A48.41,48.41,0,0,0,429,251.64c1.84-2.75,3.44-5.9,3.19-9.2-.36-4.76-4.35-8.32-8.05-11.34l-11-9a16.48,16.48,0,0,0-4.94-3.13c-2.54-.87-5.32-.46-8-.46-8,0-16.57-3.64-23.71,0-3,1.54-5.22,4.16-7.39,6.72l-9.83,11.65a6.21,6.21,0,0,0-1.58,2.66c-.28,1.42.45,2.83.71,4.25.46,2.6-.66,5.38.2,7.88,1.43,4.15,6.47,4.73,9.55,7.11,1.15.89,2.94,3.89,4.31,3.85C374.89,262.59,376.59,258.18,376.66,256.26Z"
        transform="translate(-173.11 -87)"
        fill="#3f3d56"
      />
      <path
        d="M441.93,309.87a20.5,20.5,0,0,1,16.24,3c7.56,5.18,10.67,14.64,13.3,23.42l5.89,19.69c2.58,8.63,5.18,17.34,5.78,26.33.55,8.17-.57,16.35-1.69,24.46-1.55,11.23,2.1,23.54,2.15,34.87,0,13-.08,26.79-6.7,38a6.34,6.34,0,0,0-4-4.27,18.65,18.65,0,0,0-5.95-1l-17.6-1.13a54.27,54.27,0,0,0,3.86-7.93c6.75-17.34,4.16-36.72,1.47-55.12a38,38,0,0,0-1-5.2,49.58,49.58,0,0,1-1.19-20.15,47,47,0,0,0,.31-9.18,10.26,10.26,0,0,0-.81-3.76,11.82,11.82,0,0,0-1.94-2.68l-3.56-4c-.29-12.1-2.19-24.29-4.07-36.25A81.41,81.41,0,0,1,441.93,309.87Z"
        transform="translate(-173.11 -87)"
        fill="#6c63ff"
      />
      <path
        d="M407.35,363.3a84.94,84.94,0,0,1-31.84-53.93c-.58-3.79-2.09-8.74-5.92-8.61a7.55,7.55,0,0,0-3.26,1.14l-13.88,7.44a5.23,5.23,0,0,0-2.42,2c-.91,1.78.38,3.83,1.49,5.49,7.47,11.3,8,25.65,8.3,39.19.09,4,.12,8.21-1.81,11.71-1.57,2.83-4.3,4.91-5.74,7.82-1.35,2.73-1.41,5.89-1.45,8.94-.28,21.76-.56,43.55.58,65.29.17,3.28.43,6.72,2.18,9.5,1.63,2.57,4.32,4.23,6.92,5.8l21.05,12.72c4,2.43,8.16,4.9,12.79,5.68s9.92-.54,12.58-4.41c1.29-1.88,1.86-4.17,3.1-6.08s3.61-3.46,5.72-2.61c1.5.6,2.32,2.17,3,3.62,1.34,2.77,2.74,5.61,4.93,7.78s5.36,3.63,8.37,3c2.6-.57,4.63-2.54,6.5-4.42,5.78-5.79,11.62-11.66,15.87-18.67a4.5,4.5,0,0,0,.9-2.83,5.7,5.7,0,0,0-1.55-2.6c-2.66-3.26-3.71-7.53-4.5-11.67-7.91-41.6,3.26-84.84-2.89-126.74-.7-4.8-2.24-9.21-5.84-12.46s-8.08-5.32-12.49-7.34c-1.45-.66-3.11-1.34-4.59-.74-2.45,1-2.46,4.37-2.28,7a91.71,91.71,0,0,1-.8,16.13l-2.15,19.79a38.24,38.24,0,0,1-1.68,8.9A35.84,35.84,0,0,1,407.35,363.3Z"
        transform="translate(-173.11 -87)"
        fill="#575a88"
      />
      <path
        d="M284.74,206.07s-7.63,6.2-16.64-10.06-52.52-55.34-52.52-55.34-5.63-5.15,15.76-13.73S281.94,87,281.94,87s48.74,43.33,53.51,64.79-5.67,25.1-23.59,36.5S284.74,206.07,284.74,206.07Z"
        transform="translate(-173.11 -87)"
        fill="#3f3d56"
      />
      <path
        d="M424.43,413.59l-123-221.67,10.44-3.63L432.79,409a4.79,4.79,0,0,1-2,6.54h0A4.77,4.77,0,0,1,424.43,413.59Z"
        transform="translate(-173.11 -87)"
        fill="#656380"
      />
      <path
        d="M373.92,357c3.22-6.89-.08-14.84-2.28-22.12a74.74,74.74,0,0,1-3.11-17.8,13,13,0,0,1,.62-5.63,5,5,0,0,1,4.28-3.27,20.53,20.53,0,0,1,3.21,14.62,49.22,49.22,0,0,1,5.66-1.11A10.59,10.59,0,0,1,393.81,329c3.17,10.16,4.25,21-.17,30.5a58,58,0,0,1-4.89,8.05l-9.64,14.08c-.66,1-1.47,2-2.63,2.17s-2.37-.88-3.22-1.85c-2.93-3.31-8.13-8.13-7.56-12.69S372,361.05,373.92,357Z"
        transform="translate(-173.11 -87)"
        fill="#ffc0c6"
      />
      <path
        d="M351.64,314.53c-9.79-1.11-21,1.54-26.12,10-2.57,4.27-3.21,9.39-3.66,14.35-.63,6.95-1,13.92-1.35,20.88l-1.89,36.64c-.23,4.38-.45,8.77-.53,13.15a29.63,29.63,0,0,0,1.09,10.16c1.21,3.55,3.61,6.55,6,9.47s5.38,5.93,8.89,5.08a9.66,9.66,0,0,0,3.81-2.35l8-6.92c5.44-4.67,10.94-9.41,15.08-15.27,2-2.82,3.64-5.87,5.66-8.67,5.94-8.24,15.08-14.49,18.21-24.15-3-.16-7.36-1.15-8.74-3.81-.73-1.41-.83-3.09-1.64-4.44-1.23-2.06-4-3.18-4.41-5.54-7.86,6.33-14.44,13.68-20.72,21.57l2.55-9.91A6.3,6.3,0,0,1,353,372.2a11.33,11.33,0,0,1,3-1.89c3.11-1.88,4.29-5.77,4.92-9.35A65.43,65.43,0,0,0,351.64,314.53Z"
        transform="translate(-173.11 -87)"
        fill="#6c63ff"
      />
    </svg>
  );
};

import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";

interface Props {}

const items = [
  {
    x: 0,
    y: 0,
    word: "KUBA",
  },
  {
    x: 4,
    y: 0,
    word: "KASIA",
  },
];

interface TableItem {
  value: string;
  itemIndex: number;
  letterIndex: number;
}

const table: TableItem[][] = [];
items.forEach(({ x, y, word }, itemIndex) => {
  if (!table[y]) table[y] = [];
  word.split("").forEach((v, i) => {
    table[y][x + i] = { value: v, letterIndex: i, itemIndex };
  });
});

const size = 50;

export const Crossword: React.FC<Props> = () => {
  const [selectMode, setSelectMode] = useState(false);
  const [selected, setSelected] = useState<TableItem[]>([]);
  const addSelected = (item: TableItem) => {
    if (
      selected.find(
        (x) =>
          x.itemIndex === item.itemIndex && x.letterIndex === item.letterIndex
      )
    ) {
      return;
    }
    setSelected([...selected, item]);
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      onTouchEnd={() => {
        setSelectMode(false);
        setSelected([]);
      }}
    >
      {table.map((row: any[], i) => {
        return (
          <Grid container key={i}>
            {row.map((item, j) => {
              const isSelected = selected.find(
                (x) =>
                  x.itemIndex === item.itemIndex &&
                  x.letterIndex === item.letterIndex
              );
              return (
                <Grid
                  key={j}
                  container
                  alignItems="center"
                  justify="center"
                  style={{
                    border: `1px solid blue`,
                    background: isSelected ? "rgba(0,0,255,0.1)" : "none",
                    borderRadius: 10,
                    width: size,
                    height: size,
                    margin: "0.3rem",
                  }}
                  onTouchStart={() => {
                    setSelectMode(true);
                    addSelected(item);
                  }}
                  onClick={() => {
                    addSelected(item);
                  }}
                  onTouchMove={(e) => {
                    if (selectMode) {
                      const currentElementTouched = document.elementFromPoint(
                        e.touches[0].pageX,
                        e.touches[0].pageY
                      );
                      if (currentElementTouched) {
                        (currentElementTouched as any).click();
                      }
                    }
                  }}
                >
                  {item.value}
                </Grid>
              );
            })}
          </Grid>
        );
      })}
    </Grid>
  );
};

enum WordDirection {
  COLUMN = 0,
  ROW = 1,
}

interface Word {
  direction: WordDirection;
  value: string;
}

// eslint-disable-next-line
class Board {
  board: string[][];
  constructor(private words: Word[]) {
    this.board = [];
  }
  private insert(word: Word) {
    switch (word.direction) {
      case WordDirection.COLUMN:
        break;
      case WordDirection.ROW:
        this.insertRow(word.value);
        break;
    }
  }
  private insertRow(value: string) {}
  private insertColumn(value: string) {}
}

import React from "react";
import { useHistory } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import { Props } from "./page";
import { Forever, Heart } from "../resources";

export const Main: React.FC<Props> = ({ goTo }) => {
  const history = useHistory();
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      style={{ height: "100vh" }}
    >
      <Grid container direction="column" alignItems="center" wrap="nowrap">
        <Forever style={{ width: "80%", height: "40%", margin: "2rem" }} />
        <Typography variant="h2" color="secondary" style={{ fontWeight: 900 }}>
          K
          <span
            style={{ marginLeft: "1.5rem", marginRight: "1.5rem" }}
            onClick={() => history.push(goTo)}
          >
            <Heart />
          </span>
          K
        </Typography>
        <Typography variant="h3" color="primary">
          Druga Rocznica
        </Typography>
      </Grid>
    </Grid>
  );
};

import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Props } from "./page";
import code1 from "./Code/code_1.svg";
import code2 from "./Code/code_2.svg";
import code3 from "./Code/code_3.svg";
import code4 from "./Code/code_4.svg";
import code5 from "./Code/code_5.svg";
import arrow from "./Code/arrow.svg";

const codes = [code3, code5, code1, code2, code4];

export const Code: React.FC<Props & { onEnter?: () => void }> = ({
  onEnter,
}) => {
  useEffect(() => {
    onEnter && onEnter();
  }, []);
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      style={{ height: "100vh" }}
    >
      <Grid
        container
        direction="column-reverse"
        alignItems="center"
        wrap="nowrap"
      >
        <img src={arrow} />
        {codes.map((code, i) => {
          return (
            <img
              key={i}
              src={code}
              style={{ width: `${100 / codes.length - 5}vh` }}
            />
          );
        })}
      </Grid>
    </Grid>
  );
};
